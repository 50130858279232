import { Injectable } from '@angular/core';
import moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  static getWeekday(d: Date) {
    const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    const weekday = d.toLocaleDateString('en-gb', { weekday: 'long' }).toLowerCase();

    return { weekday, index: weekdays.indexOf(weekday) };
  }

  static month(d: Date): string {
    return d.toLocaleString('default', { month: 'long' });
  }

  static date(d: Date): string {
    return ('00' + d.getDate()).slice(-2) + '/' +
      ('00' + (d.getMonth() + 1)).slice(-2) + '/' +
      d.getFullYear();
  }

  static datetime(d: Date): string {
    return this.date(d) + ' ' +
      ('00' + d.getHours()).slice(-2) + ':' +
      ('00' + d.getMinutes()).slice(-2) + ':' +
      ('00' + d.getSeconds()).slice(-2);
  }

  static monthsDiff(dateFrom: Date, dateTo: Date): number {
    return (dateTo.getFullYear() * 12 + dateTo.getMonth()) - (dateFrom.getFullYear() * 12 + dateFrom.getMonth());
  }

  /**
   * Return dates from start date backwards in quarters (3 months)
   * 
   * @param startDate The starting date to count backwards
   */
  static quarters(startDate?: Date, rows = 8): IQuarter[] {
    const quarters = [, 'January to March', 'April to July', 'July to September', 'October to December'];
    const dates: IQuarter[] = [];
    for (let index = 1; index <= rows; index++) {
      const dt = moment(startDate).subtract(index * 3, 'month').startOf('month');
      const quarter = dt.quarter();
      const year = dt.year();
      //const date = dt.toDate();
      const monthIndex = (quarter - 1) * 3;
      const date = new Date(year, monthIndex, 1, 0, 0, 0);
      dates.push({ title: quarters[quarter] + ' ' + dt.get('year'), quarter, year, date, monthIndex });
    }

    return dates;
  }
}

export interface IQuarter {
  title: string;
  quarter: number;
  year: number;
  date: Date;
  monthIndex: number;
}
