import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rating-ae',
  templateUrl: './rating-ae.component.html',
  styleUrls: ['./rating-ae.component.css']
})
export class RatingAeComponent implements OnInit {

  _value: number;
  @Input()
  public set percentage(v: number) {
    this._value = v;
    console.log('GOT ', v);
  }

  @Input()
  width: number;

  public get percentage(): number {
    return this._value;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
