import { Address } from "./address";

export class Org {
	id: number;
	title: string;
	type = 'owner';
	isActive: boolean;
	shortTitle: string;
	address_id: number;
	addressTitle: string;
	address1: string;
	address2: string;
	address3: string;
	addressTown: string;
	addressCounty: string;
	addressCountry: string;
	addressPostcode: string;
	addressTel: string;
	addressEmail: string;
	addressLong: string;
	addressLat: string;
	createdAt: Date;
	dateFormat: string;
	settings: any = {};
	isBillable: boolean;
	logo: string;
	address: Address;
	notes: string;
	swapCount: number;
	swapUpdatedAt: Date;
	// When true, use users org
	hide: boolean;
	engagementType: EngagementType;
	siteCount: number;

	constructor(data?: any) {
		if (!data) {
			this.address = new Address();
			return;
		}

		this.id = data.id;
		this.title = data.title;
		this.shortTitle = data.shortTitle;
		this.type = data.type;
		this.isActive = (typeof data.isActive === "undefined") ? true : !!data.isActive;
		this.dateFormat = data.dateFormat || 'DD-MM-YYYY';
		this.address1 = data.address1;
		this.addressPostcode = data.addressPostcode;
		this.dateFormat = data.dateFormat;
		this.settings = data.settings;
		this.logo = data.logo;
		this.address = new Address(data);
		this.notes = data.notes;
		this.swapCount = data.swapCount;
		this.hide = !!data.hide || false;
		this.isBillable = data.isbillable;
		this.createdAt = data.createdAt && new Date(data.createdAt);
		if (data.swapUpdatedAt) {
			this.swapUpdatedAt = new Date(data.swapUpdatedAt);
		}
		this.engagementType = data.engagement_type;
		this.siteCount = data.siteCount ?? null;
	}

	serialise() {
		return {
			id: this.id,
			title: this.title,
			shortTitle: this.shortTitle,
			type: this.type,
			isActive: this.isActive,
			address: this.address.serialise(),
			notes: this.notes
		}
	}
}

export type EngagementType = 'limited' | 'all';
