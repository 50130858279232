<div class="chart-container" *ngIf="ready">
  <svg [class.white]='theme === "white"'  [class.light]='theme === "light"' class="week" [attr.viewBox]="viewbox" xmlns="https://www.w3.org/2000/svg" style="height:240px;width: 100%;">
    <svg:defs>
      <svg:linearGradient id="gradWhite" x1="0%" y1="0%" x2="0%" y2="100%">
        <!--<svg:stop offset="0%" style="stop-color:rgb(72,170,220);stop-opacity:1" ></svg:stop>-->
        <svg:stop offset="0%" style="stop-color:rgb(255,255,255);stop-opacity:1"></svg:stop>
        <svg:stop offset="100%" style="stop-color:rgb(63,81,181);stop-opacity:1"></svg:stop>
      </svg:linearGradient>
      <svg:linearGradient id="gradRed" x1="0%" y1="0%" x2="0%" y2="100%">
        <!--<svg:stop offset="0%" style="stop-color:rgb(72,170,220);stop-opacity:1" ></svg:stop>-->
        <svg:stop offset="0%" style="stop-color:rgb(255,255,255);stop-opacity:1"></svg:stop>
        <svg:stop offset="100%" style="stop-color:rgb(88,184,241);stop-opacity:1"></svg:stop>
      </svg:linearGradient>
      <svg:linearGradient id="solid" x1="0%" y1="0%" x2="0%" y2="100%">
        <!--<svg:stop offset="0%" style="stop-color:rgb(72,170,220);stop-opacity:1" ></svg:stop>-->
        <svg:stop offset="0%" style="stop-color:rgb(88,184,241);stop-opacity:1"></svg:stop>
        <svg:stop offset="100%" style="stop-color:rgb(88,184,241);stop-opacity:1"></svg:stop>
      </svg:linearGradient>
    </svg:defs>
    <svg:g>

      <!-- <svg:polyline fill="white" stroke="white" stroke-width="1" points="440,60 240,60"></svg:polyline>-->
      <svg:polyline [attr.fill]="'none'" stroke="#02acee" stroke-width="1" stroke-opacity="0.5" [attr.points]="points">
        <svg:animate attributeName="points" dur=".5s" [attr.from]="pointsFrom" [attr.to]="points"></svg:animate>
      </svg:polyline>


    </svg:g>
    <!-- legends for day of week-->
    <svg:g>
      <ng-template ngFor let-item [ngForOf]="legend">

        <svg:g *ngIf="item">
          <svg:rect *ngIf="item" [id]="item.id" (mouseenter)="legendEnter($event, item)" (click)="legendClick($event, item)" fill="none"
            [attr.x]="item.x" [attr.y]="-2" [attr.width]="item.width" [attr.height]="item.height+4" stroke-width="1" style="stroke: #ddd;opacity: 0.6;pointer-events: all"
            [class.selected]="item.selected"></svg:rect>

          <!-- min line -->
          <svg:line *ngIf="item && setpoints[item.w].isActive && setpoints[item.w].min" [attr.fill]="'red'" stroke="rgb(255,0,0)" stroke-width="1"
            [attr.x1]="item.x" [attr.x2]="item.x + item.width" [attr.y1]="graphYBase - ((+setpoints[item.w].min - graphMin) * ystep)"
            [attr.y2]="graphYBase - ((+setpoints[item.w].min - graphMin) * ystep)"></svg:line>
          <!-- max line -->
          <svg:line *ngIf="item && setpoints[item.w].isActive && setpoints[item.w].max" [attr.fill]="'red'" stroke="rgb(255,0,0)" stroke-width="1"
            [attr.x1]="item.x" [attr.x2]="item.x + item.width" [attr.y1]="graphYBase - ((+setpoints[item.w].max - graphMin) * ystep)"
            [attr.y2]="graphYBase - ((+setpoints[item.w].max - graphMin) * ystep)"></svg:line>

          <svg:text *ngIf="item" fill="#666" [attr.x]="item.x + 2" [attr.y]="20" text-anchor="left" style="font-weight:100;opacity: 0.9">{{item.title}}</svg:text>
          <svg:text *ngIf="item" fill="#666" [attr.x]="item.x + 2" [attr.y]="32" text-anchor="left" style="font-weight:100;font-size: 10px;opacity: 0.9">min: {{item.min}}</svg:text>
          <svg:text *ngIf="item" fill="#666" [attr.x]="item.x + 60" [attr.y]="32" text-anchor="left" style="font-weight:100;font-size: 10px;opacity: 0.9">max: {{item.max}}</svg:text>
        </svg:g>
      </ng-template>
    </svg:g>

    <!-- selected day of week -->
    <svg:g class="selected-svg" style="pointer-events:none" [class.white]="theme==='white'">
      <ng-template ngFor let-item [ngForOf]="dow">
        <!--  <svg:line [attr.fill]="item.selected ? 'white' : fill" stroke="rgb(88,184,241)" stroke-width="1" [attr.x1]="item.plot.x" [attr.x2]="item.plot.x" [attr.y1]="item.plot.y" [attr.y2]="200"></svg:line> -->

        <svg:polyline *ngIf="item && item.selected" [attr.fill]="fill" stroke="white" stroke-width="1" style="opacity:0.9" [attr.points]="item.points">
        </svg:polyline>
      </ng-template>
    </svg:g>

    <!-- drill down overlay graph -->


  </svg>

  <svg class="day" *ngIf="dowClicked" [attr.viewBox]="viewbox" xmlns="https://www.w3.org/2000/svg" style="height:240px;width: 100%;">
    <svg:g (click)="legendClick($event, null)">

      <svg:rect fill="#e6e6e6" [attr.x]="0" [attr.y]="0" [attr.width]="width" [attr.height]="graphYBase" stroke-width="1" style="stroke: #e6e6e6;opacity: 0.9;pointer-events: all">

      </svg:rect>
      <svg:text fill="#666" [attr.x]="0" [attr.y]="(graphYBase - height) - 26" text-anchor="left" style="font-weight:100;font-size: 14px;opacity:1">{{dowClicked.title}} from {{dowClicked.plotted.stats.dateStart}} to {{dowClicked.plotted.stats.dateEnd}}</svg:text>
      <ng-template ngFor let-hour [ngForOf]="dowClicked.plotted.hours">
        <svg:g *ngIf="hour">
          <!-- hour line -->
          <svg:line [attr.fill]="'grey'" stroke="rgb(150,150,150)" stroke-width="1" stroke-dasharray="5,5" [attr.x1]="hour.x" [attr.x2]="hour.x"
            [attr.y1]="graphYBase" [attr.y2]="graphYBase - height"></svg:line>
          <svg:text fill="#888" [attr.x]="hour.x" [attr.y]="(graphYBase - height) - 16" text-anchor="left" style="font-weight:100;font-size: 10px;opacity: 0.9">{{hour.hour}}</svg:text>
        </svg:g>
      </ng-template>

      <svg:polyline fill="none" stroke="white" stroke-width="2" style="opacity:1" [attr.points]="dowClicked.plotted.toPoints">
        <svg:animate attributeName="points" dur="500ms" [attr.from]="dowClicked.plotted.fromPoints" [attr.to]="dowClicked.plotted.toPoints"></svg:animate>
      </svg:polyline>


      <svg:line stroke="orange" stroke-width="1" stroke-dasharray="1,1" [attr.x1]="dowClicked.plotted.xy.max.x" [attr.x2]="0" [attr.y1]="dowClicked.plotted.xy.max.y"
        [attr.y2]="dowClicked.plotted.xy.max.y">
        <animate attributeName="x2" dur="1500ms" [attr.from]="dowClicked.plotted.xy.max.x" [attr.to]="0" begin="1ms"></animate>
      </svg:line>
      <svg:line stroke="orange" stroke-width="1" stroke-dasharray="1,1" [attr.x1]="dowClicked.plotted.xy.min.x" [attr.x2]="0" [attr.y1]="dowClicked.plotted.xy.min.y"
        [attr.y2]="dowClicked.plotted.xy.min.y">
        <animate attributeName="x2" dur="1500ms" [attr.from]="dowClicked.plotted.xy.min.x" [attr.to]="0" begin="1ms"></animate>
      </svg:line>

      <!-- connect the text to the exact MAX point -->
      <svg:line stroke="orange" stroke-width="1" stroke-dasharray="1,1" [attr.x1]="dowClicked.plotted.xy.max.x " [attr.x2]="dowClicked.plotted.xy.max.x"
        [attr.y1]="dowClicked.plotted.xy.max.y - 6" [attr.y2]="dowClicked.plotted.xy.max.y" style="opacity: 0.8"></svg:line>

      <svg:text fill="black" [attr.x]="dowClicked.plotted.xy.max.x" [attr.y]="dowClicked.plotted.xy.max.y - 6" style="font-weight:100;font-size: 10px;opacity:0.9">{{dowClicked.plotted.xy.max.value}}</svg:text>


      <!-- connect the text to the exact MIN point -->
      <svg:line stroke="orange" stroke-width="1" stroke-dasharray="1,1" [attr.x1]="dowClicked.plotted.xy.min.x" [attr.x2]="dowClicked.plotted.xy.min.x"
        [attr.y1]="dowClicked.plotted.xy.min.y" [attr.y2]="dowClicked.plotted.xy.min.y < (graphYBase-16) ? dowClicked.plotted.xy.min.y + 8 : dowClicked.plotted.xy.min.y - 32"
        style="opacity: 0.8"></svg:line>

      <svg:text fill="black" [attr.x]="dowClicked.plotted.xy.min.x" [attr.y]="dowClicked.plotted.xy.min.y < (graphYBase-16) ? dowClicked.plotted.xy.min.y + 8 : dowClicked.plotted.xy.min.y - 32"
        style="font-weight:100;font-size: 10px;opacity:0.9">{{dowClicked.plotted.xy.min.value}}</svg:text>
    </svg:g>
  </svg>

</div>