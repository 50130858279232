import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AQService {

  constructor() { }

  AQFactory(data?: any): AQItem {
    return new AQItem(data);
  }
}

export class AQItem {
  id: string; // Gateway id
  title: string;
  lat: number;
  lng: number;
  voc: number;
  co2: number;
  radon: number;
  pressure: number;
  humidity: number;
  pm25: number;
  temp: number;
  updatedAt: Date;
  siteId: number;
  orgLogo: string;

  isOffline: boolean;
  isSelected: boolean;

  states: {
    voc: 'green' | 'red',
    temp: 'green' | 'red',
    humidity: 'green' | 'red',
    co2: 'green' | 'red'
  } = {
      co2: 'green',
      humidity: 'green',
      temp: 'green',
      voc: 'green'
    };

  state: 'green' | 'red' = 'green';

  static readonly info = {
    co2:
      '250 - 400 ppm: background (normal) outdoor air level. 400 - 1,000 ppm: typical level found in occupied spaces with good air exchange. 1,000 - 2,000 ppm: level associated with complaints of drowsiness and poor air. 2,000 - 5,000 ppm: level associated with headaches, sleepiness, and stagnant, stale, stuffy air.'
  };

  constructor(data?: any) {
    if (!data) {
      return;
    }
    this.co2 = data.co2;
    this.humidity = data.humidity || data.humid;
    this.radon = data.radon;
    this.pm25 = data.pm25;
    this.pressure = data.pressure;
    this.voc = data.voc || data.tvoc;
    this.lat = data.lat;
    this.lng = data.lng;
    this.title = data.title;
    this.temp = data.temp;
    
    this.updatedAt = new Date(data.updatedAt);
    // Over 24 hours mark as offline
    this.isOffline = ((+new Date()) - (+this.updatedAt)) > ( 60 * 60 * 24 * 1000);
    this.id = data.id;
    this.siteId = data.siteId;
    this.orgLogo = data.orgLogo;
    if (this.co2 >= 1000) {
      this.redState('co2');
    }
    if (this.humidity < 40 || this.humidity > 60) {
      this.redState('humidity');
    }
    if (this.temp < 20 || this.temp > 24) {
      this.redState('temp');
    }
    if (this.voc > 500) {
      this.redState('voc');
    }
  }

  redState(property: string) {
    this.states[property] = 'red';
    this.state = 'red';
  }

}