import { Ticket } from "./ticket";
import { Asset } from "./asset";
export class AssetTicket extends Ticket {
    asset: Asset;
    override isAssetTicket: boolean = true;

    constructor(data?: any) {
        super(data);
        this.type = 'asset';
        let asset = new Asset();
        asset.id = data.assetId;
        asset.title = data.assetTitle;

        this.asset = asset;
    }

    override serialise() {
        let serialise = super.serialise();
        serialise.assetId = this.asset.id;

        return serialise;
    }

    display() {
        return this.asset.title + ' sensor at ' + this.site.title;
    }


}
