import { Component, signal, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrl: './select.component.css'
})
export class SelectComponent {
  _list = signal<SelectListItem[]>([]);
  _value = signal(null);
  _block = signal(true);
  _placeholder = signal(null);
  _caption = signal<string>(null);
  model: any = '';

  @Input()
  public set list(v: SelectListItem[]) {
    this._list.set(v);
  }

  @Input()
  public set value(v: any) {
    this._value.set(v);
    this.model = v;
  }

  @Input()
  public set block(v: boolean) {
    this._block.set(v);
  }

  @Input()
  public set placeholder(v: string) {
    this._placeholder.set(v);
  }

  @Input()
  public set caption(v: string) {
    this._caption.set(v);
  }

  @Output()
  onSelected: EventEmitter<any> = new EventEmitter();

  changed() {
    console.log(`changed`, this.model);
    this.onSelected.emit(this.model);
  }
}

export interface SelectListItem {
  id: number | string;
  title: string;
}
