@defer() {
<main>
	<header>
		<div>
			<span class="hover-toggle" (click)="goBack()"> <i class="mdi mdi-chevron-left-circle-outline can-click is-hover"></i> <i class="mdi mdi-chevron-left not-hover"></i></span>
		</div>
		<div class="title can-click" (click)="clickTitle()">{{_title()}}</div>
		<div class="hover-toggle">
			<span (click)="goForward()"><i class="mdi mdi-chevron-right-circle-outline can-click is-hover"></i> <i class="mdi mdi-chevron-right not-hover"></i></span>
		</div>
	</header>
	<div class="header">
		<div>
			<div>Mo</div>
		</div>
		<div>
			<div>Tu</div>
		</div>
		<div>
			<div>We</div>
		</div>
		<div>
			<div>Th</div>
		</div>
		<div>
			<div>Fr</div>
		</div>
		<div>
			<div>Sa</div>
		</div>
		<div>
			<div>Su</div>
		</div>
	</div>
	@for(row of rows;track row) {
	<div class="week">
		@for(day of row; track day) {
		<div [class.is-other-month]="day.isOtherMonth" [class.is-selected]="day.isSelected" [class.is-future]="day.isFuture" [class.has-items]="day.hasItems" [tooltip]="day.tooltip" [class.is-today]="day.isToday">
			<div (click)="clickDay(day)">{{day.date | amDateFormat:'D'}}</div>
		</div>
		}
	</div>
	}
</main>
} @placeholder (minimum 100) {}
