@defer (when asset) {
<article>
	<main>
		<section>
			<div>
				<label>Tenant</label>{{asset.tenantTitle}}
			</div>
			@if (tenantPercs()) {
			<div class="percs">
				<table class="table table-sm">
					<thead>
						<tr>
							<th>Date</th>
							<th>Occupation %</th>
						</tr>
					</thead>
					<tbody>
						@for(perc of tenantPercs(); track $index) {
						<tr>
							<td width="160px" style="font-family: monospace;">{{perc.forDate | date:'EEE dd MMM YY' }}</td>
							<td>
								<app-occ-percentage-bar [perc]="perc.percentage"></app-occ-percentage-bar>
							</td>
						</tr>
						}
					</tbody>
				</table>
			</div>
			}
			<div>
				<label>Service</label>{{asset.serviceTitle}}
			</div>
			@if(servicePercs()) {
			<div class="percs">
				<table class="table table-sm">
					<thead>
						<tr>
							<th>Date</th>
							<th>Occupation %</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let perc of servicePercs()">
							<td width="160px" style="font-family: monospace;">{{perc.forDate | date:'EEE dd MMM YY' }}</td>
							<td>
								<app-occ-percentage-bar [perc]="perc.percentage"></app-occ-percentage-bar>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			}
		</section>
	</main>
</article>
} @placeholder (minimum 100ms) {
<i class="mdi mdi-loading mdi-spin-x2"></i>
}
