import { Component, Input, OnInit } from '@angular/core';
import { APIService } from 'app/shared/api.service';
import { Site } from 'app/classes/site';
import { IGetWeeklyForSiteResponse, OccupancyService } from 'app/shared/occupancy.service';

import moment from 'moment';

@Component({
  selector: 'app-spd-weekly',
  templateUrl: './spd-weekly.component.html',
  styleUrls: ['./spd-weekly.component.css']
})
export class SpdWeeklyComponent implements OnInit {

  @Input()
  site: Site;

  @Input()
  public set date(d: Date) {

    this.dateFrom = d;

    if (d) {
      this.get();
    }

  }

  dateFrom: Date;

  occupancyStats: OccupancySiteWeeklyStats;

  canGoNextWeek: boolean = true;
  pins: { date: number };

  constructor(private apiService: APIService, private occupancyService: OccupancyService) {
    this.dateFrom = moment().isoWeekday(1).startOf('isoWeek').startOf('day').toDate();
    // this.pins = { date: +this.dateFrom };
    //this.getLimage.pngocalStorage();
  }

  getLocalStorage() {
    const storage = localStorage.getItem('spd-weekly:pins');
    if (storage) {
      const obj = JSON.parse(storage);
      this.pins = obj;
      this.dateFrom = new Date(this.pins.date);
    }
  }

  setLocalStorage() {
    let pins: any = this.pins;
    localStorage.setItem('spd-weekly:pins', JSON.stringify(pins));
  }

  ngOnInit(): void {

  }

  get() {
    console.log('GET', this.dateFrom, this.site);
    this.occupancyService.getWeeklyForSite(this.site.id, this.dateFrom)
      .then(response => {
        this.occupancyStats = response;
      });
  }

  previousWeek() {
    this.dateFrom = moment(this.dateFrom).subtract(1, 'week').toDate();
    this.pins.date = +this.dateFrom;
    this.setLocalStorage();
    this.canGoNextWeek = true;
    this.get();
  }

  nextWeek() {
    this.dateFrom = moment(this.dateFrom).add(1, 'week').toDate();
    this.pins.date = +this.dateFrom;
    this.setLocalStorage();
    this.get();
  }
}

export class OccupancySiteWeeklyStats {
  assets: {
    [assetId: number]: {
      dow: number[],
      perc?: number[],
      gateway_id: string,
      title: string,
      a_id: number,
      coll_id: number
    }
  };
  collections: { [collectionId: number]: { i: number, org_id: number, t: string, days: { coll_id: number, d: number, days: number[], endHour: number, startHour: number, startTime: string, endTime: string }[] } };
  values: { d: number, a_id: number, forDate: Date }[];
  assetIds: number[];

  constructor(data?: IGetWeeklyForSiteResponse) {
    this.assets = data.assets;
    this.collections = data.collections;
    this.values = data.values;
    this.assetIds = Object.keys(data.assets).map(a => +a);

    // Reset percentages for days
    this.assetIds.forEach(id => {
      const asset = this.assets[id];
      asset.perc = [0, 0, 0, 0, 0, 0, 0];
      for (let index = 0; index < asset.dow.length; index++) {
        const duration = asset.dow[index];
        const collection = this.collections[asset.coll_id].days.find(day => day.days[index]);
        asset.perc[index] = duration ? +(duration / (collection.d / 100)).toFixed(0) : 0;
        if (asset.perc[index] > 100) {
          asset.perc[index] = 100;
        }
      }


    });


  }
}
