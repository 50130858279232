import { User } from "./user";

export class Grouping {
    id?: number;
    title: string;
    notes: string;
    createdBy: User;
    createdAt: Date;
    users: User[] = [];
    userCount: number;
    selected: boolean;

    constructor(data?: any) {
        if (!data) {
            return;
        }
        this.id = data.grouping_id || data.id || null;
        this.title = data.groupingTitle || data.title;
        this.notes = data.notes;
        this.createdAt = new Date(data.createdAt);
        this.createdBy = new User({ id: data.createdBy, name: data.createdByName });
        if (data.users) {
            this.users = data.users.map(u => new User({id:u.id, name:u.name}));
            this.userCount = this.users.length;
        } else {
            this.userCount = data.userCount;
        }
    }

    serialise() {
        return {
            id: this.id,
            title: this.title,
            notes: this.notes,
            users: this.users.map(u => u.id)
        }
    }
}