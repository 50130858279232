<div [class.caption]="_caption()">
	@if(_caption()) {
	<label>{{_caption()}}</label>
	}
	<app-button [selected]="_selected()" [size]="_size()" [label]="label()" (onAction)="_onDialog.set(true)" rightAction="configure" margin="1px 0 1px 1px" [hasRightBorder]="false" (click)="clickDates()"></app-button>
</div>

@if(_onDialog() ) {
<app-dialog [canScroll]="false" (onClose)="closed($event)" [dimensions]="{width:660, height:350}" header="Select date range">
	<app-toolbar size="small">
		<div class="right">
			<app-button label="Last Month" icon="mdi mdi-calendar" size="small" (click)="clickLastMonth()"></app-button>
			<app-button label="This Month" icon="mdi mdi-calendar" class="mr-1" size="small" (click)="clickThisMonth()"></app-button>
			@if (asset()?.ingestRatePerDay === 1) {
			<app-button label="All data" icon="mdi mdi-calendar" class="mr-1" size="small" (click)="clickAllData()"></app-button>
			}
			<app-button label="Cancel" icon="mdi mdi-close" size="small" (click)="clickCancel()"></app-button>
		</div>
	</app-toolbar>
	<section class="dates mt-1">
		@if(_config()) {
		<div>
			<app-calendar [value]="_config().from" (onSelect)="changeDate('from', $event)"></app-calendar>
			@if(showMinMaxButton()) {
			<div>
				<app-button label="Start of telemetry" size="small" icon="mdi mdi-format-horizontal-align-left" (click)="gotoStartOfTelemetry()" />
			</div>
			}
		</div>
		<div>
			<app-calendar [value]="_config().to" (onSelect)="changeDate('to', $event)"></app-calendar>
			@if(showMinMaxButton()) {
			<div>
				<app-button label="Latest telemetry" size="small" icon="mdi mdi-arrow-collapse-right" (click)="gotoEndOfTelemetry()" iconPos="right"></app-button>
			</div>
			}
		</div>
		}
	</section>
</app-dialog>
}
