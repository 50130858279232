import { BillingClient } from '../classes/billing-client';
import { Building } from './building';
import { Gateway } from './gateway';
import { LicensePackage } from './license-package';

export class License {

	static readonly BILLING_NAMES = {
		ANNUALLY: 'annually',
		MONTHLY: 'monthly',
		QUARTERLY: 'quarterly'
	};

	static readonly LICENSE_FOR = {
		BUILDING: 'building',
		GATEWAY: 'gateway'
	};

	id: number;
	value: number;
	commission: number;
	licensedAt: Date;
	hasExpired: boolean;
	expiresAt: Date;
	billingClient: BillingClient = new BillingClient();
	billingCycle: 'annually' | 'monthly' | 'quarterly' = 'annually';
	isActive: boolean;
	notes: string;
	status: string;
	package: LicensePackage;
	neverExpires: boolean;
	licenseFor: string;
	title: string = 'unlinked license';
	gateway: Gateway;

	constructor(data?: any) {
		if (data) {
			this.licensedAt = new Date(data.licensedAt);
			this.commission = data.commission;
			this.value = data.value;
			this.billingCycle = data.billingCycle;
			if (typeof data.lia === 'number') {
				this.isActive = (data.lia === 1);
			} else {
				this.isActive = (data.isActive === true || data.isActive === 'Y');
			}

			this.id = data.licenseId || data.id;
			this.notes = data.lNotes || data.licenseNotes || data.notes;
			this.status = data.licenseStatus || data.status;
			this.neverExpires = !!data.neverExpires;
			if (!this.neverExpires) {
				this.expiresAt = new Date(data.expiresAt);
			}
			if (data.package) {
				this.package = data.package;
			} else {
				this.package = new LicensePackage(data.package_id);
			}
			this.hasExpired = (+this.expiresAt < +new Date()) && !this.neverExpires;
			if (data.billingClient?.id) {
				this.billingClient = data.billingClient;
			} else if (data.billing_client_id) {
				this.billingClient = new BillingClient(data);
			} else {
				this.billingClient = new BillingClient();
			}
		} else {
			this.billingCycle = 'annually';
			this.isActive = true;
			this.value = 1200;
			this.neverExpires = false;
			this.licensedAt = new Date();
			this.expiresAt = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
			this.status = null;
			this.package = new LicensePackage();
		}
	}

	/**
	 * Advance the license expiry to a future date - may jump multiple years.
	 */
	advanceExpiryToFutureDate() {
		const now = new Date();
		const proposedDate = new Date(this.expiresAt);
		proposedDate.setFullYear(now.getFullYear());
		if (+proposedDate < +now) {
			proposedDate.setFullYear(now.getFullYear() + 1);
		}
		return proposedDate
	}

	serialise() {
		return {
			id: this.id,
			licensedAt: this.dt(this.licensedAt),
			expiresAt: this.dt(this.expiresAt),
			billingCycle: this.billingCycle,
			notes: this.notes,
			package_id: this.package.id,
			status: this.status,
			value: this.value,
			neverExpires: this.neverExpires ? 1 : 0,
			isActive: this.isActive ? 'Y' : 'N',
			billing_client_id: this.billingClient?.id,
			commission: this.commission
		}
	}

	dt(dt: Date) {
		const month = '00' + (dt.getMonth() + 1);
		const day = '00' + dt.getDate();
		return (dt.getFullYear() + '-' + month.substring(month.length - 2) + '-' + day.substring(day.length - 2));
	}


}

export class LicenseBuilding extends License {
	building: Building;
	override licenseFor = 'building';
	declare title: string;

	constructor(data?: any) {
		super(data);
		if (data) {
			this.setBuilding(new Building(data));
		}
	}

	setBuilding(building: Building) {
		this.building = building;
		this.title = building.title;
	}
}

export class LicenseGateway extends License {
	override licenseFor = 'gateway';
	declare title: string;
	constructor(data?: any) {
		super(data);
		if (data) {
			this.gateway = new Gateway(data);
		}
	}

	setGateway(gateway: Gateway) {
		this.gateway = gateway;
		this.title = gateway.title;
	}
}

/*

export class BuildingLicense {
	license: License;
	building: Building;

	constructor(data) {
		this.building = new Building(data);
		this.license = new License({
			id: data.licenseId,
			isActive: 'Y',
			package_id: data.package_id,
			expiresAt: data.expiresAt,
			licensedAt: data.licensedAt,
			billingCycle: data.billingCycle,
			value: data.value
		});
		this.building.licenses = [this.license];
	}
}

export class GatewayLicense {
	license: License;
	gateway: Gateway;

	constructor(data) {
		this.gateway = new Gateway(data);
		this.license = new License({
			id: data.licenseId,
			isActive: 'Y',
			package_id: data.package_id,
			expiresAt: data.expiresAt,
			licensedAt: data.licensedAt,
			billingCycle: data.billingCycle,
			value: data.value
		});
	}
}
*/
