<app-theme-page-section>

    <main>
        <section class="details">
            <div *ngFor="let grouping of groupings">
                <div class="grouping" [class.active]="groupingActive.id === grouping.id" (mouseenter)="groupingActive = grouping" (click)="grouping.selected = (grouping.selected ? false : true)">
                    <app-tick [selected]="grouping.selected"></app-tick>&nbsp; {{grouping.title}}
                </div>
            </div>
        </section>
        <section class="users">
            <div>
                <p-scrollPanel [style]="{width: '100%', height: '340px'}">
                    <div class="selected" *ngIf="groupingActive">
                        <label>In Group</label>
                        <p *ngIf="groupingActive.users.length===0"><i class="red">No users</i></p>
                        <ul>
                            <li *ngFor="let user of groupingActive?.users"> {{user.name}}</li>
                        </ul>
                        <p class="notes" [innerHTML]="groupingActive.notes"></p>
                    </div>
                </p-scrollPanel>
            </div>
        </section>
    </main>
    <footer>
        <div class="buttons">
            <div class="text-right">
                <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-outlined p-button-secondary mr-1" (click)="cancel()">
                </p-button>
                <p-button [label]="'Update'" icon="mdi mdi-content-save" styleClass="" (click)="submit()">
                </p-button>
            </div>
        </div>
    </footer>

</app-theme-page-section>