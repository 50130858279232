export class Address {
    addressId: number;
    addressTitle: string;
    address1: string;
    address2: string;
    address3: string;
    addressTown: string;
    addressCounty: string;
    addressPostcode: string;
    addressTel: string;
    addressEmail: string;
    addressLong: string;
    addressLat: string;
    addressCountry: string;
    formattedForHTML: string;

    constructor(data?: any) {
        data = Object.assign({}, data);
        this.addressId = data.addressId || null;
        this.addressTitle = data.addressTitle || '';
        this.address1 = data.address1 || '';
        this.address2 = data.address2 || '';
        this.address3 = data.address3 || '';
        this.addressTown = data.addressTown || '';
        this.addressCounty = data.addressCounty || '';
        this.addressPostcode = data.addressPostcode || '';
        this.addressTel = data.addressTel || '';
        this.addressEmail = data.addressEmail || '';
        this.addressLong = data.addressLong || '';
        this.addressLat = data.addressLat || '';
        this.addressCountry = data.addressCountry || 'gb';
        this.formattedForHTML = this.displayForHTML();
    }

    displayForLine() {
        return this.displayWithJoin(', ');
    }

    displayForHTML() {
        return this.displayWithJoin('<br/>');
    }

    displayWithJoin(character: string) {
        let display = this.address1;
        if (this.address2) display += character + this.address2;
        if (this.address3 && (this.address2 !== this.address3)) display += character + this.address3;
        if (this.addressTown) display += character + this.addressTown;
        if (this.addressCounty && (this.addressCounty !== this.addressTown)) display += character + this.addressCounty;
        //if (this.addressCountry) display += character + this.addressCountry;
        if (this.addressPostcode) display += character + this.addressPostcode;

        return display;
    }

    serialise() {
        return {
            addressId: this.addressId,
            addressTitle: this.addressTitle,
            address1: this.address1,
            address2: this.address2,
            address3: this.address3,
            addressTown: this.addressTown,
            addressCounty: this.addressCounty,
            addressPostcode: this.addressPostcode,
            addressTel: this.addressTel,
            addressEmail: this.addressEmail,
            addressLong: this.addressLong,
            addressLat: this.addressLat
        }
    }
}
