<div class="rating" [style.width.px]="width">
	<div>
		<div>(80 to 100)</div>
		<div>A</div>
		<div *ngIf="_value >= 80">
			<i class="f-green fa fa-caret-left "></i>
			<span class="b-green">{{ _value + '%'}}</span>
		</div>
	</div>
	<div>
		<div>(60 to 80)</div>
		<div>B</div>
		<div *ngIf="_value >= 60 && _value < 80">
			<i class="f-lightgreen fa fa-caret-left "></i>
			<span class="b-lightgreen">{{ _value + '%'}}</span>
		</div>
	</div>
	<div>
		<div>(40 to 60)</div>
		<div>C</div>
		<div *ngIf="_value >= 40 && _value < 60"><i class="f-yellow fa fa-caret-left "></i>
			<span class="b-yellow">{{_value + '%'}}</span>
		</div>
	</div>
	<div>
		<div>(20 to 40)</div>
		<div>D</div>
		<div *ngIf="_value >= 20 && _value <= 40">
			<i class="f-orange fa fa-caret-left "></i>
			<span class="b-orange">{{_value + '%' }}</span>
		</div>
	</div>
	<div>
		<div>(0 to 20)</div>
		<div>E</div>
		<div *ngIf="_value < 20 ">
			<i class="f-red fa fa-caret-left "></i>
			<span class="b-red">{{_value + '%'}}</span>
		</div>
	</div>
</div>
