// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  mapbox: {
    accessToken: 'pk.eyJ1IjoibWFuYWdlZm91cmQiLCJhIjoiY2plM3lkeWlhMm9xYTM0dGI4cWNhejhhbyJ9.Wbh0uhDVLa7WHL3IvSu8_g'
  },
  api: {
    url: 'https://55auilnnlh.execute-api.eu-west-2.amazonaws.com/4d/'
  },
  region: 'eu-west-2',
  stage: 'alpha'
};
