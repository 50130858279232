import { Component, Input, OnInit } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { APIService } from 'app/shared/api.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-dialog-asset-edit',
  templateUrl: './dialog-asset-edit.component.html',
  styleUrls: ['./dialog-asset-edit.component.css'],
  providers: [DialogService]
})
export class DialogAssetEditComponent implements OnInit {

  asset: Asset;
  isWorking: boolean;
  title: string;
  location: string;

  constructor(public dialogService: DialogService, public ref: DynamicDialogRef, public config: DynamicDialogConfig, private apiService: APIService) {

  }

  ngOnInit(): void {
    this.asset = this.config.data.asset || new Asset();
    this.title = this.asset.title;
    this.location = this.asset.location;
  }

  cancel() {
    this.ref.close();
  }

  update() {
    if (this.isWorking) {
      return;
    }
    this.isWorking = true;

    const fn = [];
    if (this.location !== this.asset.location) {
      fn.push(this.apiService.save(this.asset.id, 'assets.location', this.asset.location));
    }
    if (this.title !== this.asset.title) {
      fn.push(this.apiService.save(this.asset.id, 'assets.display', this.asset.title));
    }

    if (fn.length === 0) {
      return this.ref.close();
    }

    Promise.all(fn).then(r => {
      this.isWorking = false;
      this.ref.close(this.asset);
    });
  }
}

