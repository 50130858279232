export class ElogbooksReactiveJob {
	id: number;
	description: string;
	summary: string;
	jobType: string;
	userId: number;
	createdAt: Date;
	site: { id: number, title: string } = { id: null, title: null };
	status: string;
	statusDisplay: string;
	isFinalStatus: boolean;
	poRequired: boolean;
	siteContactAvailableOnSite: boolean;
	reminderAt: Date;
	links: any;

	static readonly statuses = {
		'approval_rejected': 'Approval was rejected',
		'created': 'Has been created',
		'approval_pending': 'Approval is pending',
		'assignment_pending': 'Assignment is pending',
		'assignment_proposal': 'Assignment proposal',
		'assignment_rejected': 'Assignment was rejected',
		'acknowledged': 'Has been acknowledged',
		'commenced': 'Has commenced',
		'missed': 'Was missed',
		'cancelled': 'Has been cancelled',
		'completed': 'Has been completed',
		'awaiting_paperwork': 'Is awaiting paperwork',
		'extension_pending': 'extension_pending',
		'extension_rejected': 'extension_rejected:'
	};


	constructor(data?: any) {
		if (!data) {
			return;
		}
		this.id = data.job_id || data?.id;
		this.description = data?.description || data?.jobdesc;
		this.summary = data?.summary || data?.jobsum;
		this.jobType = data?.jobtype;
		this.userId = data?.user_id;
		this.createdAt = data?.createdAt ? new Date(data.createdAt) : null;
		if (data.settings) {
			const obj = JSON.parse(data.settings);
			this.site = { id: obj?.siteId, title: obj?.siteTitle };
		}
	}

	importFromElogbooks(data: any) {
		this.id = data.id;
		this.summary = data.summary;
		this.description = data.description;
		this.jobType = data.type;
		this.status = data.status;
		this.statusDisplay = ElogbooksReactiveJob.statuses[this.status];
		this.isFinalStatus = data.is_final_status;
		this.poRequired = data.poRequired;
		this.siteContactAvailableOnSite = data.siteContactAvailableOnSite;
		this.createdAt = new Date(data.createdAt);
		this.reminderAt = data.reminderAt ? new Date(data.reminderAt) : null;
		this.links = data._links;
		this.site.title = data._links.site?.title;
	}
}
