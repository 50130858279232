export class ComplianceItem {
	frequency: 'month' | 'week' | 'day' = 'month';
	createdAt: Date;
	id: number = null;
	title: string;
	description: string;
	org: { id: number };
	values: any[] = [];
	sites: any[] = [];
	assets: any[] = [];

	compliance: {
		noncompliant: number,
		compliant: number
	};

	constructor(data?: any) {
		if (!data) return;

		this.frequency = data.checkEvery;
		this.createdAt = new Date(data.createdAt);
		this.id = data.id;
		this.org = { id: data.org_id };
		this.title = data.title;
		if (data.assets) {
			this.assets = data.assets.map(a => {
				return {
					id: a.aId,
					title: a.aTitle,
					site: { id: a.sId, title: a.sTitle }
				};
			}).sort((a, b) => a.title > b.title ? 1 : -1);
		}
	}

	serialise() {
		return {
			id: this.id || null,
			title: this.title,
			description: this.description,
			frequency: this.frequency,
			assets: this.assets.map(a => a.id)
		}
	}
}
