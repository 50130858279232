import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SetpointsService } from 'app/shared/setpoints.service';
import { StoreService } from 'app/shared/store.service';
@Component({
  selector: 'app-dialog-setpoints-delete',
  templateUrl: './dialog-setpoints-delete.component.html',
  styleUrls: ['./dialog-setpoints-delete.component.css']
})
export class DialogSetpointsDeleteComponent {

  action: string;
  rangeId: 2 | 3;
  setpoints: any[];

  constructor(public storeService: StoreService, public dialogService: DialogService, public config: DynamicDialogConfig, public ref: DynamicDialogRef, public messageService: MessageService, public setpointsService: SetpointsService) {
    if (this.config.data.setpoints) {
      this.setpoints = this.config.data.setpoints;
      this.action = this.config.data.action;
      this.rangeId = this.config.data.rangeId;
    }
  }

  cancel() {
    this.ref.close();
  }

  async submit() {

    // const response = await this.setpointsService.updateTemplateCategory(this.setpointTemplateCategory);

    //   console.log(response);

    //   this.ref.close(this.setpointTemplateCategory);
  }


  async disableSetpoints() {
    try {
      await this.setpointsService.disableSetpoints(this.setpoints.map(s => s.id), this.rangeId);
    } catch (e) { }
    this.storeService.setDataChanged('setpoints', 'changed', this.setpoints);
    this.ref.close();
  }

  async deleteSetpoints() {
    await this.setpointsService.deleteSetpoints(this.setpoints.map(s => s.id), this.rangeId);
  }

}
