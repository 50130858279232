import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { Gateway } from 'app/classes/gateway';
import { Site } from 'app/classes/site';
import { WeatherItem } from 'app/classes/weather-item';
import { APIService } from 'app/shared/api.service';
import mapboxgl from 'mapbox-gl';

declare const google: any;

@Component({
  selector: 'app-explorer-site',
  templateUrl: './explorer-site.component.html',
  styleUrls: ['./explorer-site.component.css']
})
export class ExplorerSiteComponent implements OnInit, AfterViewInit {

  _site: Site;
  @Input()
  public set site(v: Site) {
    this._site = v;
    if (v) {
      this.view = 'gateways';
      this.get();
    }
  }

  _selectMode = signal<boolean>(false);
  @Input()
  public set selectMode(v: boolean) {
    this._selectMode.set(v);
  }

  public get site(): Site {
    return this._site;
  }

  @Input()
  gatewaysChecked = {};

  // Used by org-map
  @Input()
  weather: WeatherItem;

  @Input()
  showMapControls = false;

  @Input()
  showId = true;

  @Input()
  canNavigate = false;

  @Output()
  onSelected: EventEmitter<Gateway> = new EventEmitter<Gateway>();

  @Output()
  isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onGatewayChecked = new EventEmitter<Gateway>();

  gateways: Gateway[];
  gateway: Gateway;
  asset: Asset;
  options: any;
  mapOptions: any;
  defaults: any = { zoom: 15, hideSiteDetails: false, showAllGateways: false, showWeather: true };
  report: any;
  view: 'gateways' | 'assets' | 'asset' = 'gateways';
  isAdmin: boolean;

  ageOfAssets: Date;

  weatherMarkerSvg = {
    path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
    fillColor: "blue",
    fillOpacity: 0.6,
    strokeWeight: 0,
    rotation: 0,
    scale: 2,
    anchor: new google.maps.Point(0, 20),
  };
  weatherOptions: any;

  mapSettings: { center: mapboxgl.LngLatLike, markers: mapboxgl.LngLatLike[] };

  constructor(private apiService: APIService) {
    this.loadDefaults();
    this.isAdmin = apiService.isAdmin();
    this.weatherOptions = { icon: this.weatherMarkerSvg };
  }

  ngOnInit(): void {
    this.mapOptions = {
      disableDefaultUI: !this.showMapControls
    };
  }

  select(item: Gateway, event: any) {
    event.stopPropagation();
    item.checked = !item.checked;
    this.onGatewayChecked.emit(item);
    this.gatewaysChecked[item.id] = item.checked;
  }

  showWeatherOnMap() {
    this.toggleDefault('showWeather');
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.options = {
        animation: google.maps.Animation.DROP,
        clickable: false
      };
    }, 100);
  }

  gatewayClick(gateway: Gateway) {
    this.onSelected.emit(gateway);
    if (this.canNavigate) {
      this.apiService.getAssetsForGatewayId(gateway.id).then(a => {
        gateway.assets.addAll(a);
        this.view = 'assets';
        this.gateway = gateway;
      });
    }
  }

  assetCick(asset: Asset) {
    this.asset = asset;
  }

  showAllGatewaysClick() {
    this.defaults.showAllGateways = !this.defaults.showAllGateways;
    this.saveDefaults();
    this.get();
  }

  get() {
    this.isLoading.emit(true);

    if (!this.site.createdAt || !this.site.localWeatherLat) {
      this.apiService.getSite(this.site.id).then(site => {
        this._site = site;
        if (site?.address?.addressLat) {

          const markers = [];

          const siteMarker = new mapboxgl.Marker();
          siteMarker.setLngLat({
            lat: +site.address.addressLat,
            lng: +site.address.addressLong
          });

          markers.push(siteMarker);

          this.mapSettings = {
            center: {
              lat: +site.address.addressLat,
              lng: +site.address.addressLong
            },
            markers
          };
        }
      });
    }

    this.apiService
      .getGatewaysForSiteId(this.site.id)
      .then(gateways => {
        if (this.defaults.showAllGateways) {
          this.gateways = gateways;
        } else {
          this.gateways = gateways.filter(gateway => (gateway.assetCount && (gateway.statusTitle === 'Deployed' || gateway.statusTitle === 'Demo' || gateway.statusTitle === 'Faulty')));
        }

        this.gateways.forEach(g => g.checked = this.gatewaysChecked[g.id]);

        this.isLoading.emit(false);
      });
  }

  loadDefaults() {
    const defaults = localStorage.getItem('explorer:defaults') || '{}';
    this.defaults = {
      zoom: 15,
      hideSiteDetails: false,
      showWeather: true,
      ...JSON.parse(defaults)
    };
  }

  saveDefaults() {
    const defaults = {
      hideSiteDetails: this.defaults.hideSiteDetails,
      showAllGateways: this.defaults.showAllGateways,
      showWeather: this.defaults.showWeather
    }
    localStorage.setItem('explorer:defaults', JSON.stringify(defaults));
  }

  ragReportClick() {
    this.report = { type: 'rag', site: this.site };
  }

  toggleDefault(prop: string) {
    this.defaults[prop] = !this.defaults[prop];
    this.saveDefaults();
  }
}
