import { User } from "../user";
/**
 * @version 1
 */
export class RuleNotification {
  id: number;
  method: string; // only email is supported
  value: string | number;
  states: string[]; // Emtpty for all states
  isSuspended: boolean;

  constructor(id?: number, method?: string, value?: string, states?: any, isSuspended?: boolean) {
    this.id = id || 0;
    this.method = method || 'email';
    this.value = value || null;
    this.isSuspended = isSuspended;
    if (typeof states === 'string' && states) {
      this.states = states.split(',');
    } else {
      this.states = ['new', 'acknowledged', 'paused', 'resolved'];
    }
  }
  // Create rule notification for user id
  forUser(user: User) {
    this.value = user.email;
    this.method = 'email';
  }
}
