import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { OrgExport } from 'app/org-dashboard/org-export/org-export';
import { DataForm } from '../data-form/data-form.component';

@Component({
  selector: 'app-button-trigger',
  templateUrl: './button-trigger.component.html',
  styleUrl: './button-trigger.component.css'
})
export class ButtonTriggerComponent {
  _exportItem = signal<OrgExport>(null);
  _caption = signal<string>(null);
  label = signal<string>('Create a trigger');
  _onDialog = signal<boolean>(false);
  _triggerDataForm = signal<DataForm>(null);

  @Input()
  public set caption(v: string) {
    this._caption.set(v);
  }

  @Input()
  public set exportItem(v: OrgExport) {
    this._exportItem.set(v);
    this.changed();
  }

  public get exportItem(): OrgExport {
    return this._exportItem();
  }

  @Input()
  size = signal<string>('medium');

  @Output()
  onSelect = new EventEmitter<OrgExport>();

  showDialog() {
    this._triggerDataForm.set(this.exportItem.getConditionAsDataForm(0));
    this._onDialog.set(true);
  }

  changed() {
    let label = '';

    if (!this.exportItem || this.exportItem.conditions.length === 0) {
      this.label.set('Create a trigger');
      return;
    }
    const condition = this.exportItem.conditions[0];
    const operands = { 'lt': 'Less than', 'eq': 'Equal to', 'lte': 'Less than or equal to', 'gte': 'Greater than or equal to', 'gt': 'Greater than' };

    label = `A sensor is ${operands[condition.operand]} ${condition.value} between ${condition.timespan.from} and ${condition.timespan.to}`;
    this.label.set(label);
  }

  conditionClosed(ev: any) {
    this._onDialog.set(false);
    if (!ev) {
      return;
    }

    switch (ev.id) {
      case 'save':
        this._exportItem.update((item) => {
          item.setConditionFromDataForm(this._triggerDataForm());
          return item;
        });
        this.changed();
        this.onSelect.emit(this._exportItem());
        break;
    }
    console.log(ev);
  }

}
