export class RuleTimeRange {
	id: number;
	dayOfWeeks: number[];
	startTimeAt: string;
	endTimeAt: string;

	monday: boolean;
	tuesday: boolean;
	wednesday: boolean;
	thursday: boolean;
	friday: boolean;
	saturday: boolean;
	sunday: boolean;

	setDOW(dow: string, v: boolean) {
		this[dow] = v;
	}

	days = { monday: 1, tuesday: 2, wednesday: 3, thursday: 4, friday: 5, saturday: 6, sunday: 0};

	invertDOW(dow: string | number) {
		if ( dow !== 'string') { 
			dow = ['sunday','monday','tuesday','wednesday','thursday','friday','saturday'][dow];
		}
		this[dow] = this[dow] ? this[dow] = false : this[dow] = true;

		this.convertBooleanToDow();
	}

	convertBooleanToDow() {
		this.dayOfWeeks = [];
		
		['sunday','monday','tuesday','wednesday','thursday','friday','saturday'].map( day => {
			if (this[day]) { 
				this.dayOfWeeks.push(this.days[day]);
			}
		});
	}

	constructor(id?: number, dows?: any, startTimeAt?: string, endTimeAt?: string) {
		this.id = id;

		if (dows && dows.constructor === String) {
			dows = dows.split(',');
		}
		this.dayOfWeeks = dows || [];

		this.setupConvenienceProperties();

		// Not interested in seconds
		this.startTimeAt = startTimeAt ? startTimeAt.substr(0, 5) : '';
		this.endTimeAt = endTimeAt ? endTimeAt.substr(0, 5) : '';
	}

	isRestrictedForDOW(dow: number) {
		return this.dayOfWeeks.filter(d => d == dow).length;
	}

	toggleDOW(dow: number) {
		for (var index = 0; index < this.dayOfWeeks.length; index++) {
			var element = this.dayOfWeeks[index];
			if (+element === dow) {
				this.dayOfWeeks = this.dayOfWeeks.filter(day => +day !== dow);
				this.setupConvenienceProperties();

				return;
			}
		}
		this.dayOfWeeks.push(dow);
		this.setupConvenienceProperties();
	}


	setupConvenienceProperties() {

		this.monday = false;
		this.tuesday = false;
		this.wednesday = false;
		this.thursday = false;
		this.friday = false;
		this.saturday = false;
		this.sunday = false;

		this.dayOfWeeks.forEach(d => {
			switch (+d) {
				case 1:
					this.monday = true;
					break;
				case 2:
					this.tuesday = true;
					break;
				case 3:
					this.wednesday = true;
					break;
				case 4:
					this.thursday = true;
					break;
				case 5:
					this.friday = true;
					break;
				case 6:
					this.saturday = true;
					break;
				case 0:
					this.sunday = true;
					break;

			}
		});
	}

	getDOWAsNames(): string {
		let names: string[] = [];
		this.dayOfWeeks.forEach(d => {
			switch (+d) {
				case 1:
					names.push('Monday');
					break;
				case 2:
					names.push('Tuesday');
					break;
				case 3:
					names.push('Wednesday');
					break;
				case 4:
					names.push('Thursday');
					break;
				case 5:
					names.push('Friday');
					break;
				case 6:
					names.push('Saturday');
					break;
				case 0:
					names.push('Sunday');
					break;
			}
		});
		return names.join(', ');
	}
}
