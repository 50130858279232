import { AlarmRAGState } from "app/setpoints/setpoint-detail/setpoint-detail.component";
import { Setpoint } from "./setpoint";
import { SetpointTemplateCategory } from "./setpoint-template-category";
import { User } from "./user";
export class SetpointTemplate {
	id: number;
	title: string;
	notes: string;
	category: SetpointTemplateCategory = new SetpointTemplateCategory();
	createdBy: User;

	assetTypeIds: number[];
	siteIds: number[];

	data: any;
	setpoints: Setpoint[] = [];
	alarms: AlarmRAGState = new AlarmRAGState();

	constructor(data?: any) {
		if (!data) {
			this.clearSetpoints();

			return;
		}
		this.id = data.id;
		this.title = data.title;
		this.notes = data.notes;

		this.category = new SetpointTemplateCategory(data.category);
		const setpointData = data.setpoints;
		if (setpointData) {
			this.setpoints = setpointData.map(s => new Setpoint(s.id, s.weekday, s.startsAt, s.endsAt, s.allday, null, null, s.isActive, s.amber_min, s.amber_max, s.red_min, s.red_max));
		} else {
			this.clearSetpoints();
		}

		if (data.assetTypeIds) {
			this.assetTypeIds = data.assetTypeIds;
		}

		if (data.siteIds) {
			this.siteIds = data.siteIds;
		}

		if (data.alarms) {
			this.alarms = new AlarmRAGState(data.alarms);
		}

		this.createdBy = new User(data.createdBy);
	}

	clearSetpoints() {
		this.setpoints = Array(7);
		for (let index = 0; index < this.setpoints.length; index++) {
			this.setpoints[index] = new Setpoint(null, index, null, null, false, null, null, false);
		}
	}

	serialise() {
		return {
			id: this.id,
			title: this.title,
			notes: this.notes,
			assetTypeIds: this.assetTypeIds,
			siteIds: this.siteIds,
			category: { id: this.category.id },
			setpoints: this.setpoints.map(setpoint => setpoint.serialise()),
			alarms: this.alarms
		};
	}
}
