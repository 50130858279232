<button
  class="button-{{ _size() }} "
  [ngClass]="{
    selected: _selected(),
    'not-selected': !_selected(),
    muted: _isMuted(),
    'is-disabled': _isDisabled(),
    'has-right-button': rightAction(),
    'no-right-border': !hasRightBorder(),
    'no-left-border': !hasLeftBorder(),
    'is-danger': isDanger(),
    'is-cta': isCTA(),
    green: colour() === 'green',
    orange: colour() === 'amber' || colour() === 'orange',
    red: colour() === 'red'
  }"
  [ngStyle]="{ margin: margin() }">
  @if (_label()) {
    <span>
      @if (_icon() && iconPos() === 'left') {
        <i class="{{ _icon() }}"></i>
      }
      {{ _label() }}

      @if (_icon() && iconPos() === 'right') {
        <i class="{{ _icon() }}"></i>
      }
    </span>
  }
  <ng-content></ng-content>
</button>
@switch (rightAction()) {
  @case ('configure') {
    <app-button icon="mdi mdi-magnify" size="small" label=" " margin="1px 1px 1px 0" [hasLeftBorder]="false" (click)="actionClick($event)" />
  }
}
