import { Component, Input, OnInit } from '@angular/core';
import { Schematic } from 'app/classes/schematic/schematic';

@Component({
  selector: 'app-schematics-studio',
  templateUrl: './schematics-studio.component.html',
  styleUrls: ['./schematics-studio.component.css']
})
export class SchematicsStudioComponent implements OnInit {
  @Input()
  schematic: Schematic

  constructor() { }

  ngOnInit(): void {
  }

}
