<article>
	<main>
		<section>

			<table class="table">
				<thead>
					<tr>
						<th>Site</th>
						<th>Gateway</th>
						<th>Asset</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let setpoint of setpoints">
						<td>{{setpoint.siteTitle}}</td>
						<td>{{setpoint.gateway.title}}</td>
						<td>{{setpoint.title}}</td>
					</tr>
				</tbody>

			</table>
		</section>
	</main>
	<footer>
		<section>
			<p-button *ngIf="action === 'setpoints-delete'" label="Schedule delete Setpoints" icon="mdi mdi-trash-can" (click)="deleteSetpoints()" styleClass="p-button-danger"></p-button>
			<p-button *ngIf="action === 'setpoints-disable'" label="Schedule disable setpoints" icon="mdi mdi-bell-off" (click)="disableSetpoints()" styleClass="p-button-warning"></p-button>
		</section>
		<section class="mt-1">
			<p *ngIf="action === 'setpoints-delete'">Setpoints will be queued for deletion, associated rules and alarm history will also be removed.</p>
			<p *ngIf="action === 'setpoints-disable'">Setpoints will be queued to be disabled, associated rules and alarm history will be removed.</p>
		</section>
	</footer>
</article>
