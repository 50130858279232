@if(site) {

<header [class.details]="!defaults.hideSiteDetails">
  <section>
    <div>
      <div class=""> {{ site.address.address1 }} </div>
      <div class=""> {{ site.address.addressTown }} </div>
      <div class=""> {{ site.address.addressCounty }} </div>
      <div class=""> {{ site.address.addressPostcode }} </div>
      <div class="" *ngIf="site.regionTitle"><span tooltip="Region"> {{ site.regionTitle }}</span> </div>
      <div class="mt-1">
        @if(site.metobsid) {
        <app-weather-temp [site]="site" (click)="showWeatherOnMap()"></app-weather-temp>
        }
      </div>
      <div class="mt-1" tooltip="Date site online">Online: {{site.createdAt | date:'dd MMM YYYY' }}</div>
    </div>
  </section>
  <section>
    <div class="map">
      @if(mapSettings) {
      <app-map [center]="mapSettings.center" [markers]="mapSettings.markers" [zoom]="10"></app-map>
      }
    </div>
  </section>
</header>

<section *ngIf="view==='gateways'">
  @if(isAdmin) {
  <app-toolbar>
    <div class="left">
      <i class="mdi mdi-shield-account-outline" pTooltip="Admin toolbar"></i>
    </div>
    <div class="right">
      <span (click)="showAllGatewaysClick()" class="can-click">
        <i class="mdi" [class.mdi-checkbox-marked-outline]="defaults.showAllGateways" [class.mdi-checkbox-blank-outline]="!defaults.showAllGateways"></i>
        Include gateways not deployed
      </span>
    </div>
  </app-toolbar>
  }
  <main>
    <table class="table table-sm table-hover gateways">
      <thead>
        <tr>

          <th>Gateway</th>
          <ng-template [ngIf]="defaults.showAllGateways">
            <th>Status</th>
            <th>Created</th>
          </ng-template>
          <th class="isDesktop">
            Seen
          </th>
          <th class="text-right">
            <span pTooltip="Sensor count for this gateway" tooltipPosition="left"># Assets</span>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-template ngFor let-gateway [ngForOf]="gateways">
          <tr (click)="gatewayClick(gateway)" class="status-{{gateway.statusId}}" [ngClass]="{'red' : gateway.noDataFor24Hours}">
            <td pTooltip="{{gateway.id}}">
              @if(_selectMode()) {
              <app-chip [checked]="gateway.checked" (click)="select(gateway, $event)"></app-chip>
              }
              {{ gateway.title }}
            </td>
            <ng-template [ngIf]="defaults.showAllGateways">
              <td>{{ gateway.statusTitle}} </td>
              <td>{{ gateway.createdAt | date:'dd-MM-YY'}}</td>
            </ng-template>
            <td class="isDesktop">{{gateway.seenAt| date:'dd-MM-YY'}}</td>
            <td class="text-right">{{ gateway.assetCount }}</td>
            <td class="text-right"></td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </main>
  <footer>
    <div *ngIf="site.weather" class="weather">
      <h3><img [src]="site.weather.iconUrl"> {{site.weather.description}}</h3>
      <div>
        <div *ngIf="site.weather.snow"><label>snow</label> {{site.weather.snow}}mm</div>
        <div *ngIf="site.weather.rain"><label>rain</label> {{site.weather.rain}}mm</div>

        <div><label>temperature</label> {{site.weather.temp}}c</div>
        <div><label>pressure</label> {{site.weather.pressure}}</div>
        <div><label>humidity</label> {{site.weather.humidity}}</div>
        <div><label>feels like</label> {{site.weather.feelsLike}}c</div>
        <div><label>clouds</label> {{site.weather.clouds}}%</div>
        <div pTooltip="Visibility in meters"><label>visibility</label> {{site.weather.visibility}}</div>
        <div>
          <label>wind</label> {{site.weather.wind.speed}}<small>m/s</small>
          <span pTooltip=" Wind direction, degrees (meteorological)"> {{site.weather.wind.deg}}<small>deg</small>
          </span>
          <span pTooltip="Wind gust, metre/sec," *ngIf="site.weather.wind.gust">
            {{site.weather.wind.gust}}<small>gust</small> </span>
        </div>

        <div class="air">
          <table class="table table-sm">
            <thead>
              <tr>
                <th tooltipPosition="top" pTooltip="Сoncentration of CO (Carbon monoxide), μg/m3">co</th>
                <th tooltipPosition="top" pTooltip="Сoncentration of NO (Nitrogen monoxide), μg/m3">no</th>
                <th tooltipPosition="top" pTooltip="Сoncentration of NO2 (Nitrogen dioxide), μg/m3">no2</th>
                <th tooltipPosition="top" pTooltip="Сoncentration of O3 (Ozone), μg/m3">o3</th>
                <th tooltipPosition="top" pTooltip="Сoncentration of SO2 (Sulphur dioxide), μg/m3">so2</th>
                <th tooltipPosition="top" pTooltip="Сoncentration of PM2.5 (Fine particles matter), μg/m3">pm2.5</th>
                <th tooltipPosition="top" pTooltip="Сoncentration of PM10 (Coarse particulate matter), μg/m3">pm10</th>
                <th tooltipPosition="top" pTooltip="Сoncentration of NH3 (Ammonia), μg/m3">nh3</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{site.weather.air.co}}</td>
                <td>{{site.weather.air.no}}</td>
                <td>{{site.weather.air.no2}}</td>
                <td>{{site.weather.air.o3}}</td>
                <td>{{site.weather.air.so2}}</td>
                <td>{{site.weather.air.pm2_5}}</td>
                <td>{{site.weather.air.pm10}}</td>
                <td>{{site.weather.air.nh3}}</td>
              </tr>
            </tbody>
          </table>
          <div class="text-center aq-description aq-{{site.weather.air.aqi}}">Air quality is
            {{site.weather.airQualityText}}
          </div>
        </div>

        <div class="footnote">
          Data from {{site.weather.title}}, {{site.weather.pulledAt | amDateFormat:'DD MMM YYYY HH:mm'}}
        </div>
      </div>
    </div>
  </footer>

</section>

<section *ngIf="view==='assets'">
  <h3 class="can-click" (click)="view='gateways'"><i class="fa fa-fw fa-long-arrow-left"></i> Assets</h3>
  <table class="table table-sm  gateways">
    <thead>
      <tr>
        <th *ngIf="showId">id</th>
        <th>Asset</th>
        <th>Value</th>
      </tr>
    </thead>
    <tbody>
      <ng-template ngFor let-asset [ngForOf]="gateway.assets.list">
        <tr (click)="assetCick(asset)">
          <td>{{ asset.title }}</td>
          <td>{{ asset.value}}</td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</section>
}
