<article>
	<section>

		<div></div>
	</section>

	<section class="pt-1">
		<app-info-panel icon="admin">
			<div>
				<button pButton type="button" (click)="fix(asset.id)" icon="mdi mdi-database-refresh-outline"
					class="p-button-outlined p-button-sm mr-1" label="Rebuild this asset"></button>
			</div>
			<div class="pt-1" *ngIf="response">
				{{response | json}}
			</div>
		</app-info-panel>
	</section>

</article>
