import { AlarmTimeline } from './alarm-timeline';
import { RulePackage } from "./rule-service/rule-package";
import { RuleCondition } from "./rule-service/rule-condition";
import { RuleNotification } from "./rule-service/rule-notification";
import { RuleTimeRange } from "./rule-service/rule-hours";
import { ElogbooksReactiveJob } from './elogbooks-reactive-job';

export class Alarm {
    id: number;
    rulePackage_id: number;
    ticksInAlarm: number;
    createdAt: string;
    updatedAt: string;
    state: string;
    rulePackageTitle: string;
    rulePackageSeverity: string;
    rulePackageInstructions: string;
    user_id: number;
    timeline: AlarmTimeline[];
    rulePackage: RulePackage;
    findings: { at: string, text: string, state: string };
    history: any[];
    jobs: ElogbooksReactiveJob[];

    constructor(data?: any) {

        if (data) {
            let master;
            let rulePackage: RulePackage;
            let contacts: RuleNotification[] = [];
            let timeRanges: RuleTimeRange[] = [];
            let conditions: RuleCondition[] = [];
            this.timeline = [];
            if (!data[0]) {
                master = data;
            } else {
                master = data[0][0];
                this.timeline = data[1].map(t => new AlarmTimeline(t));
                rulePackage = data[2][0];
                contacts = data[3] as RuleNotification[];
                timeRanges = data[4] as RuleTimeRange[];
                conditions = data[5] as RuleCondition[];
            }
            this.id = master.id;
            this.rulePackage_id = master.rulePakage_id;
            this.createdAt = master.createdAt;
            this.updatedAt = data.updatedAt || master.updatedAt;
            this.state = master.state;
            this.user_id = master.user_id;
            if (this.rulePackage_id) {
                this.rulePackage = new RulePackage(conditions, contacts);
                this.rulePackage.ruleTimeRanges = timeRanges;
                this.rulePackage.id = rulePackage.id;
                this.rulePackage.title = rulePackage.title;
                this.rulePackage.severity = rulePackage.severity;
                this.rulePackage.instructions = rulePackage.instructions;
                this.rulePackage.autoResolve = rulePackage.autoResolve;
                this.rulePackage.resetValueOnAlert = rulePackage.resetValueOnAlert;
            }

            this.timeline = this.timeline.sort(function (a, b) {
                return (+new Date(b.createdAt) - +new Date(a.createdAt));
            });;
        }
    }

    /**
     * Add an elogbook job(s), only expect 1, so coded internaly for 1.
     * - allow multiple in the interface for possible future changes
     * @param jobs 
     */
    addElogbooksJobs(jobs: any[]) {
        const job = new ElogbooksReactiveJob(jobs[0]);
        if (this.timeline?.length) {
            const timelineItem = this.timeline.find(t => t.action === 'elogbook_job' && +t.value === job.id);
            if (timelineItem) {
                timelineItem.job = job;
            }
        }
        this.jobs = [job];
    }
}
