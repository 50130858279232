/**
 * An asset preset - a location linked to an asset
 * - on cameras these are locations to move to.
 */
export class AssetPreset {
  id: number;
  title: string;
  presetId: string;
  shotNight: string;
  shotDay: string;
  details: string;
  ref: string;
  recentBucketKey: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.title = data.title
      this.shotNight = data.shotNight;
      this.shotDay = data.shotDay;
      this.ref = data.ref;
      this.recentBucketKey = data.recentBucketKey || data.presetRecentBucketKey;
      this.presetId = data.presetId;
    }
  }
}