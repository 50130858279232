import { ReportBlock } from './report-block';

export class Report {
	id: number;
	title: string;
	org_id: number;
	blocks: ReportBlock[];
	icon: string;
	forAsset: string;
	forGateway: string;
	// Optional asset type to restrict
	forAssetType: number;
	forGatewayType: number;
	startAt: Date;
	updatedAt: string;
	createdAt: Date = new Date();
	dateSpan: 'month' | 'week' = 'week';
	details: string[] = [];


	constructor(report?: Report) {
		this.blocks = [];
		if (report) {
			this.id = report.id;
			this.title = report.title
			this.org_id = report.org_id;
			if (report.blocks) {
				this.blocks = report.blocks.map(block => {
					const rb = new ReportBlock();
					rb.id = block.id;
					rb.fragment_id = block.fragment_id;
					rb.options = block.options;
					rb.pagebreak = block.pagebreak;
					rb.title = block.title;
					return rb;
				});
			} else {
				this.blocks = [];
			}
			this.icon = report.icon;
			this.forGateway = report.forGateway;
			this.forAsset = report.forAsset;
			this.forGatewayType = report.forGatewayType;
			this.forAssetType = report.forAssetType;
			this.startAt = report.startAt;

			switch (report.title) {
				case 'Profiling Wellbeing (setpoints)':
					this.details = ['Operational Wellbeing Set Points by Day'];
					break;
				case 'Month Average, Min, Max for Setpoints':
					this.details = ['Min/Max/Avg by Day'];
					break;
				case 'Infrastructure Health':
					this.details = ['Min/Max by Day', 'Average Telemetry by Day', 'Telemetry', 'Disconnects', '24 Hour Min/Max/Average'];
					break;
				case 'Footfall weekly':
					this.details = ['Footfall - Entrance', 'Weather', 'Count by Day'];
					break;
				case 'Performance Statistics (setpoints)':
					this.details = ['Operational set points by Day'];
					break;
				case 'Month Average, Min, Max for RAG':
					this.details = ['Min/Max/Avg by Day'];
					break;
				case 'Air Quality':
					this.details = ['Temperature', 'Humidity', 'CO2', 'VOC', 'Pressure', 'PM2.5'];
					break;
				default:
					break;
			}
		}
	}
}
