import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.css']
})
export class PageTitleComponent implements OnInit {

  @Input()
  links: ILink[];

  @Input()
  label: string;

  @Output()
  onLinkClick: EventEmitter<ILink> = new EventEmitter();

  @Input()
  canGoBack: boolean;

  @Input()
  size = 'large';

  constructor() { }

  ngOnInit(): void {
  }
}

export interface ILink {
  label: string;
  icon: string; // mdi-{{icon}}
  value: string | number;
  type: 'button' | 'button-sm' | undefined;
}
