import { Component, Input } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { Telemetry } from 'app/classes/telemetry';
import { PowerService } from "app/shared/power.service";
@Component({
  selector: 'app-sq-power',
  templateUrl: './sq-power.component.html',
  styleUrls: ['./sq-power.component.css']
})
export class SqPowerComponent {

  @Input()
  asset: Asset;

  @Input()
  packets: Telemetry[];

  response: any;

  constructor(private powerService: PowerService) {

  }

  async fix(assetId: number) {
    this.response = '';
    const response = await this.powerService.fixAsset(assetId);

    this.response = response;
  }

}
