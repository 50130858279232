<article>
    <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input type="text" pInputText placeholder="Search" [(ngModel)]="searchText" (keyup)="searchChanged()" pTooltip="{{tooltipText}}" [showDelay]="2000" tooltipPosition="bottom" />
        @if(resultCount() === 0) {
        <div class="no-results">
            <app-chip [isDanger]="true" [canClick]="false" label=" No results" />
        </div>
        }
    </span>
</article>
