import { Component, OnInit, Input, Output, EventEmitter, input, signal } from '@angular/core';
import { Asset } from '../../classes/asset';
import { APIService } from '../../shared/api.service';
import { License } from 'app/classes/license';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { DialogAssetEditComponent } from 'app/dialogs/dialog-asset-edit/dialog-asset-edit.component';
import { AssetService } from 'app/shared/asset.service';
import { RedisService } from 'app/shared/redis.service';

@Component({
  selector: 'app-asset-viewer',
  templateUrl: './asset-viewer.component.html',
  styleUrls: ['./asset-viewer.component.css'],
  providers: [DialogService]
})
export class AssetViewerComponent implements OnInit {

  asset = signal<Asset>(null);

  assetId = input.required<number>();

  @Output()
  hasLoaded: EventEmitter<void> = new EventEmitter();

  @Input()
  wantsDetails: boolean = true;

  wantsChart = input<boolean>(true);
  wantsExtendedDetails = input<boolean>(false);

  canEdit: boolean;
  isAdmin: boolean;
  fullscreen = false;

  constructor(private assetService: AssetService, private apiService: APIService, public dialogService: DialogService, public messageService: MessageService, private redisService: RedisService) { }

  ngOnInit(): void {
    this.canEdit = this.apiService.hasOrgRole('amend_asset') || this.apiService.isAdmin();
    this.isAdmin = this.apiService.isAdmin();
    if (this.assetId) {
      this.assetService.getAsset(this.assetId())
        .then(asset => {
          this.redisService.getAsset(this.assetId()).then(redisAsset => {
            asset.redis = redisAsset;
            this.asset.set(asset);
            this.hasLoaded.emit();
          });
        });
    }
  }

  toolbarClick(event: any) {
    const dialogRef = this.dialogService.open(DialogAssetEditComponent, {
      header: `Update ${this.asset().title}`,
      data: { asset: this.asset }
    });
    dialogRef.onClose.subscribe((license: License) => {
      if (license) {
        this.messageService.add({ severity: 'info', summary: 'Asset updated', detail: '' });
      }
    });
  }

}
