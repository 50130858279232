import { Component, EventEmitter, Input, OnDestroy, Output, signal } from '@angular/core';
import { AssetType } from 'app/classes/asset';
import { AssetService } from 'app/shared/asset.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-button-sensors',
  templateUrl: './button-sensors.component.html',
  styleUrl: './button-sensors.component.css'
})
export class ButtonSensorsComponent implements OnDestroy {
  _onDialog = signal<boolean>(false);
  label = signal<string>('All Sensor types');
  _selectedItems = signal<number[]>([]);
  _caption = signal<string>(null);
  _maxSensorsToShow = signal<number>(10);

  @Input()
  public set caption(v: string) {
    this._caption.set(v);
  }

  @Input()
  public set selectedItems(v: number[]) {
    this._selectedItems.set(v || []);
    this.changed();
  }
  public get selectedItems(): number[] {
    return this._selectedItems();
  }

  @Input()
  closeReturnsNull = signal<boolean>(false);

  // Set this value to keep the button size from appearing too long
  @Input()
  public set maxSensorsToShow(v: number) {
    this._maxSensorsToShow.set(v)
  }

  @Output()
  onSelect = new EventEmitter<number[]>();

  @Input()
  size = signal<string>('medium');

  assetTypes = signal<AssetType[]>([]);
  sub: Subscription;
  assetType = signal<AssetType>(null);

  constructor(private assetService: AssetService) {
    this.sub = assetService.assetTypes.subscribe(types => {
      this.assetTypes.set(types.filter(t => !t.isHidden));
      this.changed();
    });
  }

  rowMouseEnter(type: AssetType) {
    this.assetType.set(type);
  }

  changed() {
    if (!this.assetTypes().length || !this._selectedItems().length) {
      return;
    }
    this._selectedItems().forEach(id => this.assetTypes().find(atype => atype.id === id).checked = true);

    this.buildLabel();
  }

  selectClear() {
    this.assetTypes.update(types => { types.forEach(type => type.checked = false); return types });
    this._selectedItems.set([]);
    this.changed();
  }

  select(sensor: AssetType, event: any) {
    sensor.checked = !sensor.checked;
  }

  selectSave() {
    this.onSelect.emit(this.assetTypes().filter(t => t.checked).map(t => t.id));
    this._onDialog.set(false);
    this.buildLabel();
  }

  closed(event: any) {
    if (this.closeReturnsNull()) {
      this.onSelect.emit(null);
      this._onDialog.set(false);
      this.buildLabel();
    } else {
      this.selectSave();
    }
  }

  buildLabel() {
    const checked = this.assetTypes().filter(t => t.checked).length;
    if (checked) {
      if (checked > this._maxSensorsToShow()) {
        this.label.set(`${checked} sensor${checked > 1 ? 's' : ''}`);
      } else {
        this.label.set(this.assetTypes().filter(t => t.checked).map(t => t.title).join(', '));
      }
    } else {
      this.label.set('All Sensors');
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
