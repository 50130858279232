export class WeatherItem {
	clouds: number;
	data: string;
	description: string;
	// dewPoint: number;
	feelsLike: number;
	forDate: Date;
	forHour: number;
	humidity: number;
	icon: string;
	metobsid: number;
	pressure: number;
	pulledAt: Date;
	rain: number;
	snow: number;
	temp: number;
	// uvi: number;
	visibility: number;

	wind: {
		speed: number,
		deg: number,
		gust: number
	};

	iconUrl: string;
	lat: number;
	lng: number;
	title: string;
	air: {
		co: number,
		no: number,
		no2: number,
		o3: number,
		so2: number,
		pm2_5: number,
		pm10: number,
		nh3: number,
		aqi: number
	};

	airQualityText: string;

	constructor(data: any) {
		this.clouds = data.clouds;
		this.data = data.data;
		this.description = data.description;
		//this.dewPoint = data.dew_point;

		this.feelsLike = data.feels_like;
		this.humidity = data.humidity;
		this.metobsid = data.metobsid;
		this.forDate = new Date(data.forDate);
		this.forHour = data.forHour;
		this.icon = data.icon;
		this.pressure = data.pressure;
		this.pulledAt = new Date(data.pulledAt);
		this.temp = data.temp;
		//this.uvi = data.uvi;
		this.visibility = data.visibility;
		this.wind = {
			speed: data.wind_speed,
			deg: data.wind_deg,
			gust: data.wind_gust
		};
		this.rain = data.rain;
		this.snow = data.snow;
		this.title = data.mTitle;

		this.air = {
			aqi: data.aqi,
			co: data.co,
			no: data.no,
			no2: data.no2,
			o3: data.o3,
			so2: data.so2,
			pm2_5: data.pm2_5,
			pm10: data.pm10,
			nh3: data.nh3
		};

		this.airQualityText = ['No Data', 'Good', 'Fair', 'Moderate', 'Poor', 'Very Poor'][data.aqi || 0];

		switch (this.icon) {
			case 'cloudy-day':
				break;
			default:
				break;
		}

		this.iconUrl = `https://s3.eu-west-2.amazonaws.com/4d-icons/weather/v2/${this.icon}.png`;
		this.lat = data.lat;
		this.lng = data.lng;
	}


}
