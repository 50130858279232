import { Survey } from "./survey";
export class OrderSurveyItem {
    id: number;
    order_id: number;
    survey_id: number;
    qty: number;
    constructor(data?: any) {
        if (data) {
            this.id = data.id;
            this.order_id = data.order_id;
            this.survey_id = data.survey_id;
            this.qty = data.qty;
        }
    }
}
