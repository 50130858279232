@defer() {
<table class="table table-sm" [ngClass]="{'has-weather': hasWeather()}">
	<thead>
		<tr>
			<th> Date </th>
			<th> Time </th>
			<th> RAG </th>
			<th class="text-right"> Value </th>
			@if (hasWeather()) {
			<th class="text-right"> Temp </th>
			<th class="text-right"> Wind </th>
			<th class="text-right" pTooltip="humidity"> Hum. </th>
			}
		</tr>
	</thead>
	<tbody>
		@for (row of _packets(); track row.i){
		<tr [class.in-scope]="row.inScope">
			<td> {{row.d | amDateFormat: 'dd DD/MM/yyyy'}} </td>
			<td> {{row.d | amDateFormat: 'HH:mm'}} </td>
			<td class="{{row.rag}}">
				<span *ngIf="_hasActiveSetpoints()"> {{row.rag}} </span>
				<span *ngIf="!_hasActiveSetpoints()" pTooltip="No active setpoints">---</span>
			</td>
			<td *ngIf="!asset" class="text-right"> {{row.v | number:'0.2-2'}} </td>
			<td *ngIf="asset" class="text-right" [title]="row.v"> {{formattedValue(row.v)}} </td>
			@if (hasWeather()) {
			<td class="text-right">
				<app-weather-temp [weather]="{icon:row.w?.i, temp:row.w?.t}"></app-weather-temp>
			</td>
			<td class="text-right"> {{row.w?.w| number:'0.0'}} </td>
			<td class="text-right"> {{(row.w?.h > 1000 ? row.w.h/100 : row.w?.h) | number:'0.0'}}% </td>
			}
		</tr>
		}
	</tbody>
</table>
} @placeholder (minimum 200ms) {
<i class="fa fa-refresh fa-spin"></i>
}
