const DEFAULT_TRIGGER_TYPE = 'hour';
const DEFAULT_ACTION_TASK = null;

export class Workplan {
    actions: WorkplanAction[] = [];
    triggers: WorkplanTrigger[] = [];

    addAction() {
        const emptyCount = this.actions
            .filter(action => (!action.task))
            .length;

        if (emptyCount) {
            return;
        }
        this.actions.push(new WorkplanAction({ type: DEFAULT_ACTION_TASK }));
    }

    addTrigger() {
        const emptyCount = this.triggers.filter(trigger => !trigger.value).length;

        if (emptyCount) {
            return;
        }
        this.triggers.push(new WorkplanTrigger({ type: DEFAULT_TRIGGER_TYPE }));
    }

    deleteTrigger(index: number) {
        this.triggers.splice(index, 1);
    }

    deleteAction(index: number) {
        this.actions.splice(index, 1);
    }
}

export class WorkplanAction {
    task: string;
    value: string;
    constructor(data?: any) {
        if (!data) {
            return;
        }

        this.task = data.task;
        this.value = data.value;
    }
}

export class WorkplanTrigger {
    type: string;
    value: string;

    constructor(data?: any) {
        if (!data) {
            return;
        }

        this.type = data.type;
        this.value = data.value;
    }
}

export const WorkPlanTaskOptions = { time: 'At the time' };
export const WorkPlanActions = [
    { title: 'Pan to preset', value: 'camera:pan:preset' },
    { title: 'Take picture', value: 'camera:image:upload' },
    { title: 'Wait (seconds)', value: 'wait' },
]
