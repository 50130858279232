import { Asset } from '../asset';

export class RuleAction {
    id: number;
    method = 'set';
    trigger: string;
    asset: Asset;
    value: string;

    constructor(data?: any) {
        if (data) {
            this.id = data.id;
            this.method = data.method;
            this.value = data.value;
            this.trigger = data.trigger;
            this.asset = new Asset(
                {
                    id: data.asset_id,
                    title: data.assetTitle,
                    gateway_id: data.gatewayId,
                    gatewayTitle: data.gatewayTitle,
                    siteId: data.siteId,
                    siteTitle: data.siteTitle
                }
            );
        } else {
            this.asset = new Asset();
        }
    }
}
