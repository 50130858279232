<div [class.caption]="_caption()">
	@if(_caption()) {
	<label>{{_caption()}}</label>
	}
	<app-button [size]="size" [label]="label()" (click)="_onDialog.set(true)"></app-button>
</div>

@if(_onDialog() ) {
<app-dialog [canScroll]="false" (onClose)="closed($event)" [dimensions]="{width:680, height:300}">
	<app-toolbar>
		<div class="left">
			<app-button [selected]="_config().dateRange === 'today'" label="Today" (click)="dateButtonClick('today')"></app-button>
			<app-button [selected]="_config().dateRange === 'thisweek'" label="This Week" (click)="dateButtonClick('thisweek')"></app-button>
			<app-button [selected]="_config().dateRange === 'lastweek'" label="Last Week" (click)="dateButtonClick('lastweek')"></app-button>
			<app-button [selected]="_config().dateRange === 'thismonth'" label="This month" (click)="dateButtonClick('thismonth')"></app-button>
			<app-button [selected]="_config().dateRange === 'lastmonth'" label="Last month" (click)="dateButtonClick('lastmonth')"></app-button>
			<app-button [selected]="_config().dateRange==='custom'" label=" Custom" (click)="dateButtonClick('custom')"></app-button>
		</div>
	</app-toolbar>

	<section class="dates">
		@if(_config().dates) {
		<app-calendar [value]="_config().dates.from" (onSelect)="changeDate('from', $event)"></app-calendar>
		<app-calendar [value]="_config().dates.to" (onSelect)="changeDate('to', $event)"></app-calendar>
		}
	</section>
</app-dialog>
}
