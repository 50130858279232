import { Ticket } from "./ticket";
import { Gateway } from "./gateway";
export class GatewayTicket extends Ticket {

    gateway: Gateway;
    override isGatewayTicket: boolean = true;

    constructor(data?: any) {
        super(data);

        let gateway = new Gateway();
        gateway.id = data.gatewayId;
        gateway.title = data.gatewayTitle;

        this.gateway = gateway;
    }

    override serialise() {
        let serialise = super.serialise();
        serialise.gatewayId = this.gateway.id;

        return serialise;
    }

    display() {
        return this.gateway.title + ' gateway at ' + this.site.title;
    }

}
