import { ProfileAsset } from "./profile-asset";
export class Profile {
	id: number;
	title: string;
	gatewayId: string;
	svg: string;
	assets: ProfileAsset[];
	visible: boolean = true;
	viewbox: any = {left:0, top:0, height:0, width: 0};
	circleRadius: number = 20;

	constructor(data?: any) {

		if (data.length) {
			let details = data[0][0];
			let assets = data[1];
			this.id = details.id;
			this.title = details.title;
			this.gatewayId =  details.gateway_id;
			this.svg =  details.svg;
			this.assets = [];
			this.viewbox.height = details.viewboxHeight;
			this.viewbox.width = details.viewboxWidth;
			assets.forEach(asset => this.assets.push(new ProfileAsset(asset)));
			if (this.viewbox.width <= 200) {
				// Reduce the size of the overlay for small plans
				this.circleRadius = 8;
			}
		}
	}
}
