import { Component, Input, OnInit, signal, ChangeDetectorRef } from '@angular/core';
import { Annotation } from '../../classes/annotation';
import { Packet } from '../../classes/packet';
import { SiteFloorplanAsset } from '../../classes/site-floorplan-asset';
import { APIService } from '../../shared/api.service';
import { AssetService, IGetQueuedTelemetryStatus } from '../../shared/asset.service';
import { ExportService } from '../../shared/export.service';
import { SetpointsService } from '../../shared/setpoints.service';
import { Asset } from '../../classes/asset';
import { ChartPin } from "../../classes/chart-pin";
import { D3ChartService, ITelemetryWeather } from "../../shared/d3chart.service";
import { WindowService } from "../../shared/window.service";
import { IOnDateSelect } from './setpoint-query-header/setpoint-query-header.component';
import moment from 'moment';

@Component({
  selector: 'app-setpoint-query',
  templateUrl: './setpoint-query.component.html',
  styleUrls: ['./setpoint-query.component.css']
})
export class SetpointQueryComponent implements OnInit {

  static readonly DAYS_TO_USE_ATHENA = 120;
  isLoading = signal<boolean>(true);
  isWorking = signal<boolean>(false);
  _showHeader = signal<boolean>(true);
  _showValue = signal<boolean>(false);

  tab = signal<string>('chart');

  _asset: Asset;
  @Input()
  public get asset(): Asset {
    return this._asset;
  }

  public set asset(v: Asset) {
    console.log(`asset for query`, v);
    this._asset = v;

    if (v?.assetTypeTitle) {
      this.legendTitles = { x: v.assetTypeTitle };
    }
    this.isLoading.set(false);
  }

  /* limits to draw on the graph */
  @Input()
  limitLines: LimitLine[];

  assetContainers = signal<AssetContainer[]>([]);

  @Input()
  public set assets(v: Asset[]) {
    setTimeout(() => {
      console.log(`assets for query`, v);
      if (v) {
        this.assetContainers.set(<AssetContainer[]>v.map(asset => {
          return { asset, packets: [], setpoints: [] };
        }));
        this.getSetpointsForAssets();
      } else {
        this._asset = null;
      }
      this.changeDetectorRef.detectChanges(); // Manually trigger change detection
    }, 200);

  }

  @Input()
  saveState = true;

  @Input()
  fullscreen: boolean;

  @Input()
  view: 'compact' | 'full' | 'chart-only' = 'full'

  @Input()
  public set showValue(v: boolean) {
    this._showValue.set(v);
  }

  public get showValue(): boolean {
    return this._showValue();
  }

  @Input()
  dateType: DateType = 'today';

  // _custom: SetpointQueryDateRange;

  @Input()
  public set custom(v: SetpointQueryDateRange) {
    console.log('SET custom', v);
    this.setpointQueryDateRange = v;
  }

  public get custom(): SetpointQueryDateRange {
    return this.setpointQueryDateRange;
  }

  @Input()
  alwaysUseCustom: boolean;

  @Input()
  fromDate: Date;

  @Input()
  toDate: Date;

  @Input()
  pins: ChartPin[];

  @Input()
  public set showHeader(v: boolean) {
    this._showHeader.set(v);
  }

  public get showHeader(): boolean {
    return this._showHeader();
  }

  @Input()
  showLegend = true;

  @Input()
  canAnnotate = true;

  @Input()
  showXLegend = true;

  @Input()
  xLegendTicks: number = null;

  uuid: string;
  setpoints: any[];
  master = { notes: '', delay: 0 };
  packets = signal<Packet[]>(null);
  weather: ITelemetryWeather[];

  showHeaderDetails = signal<boolean>(true);
  hasActiveSetpoints = signal<boolean>(false);
  lastx = 7;

  isSelectingCustomDates: boolean;
  isshowingHeaderDetails: boolean;

  df: Date;
  dt: Date;

  chartPresetDate: SetpointQueryDateRange = new SetpointQueryDateRange({ from: null, to: null, type: 'today' });
  tabIndex: number;
  annotations: any[];
  chartHeight = 400;
  chartWidth: number = null;
  // Left value text size
  textFontSize = 12;
  isMobile: boolean;
  isWaitingForData: boolean;
  // Set if the user wants the big data dialog to stay open with options.
  isWaitingToConfirm: boolean;
  // Contains the return data when athena query has run
  athenaStatus: any;
  // For blocking UI display a UI instead of nothing.
  isGeneratingBlockingGraph = signal<boolean>(false);

  now = new Date();

  enabled: { [key: string]: boolean } = {
    today: true,
    yesterday: true,
    thisweek: true,
    lastweek: true,
    lastx: true
  };

  isAdmin: boolean;
  setpointQueryDateRange: SetpointQueryDateRange;

  hasData = signal<boolean>(null);

  legendTitles: { x: string, y?: string, x2?: string } = null;

  constructor(

    private setpointService: SetpointsService, private assetService: AssetService, private apiService: APIService, private exportService: ExportService, private d3ChartService: D3ChartService, private windowService: WindowService, private changeDetectorRef: ChangeDetectorRef) {

    this.tabIndex = +(localStorage.getItem('setpoint:query:tab') || 0);
    this.uuid = apiService.generateUUID();
    console.log(`SETPOINT_QUERY_${this.uuid} `);
  }

  ngOnInit(): void {
    this.isMobile = this.windowService.isMobile();
    this.isAdmin = this.apiService.isAdmin();

    if (this.isMobile) {
      this.view = 'compact';
      this.showHeader = false;
      this.chartWidth = 370;
      this.showLegend = true;
      this.canAnnotate = false;
      this.showXLegend = false;
      this.xLegendTicks = 5;
    }
    if (this.view === 'compact') {
      this.chartHeight = 200;
    }
  }

  async getForAssetContainer(assetContainer?: AssetContainer) {
    let df;
    let dt;

    if (!this.setpointQueryDateRange) {
      return;
    }
    let setpointQueryDateRange: SetpointQueryDateRange = new SetpointQueryDateRange({ from: null, to: null, type: this.setpointQueryDateRange.type });

    switch (this.setpointQueryDateRange?.type) {
      case 'today':

        setpointQueryDateRange.from = moment().startOf('day').toDate();
        setpointQueryDateRange.to = moment().toDate();
        break;
      case 'lastx':
        setpointQueryDateRange.from = moment().subtract(7, 'days').startOf('day').toDate();
        setpointQueryDateRange.to = new Date();
        break;
      case 'yesterday':
        setpointQueryDateRange.from = moment().subtract(1, 'days').startOf('day').toDate();
        setpointQueryDateRange.to = moment().subtract(1, 'days').endOf('day').toDate();
        break;
      case 'thisweek':
        setpointQueryDateRange.from = moment().isoWeekday(1).startOf('isoWeek').toDate();
        setpointQueryDateRange.to = moment().endOf('day').toDate();
        break;
      case 'lastweek':
        setpointQueryDateRange.from = moment().subtract(1, 'weeks').isoWeekday(1).startOf('isoWeek').toDate();
        setpointQueryDateRange.to = moment().subtract(1, 'weeks').isoWeekday(1).endOf('isoWeek').toDate();
        break;
      case 'custom':
        df = +this.setpointQueryDateRange.from > +this.setpointQueryDateRange.to ? this.apiService.dbDate(this.setpointQueryDateRange.to) : this.apiService.dbDate(this.setpointQueryDateRange.from);
        dt = +this.setpointQueryDateRange.from < +this.setpointQueryDateRange.to ? this.apiService.dbDate(this.setpointQueryDateRange.to) : this.apiService.dbDate(this.setpointQueryDateRange.from);

        if (!dt || !df) {
          // Must have dates
          return;
        }

        setpointQueryDateRange.from = moment(df).startOf('day').toDate();
        setpointQueryDateRange.to = moment(dt).endOf('day').toDate();

        break;
    }

    // Set the new dates
    this.chartPresetDate = setpointQueryDateRange;

    this.isLoading.set(true);

    if (assetContainer) {
      assetContainer.packets = null;
      this.d3ChartService.getTelemetry(assetContainer.asset.id, this.chartPresetDate.from, this.chartPresetDate.to, assetContainer.setpoints)
        .then(response => {
          assetContainer.packets = response.packets;
          assetContainer.weather = response.weather;
          assetContainer.annotations = response.annotations;
          assetContainer.isReady = true;
          if (typeof assetContainer.asset.value === 'string') {
            if (assetContainer.asset.value.length > 6) {
              this.textFontSize = 9;
            }
          }
        });
    } else {
      for (const ac of this.assetContainers()) {
        ac.isReady = false;
        ac.packets = null;
        await this.d3ChartService.getTelemetry(ac.asset.id, this.chartPresetDate.from, this.chartPresetDate.to, ac.setpoints)
          .then(response => {
            ac.packets = response.packets;
            ac.weather = response.weather;
            ac.annotations = response.annotations;
            ac.isReady = true;
          });
      }
    }
    this.isLoading.set(false);
  }

  async get() {
    this.packets.set(null);
    let df;
    let dt;

    let setpointQueryDateRange: SetpointQueryDateRange = new SetpointQueryDateRange({ from: null, to: null, type: this.setpointQueryDateRange.type });

    const telemetryDates = this.asset.getLastFirstTelemeteyDates();

    switch (this.setpointQueryDateRange?.type) {
      case 'today':
        setpointQueryDateRange.from = moment().startOf('day').toDate();
        setpointQueryDateRange.to = moment().toDate();
        break;
      case 'lastx':
        // If we have no recent data override to show last 7 days 
        if (moment().diff(moment(telemetryDates.to), 'days') > 6) {
          setpointQueryDateRange.from = moment(telemetryDates.to).subtract(7, 'days').toDate();
          setpointQueryDateRange.to = moment(telemetryDates.to).toDate();
        } else {
          setpointQueryDateRange.from = moment().subtract(7, 'days').startOf('day').toDate();
          setpointQueryDateRange.to = new Date();
        }
        break;
      case 'yesterday':
        setpointQueryDateRange.from = moment().subtract(1, 'days').startOf('day').toDate();
        setpointQueryDateRange.to = moment().subtract(1, 'days').endOf('day').toDate();
        break;
      case 'thisweek':
        setpointQueryDateRange.from = moment().startOf('isoWeek').toDate();
        setpointQueryDateRange.to = moment().endOf('day').toDate();
        break;
      case 'lastweek':
        setpointQueryDateRange.from = moment().subtract(1, 'weeks').isoWeekday(1).startOf('isoWeek').toDate();
        setpointQueryDateRange.to = moment().subtract(1, 'weeks').isoWeekday(1).endOf('isoWeek').toDate();
        break;
      case 'custom':
        df = +this.setpointQueryDateRange.from > +this.setpointQueryDateRange.to ? this.apiService.dbDate(this.setpointQueryDateRange.to) : this.apiService.dbDate(this.setpointQueryDateRange.from);
        dt = +this.setpointQueryDateRange.from < +this.setpointQueryDateRange.to ? this.apiService.dbDate(this.setpointQueryDateRange.to) : this.apiService.dbDate(this.setpointQueryDateRange.from);

        if (!dt || !df) {
          // Must have dates
          return;
        }

        setpointQueryDateRange.from = moment(df).startOf('day').toDate();
        setpointQueryDateRange.to = moment(dt).endOf('day').toDate();

        break;
    }

    // Set the new dates
    this.chartPresetDate = setpointQueryDateRange;

    const noOfdaysToRetrieve = Math.abs(moment(this.chartPresetDate.from).diff(moment(this.chartPresetDate.to), 'days')) + 1;


    const rate = this.asset.settings?.find(s => s.setting === 'ingest_rate_day');

    const lowIngestRate = (+rate?.value === 1);

    if (!lowIngestRate && noOfdaysToRetrieve > SetpointQueryComponent.DAYS_TO_USE_ATHENA) {
      const response = await this.assetService.requestQueuedTelemetry(this.asset.id, this.chartPresetDate.from, this.chartPresetDate.to);
      const key = response.data.key;
      let waitLimitCounter = 10;
      let athenaStatus: IGetQueuedTelemetryStatus;

      while (waitLimitCounter-- > 0) {
        athenaStatus = await this.assetService.getQueuedTelemetryStatus(key);
        console.log(this.uuid, waitLimitCounter, athenaStatus);
        switch (athenaStatus.status) {
          case 'working':
            this.isWaitingForData = true;
            await new Promise(r => setTimeout(r, 10000));
            break;
          case 'failed':
            waitLimitCounter = 0;
            break;
          case 'succeeded':
            waitLimitCounter = 0;
            break;
        }
      }
      // Force keeping the dialog open.
      this.isWaitingToConfirm = true;
      this.isWaitingForData = false;
      this.isLoading.set(false);
      this.isWorking.set(false);
      this.athenaStatus = athenaStatus;

      if (athenaStatus.status === 'succeeded' && athenaStatus.athenaKey && !this.isWaitingToConfirm) {
        this.wantsToDownloadGeneratedAthenaData();
      }

      return;
    }

    // Data size small enough to use RDS

    this.d3ChartService.getTelemetry(this.asset.id, this.chartPresetDate.from, this.chartPresetDate.to, this.setpoints, true)
      .then(response => {
        this.packets.set(response.packets);
        this.weather = response.weather;
        this.annotations = response.annotations;
        this.isLoading.set(false);
        this.isWorking.set(false);
        this.hasData.set(!!response.packets?.length);
      });
  }

  async wantsToDownloadGeneratedAthenaData() {
    if (this.athenaStatus.athenaKey) {
      this.isGeneratingBlockingGraph.set(true);

      await new Promise(r => setTimeout(r, 10));

      this.isWaitingToConfirm = false;
      // We have data
      const packets = await this.assetService.downloadDataFromAthenaKey(this.athenaStatus.athenaKey);
      this.packets.set(packets);
      this.weather = [];
      this.annotations = [];
      this.isGeneratingBlockingGraph.set(false);
    }
  }
  /*
    showCustomDatesDialog() {
      const data = {
        from: this.custom?.from || new Date(),
        to: this.custom?.to || new Date(),
        min: this.asset?.createdAt || null,
        max: this.asset?.updatedAt || new Date(),
        asset: this.asset
      }
  
      const dialogRef = this.dialogService.open(DialogDateRangeComponent, {
        data,
        maximizable: true
      });
      dialogRef.onClose.subscribe((dates: any) => {
        if (dates) {
          this.dateType = 'custom';
          this.setpointQueryDateRange = new SetpointQueryDateRange({ to: dates.to, from: dates.from, type: 'custom' });
          localStorage.setItem('setpoint:query:daterange', JSON.stringify(this.setpointQueryDateRange.serialse()));
          if (this.asset) {
            this.get();
          } else {
            this.getForAssetContainer();
          }
        }
      });
    }
    */

  async getSetpointsForAssets() {
    console.log(`getSetpointsForAssets()`, this._asset);

    this.isWorking.set(true);

    for (const assetContainer of this.assetContainers()) {
      this.setpointService
        .getSetpointsForAssetAndRange(assetContainer.asset, this.apiService.RANGES_BY_TITLE.OPERATIONAL_HOURS)
        .then(setpointData => {
          console.log(setpointData);
          assetContainer.hasActiveSetpoints = setpointData.setpoints.some(setpoint => setpoint.isActive);
          if (!this.hasActiveSetpoints()) {
            assetContainer.setpoints = setpointData.setpoints;
          } else {
            assetContainer.setpoints = setpointData.setpoints.map(setpoint => {
              if (setpoint.startsAt) {
                setpoint.startsAt = setpoint.startsAt.slice(0, 5);
              }
              if (setpoint.endsAt) {
                setpoint.endsAt = setpoint.endsAt.slice(0, 5);
              }
            });
          }
          this.getForAssetContainer(assetContainer);
        });
    }
  }

  getSetpoints() {
    this.isWorking.set(true);
    this.setpointService
      .getSetpointsForAssetAndRange(this.asset, this.apiService.RANGES_BY_TITLE.OPERATIONAL_HOURS)
      .then(setpointData => {
        console.log(setpointData);
        this.hasActiveSetpoints.set(setpointData.setpoints.some(setpoint => setpoint.isActive));
        if (!this.hasActiveSetpoints()) {
          this.setpoints = setpointData.setpoints;
        } else {
          this.setpoints = setpointData.setpoints.map(setpoint => {
            if (setpoint.startsAt) {
              setpoint.startsAt = setpoint.startsAt.slice(0, 5);
            }
            if (setpoint.endsAt) {
              setpoint.endsAt = setpoint.endsAt.slice(0, 5);
            }

            return setpoint;
          });
        }
        this.master = setpointData.master;
        this.get();
      });
  }

  toggleHeaderDetails() {
    this.showHeaderDetails.set(!this.showHeaderDetails());
  }

  export() {
    const dt = moment(this.chartPresetDate.to).format('DD-MM-YY');
    const df = moment(this.chartPresetDate.from).format('DD-MM-YY');
    let filename = '';
    if (this.asset.gateway?.title) {
      filename = this.asset.gateway.title + ', ';
    }
    filename += `${this.asset.title}, ${df} to ${dt}`;
    this.exportService.exportAssetTelemetry(this.asset, this.packets(), this.setpoints, filename);
  }

  handleTabChange(event: any) {
    this.localStorageSetItem('tab', String(event.index));
    console.log(event);
  }

  chartDimensionChanged(event: any) {
    console.log(event);
  }

  localStorageSetItem(key, value) {
    if (this.saveState) {
      localStorage.setItem(`setpoint:query:${key}`, (typeof value !== 'string' ? JSON.stringify(value) : value));
    }
  }

  headerDateSelect(onDateSelect: IOnDateSelect) {
    if (!onDateSelect?.setpointQueryDateRange) {
      return;
    }
    setTimeout(() => {
      if (!this._asset && !this.assetContainers().length) {
        return;
      }

      const setpointQueryDateRange = onDateSelect.setpointQueryDateRange;

      if (onDateSelect.event === 'constructor' && !this.alwaysUseCustom) {
        if (setpointQueryDateRange.type === 'custom') {
          // Stop popup on init
          setpointQueryDateRange.type = 'today';
        }
        let lastUpdatedInDays;
        // If we have no recent data override to show last 7 days 
        if (this.assetContainers().length) {
          lastUpdatedInDays = moment().diff(moment(this.assetContainers()[0].asset.updatedAt), 'days');
        } else {
          lastUpdatedInDays = moment().diff(moment(this.asset.updatedAt), 'days');
        }

        if (lastUpdatedInDays > 7) {
          setpointQueryDateRange.type = 'lastx';
        }
      }


      this.setpointQueryDateRange = setpointQueryDateRange;
      this.setpoints = this.setpoints || [];


      if (this.assetContainers().length) {
        this.getForAssetContainer();
      } else {
        if (this._asset.updatedAt) {

          if (this._asset.gateway) {
            this.getSetpoints();
          } else {
            this.setpoints = [];
            this.get();
          }
        }
      }

    }, 10);

  }
}

export interface AssetContainer {
  asset: Asset | SiteFloorplanAsset;
  packets: Packet[];
  weather: ITelemetryWeather[];
  setpoints: any[];
  annotations: Annotation[];
  hasActiveSetpoints: boolean;
  isReady: boolean;
}

export interface LimitLine {
  dayOfWeek: boolean[];
  startTimeAt: string;
  endTimeAt: string;
  text: string;
  colour: string;
  value: number;
}

export type DateType = 'today' | '24hours' | 'yesterday' | 'lastweek' | 'thisweek' | 'custom' | 'lastx';

export class SetpointQueryDateRange {
  type: DateType;
  from: Date;
  to: Date;

  constructor(data?: { from: Date | string, to: Date | string, type?: DateType } | string, asset?: Asset) {
    let dataObj: { from: Date, to: Date, type: DateType } = { from: new Date(), to: new Date(), type: 'today' };
    if (data) {
      if (typeof data === 'string') {
        dataObj = JSON.parse(data);
        dataObj.from = new Date(dataObj.from);
        dataObj.to = new Date(dataObj.to);
      } else {
        if (typeof data.from === 'string') {
          dataObj.from = new Date(data.from);
        } else {
          dataObj.from = data.from;
        }
        if (typeof data.to === 'string') {
          dataObj.to = new Date(data.to);
        } else {
          dataObj.to = data.to;
        }
        if (data.type) {
          dataObj.type = data.type;
        }
      }
    }

    this.type = dataObj.type || 'today';
    this.from = dataObj.from || new Date();
    this.to = dataObj.to || new Date();
  }

  setFromTo(from: Date, to: Date) {
    if (typeof from !== 'object') {
      console.error(`setFromTo not object`, from);
    }
    this.from = from;
    this.to = to;
  }

  serialse() {
    return { from: this.from ? this.from.toISOString() : null, to: this.to ? this.to.toISOString() : null, type: this.type };
  }
}
