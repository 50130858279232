import { Asset } from "./asset";

export class ShapeCategories {
	// Compiled grouped 
	items: ShapeCategory[] = [];
	// Raw listing
	listing: any[] = [];

	constructor(data?: any[]) {
		data.forEach(c => {
			this.listing.push(c);
			let cat = this.items.find(cf => cf.id === cf.getIdFromTitle(c.category));
			if (!cat) {
				cat = new ShapeCategory({ title: c.category });
				this.items.push(cat);
			}
			cat.addAsset(c);
		});
	}
}

export class ShapeCategory {
	title: string;
	id: string;
	assets: ShapeCategoryAsset[] = [];
	sites: ShapeCategorySite[] = [];
	shapes: ShapeCategoryShape[] = [];

	constructor(data: any = {}) {
		this.title = data.title.trim();
		this.id = this.title.replace(/ /g, '_').toLocaleLowerCase();
	}

	getIdFromTitle(title: string) {
		return this.title.replace(/ /g, '_').toLocaleLowerCase();
	}

	addAsset(data: any) {
		const item: ShapeCategoryAsset = {
			id: data.assetId,
			title: data.assetTitle,
			type: data.assetTypeId,
			typeTitle: data.assetTypeTitle,
			updatedAt: data.assetUpdatedAt,
			value: data.assetValue,
			siteId: data.siteId,
			siteTitle: data.siteTitle,
			sfpId: data.sfpId,
			sfpTitle: data.sfpTitle,
			floor: data.floor,
			rag: data.assetRag
		}
		this.assets.push(item);
		this.buildSites();
		this.buildShapes();
	}

	buildSites() {
		this.sites = [];
		this.assets.forEach(a => {
			let site = this.sites.find(s => s.id === a.siteId);
			if (!site) {
				site = new ShapeCategorySite({ id: a.siteId, title: a.siteTitle });
				this.sites.push(site);
			}
			site.assets.push(a);
		});
	}
	buildShapes() {
		this.shapes = [];
		this.assets.forEach(a => {
			let shape = this.shapes.find(s => s.id === a.sfpId);
			if (!shape) {
				shape = new ShapeCategoryShape({ id: a.sfpId, title: a.sfpTitle, siteId: a.siteId, siteTitle: a.siteTitle });
				this.shapes.push(shape);
			}
			shape.assets.push(a);
		});
	}
}

export class ShapeCategoryAsset {
	id: number;
	title: string;
	type: number;
	typeTitle: string;
	updatedAt: string;
	value: number | string;
	siteId: number;
	siteTitle: string;
	sfpId: number;
	sfpTitle: string;
	floor: number;
	rag: string;
}

export class ShapeCategorySite {
	id: number;
	title: string;
	assets: ShapeCategoryAsset[] = [];
	constructor(data: any = {}) {
		this.id = data.id;
		this.title = data.title;
	}
}

export class ShapeCategoryShape {
	id: number;
	title: string;
	siteId: number;
	siteTitle: string;
	assets: ShapeCategoryAsset[] = [];
	constructor(data: any = {}) {
		this.id = data.id;
		this.title = data.title;
		this.siteId = data.siteId;
		this.siteTitle = data.siteTitle;
	}
}
