import { Address } from "./address";
import { Org } from "./org";
import { UserGroup } from "./user-group";
import { WeatherItem } from "./weather-item";

export class Site {
	id: number;
	title: string;
	isActive: boolean;
	isDeployable: boolean;
	addressTitle: string;
	address1: string;
	address2: string;
	address3: string;
	addressTown: string;
	addressCounty: string;
	addressCountry: string;
	addressPostcode: string;
	addressTel: string;
	addressEmail: string;
	addressLong: number;
	addressLat: number;
	address: Address;
	org: Org;
	localWeatherTemp: number;
	localWeatherIcon: string;
	localWeatherTitle: string;
	localWeatherLat: number;
	localWeatherLong: number;
	userGroups: UserGroup[] = [];
	createdAt: Date;
	regionId: number;
	regionTitle: string;
	gatewayCount: number;
	floorplanCount: number;
	rags: any[] = [];
	floorplans: any[] = [];
	isFav: boolean;
	rag: string;
	settings: any = {};
	metobsid: number;
	weather: WeatherItem;
	checked: boolean;
	hasDashboard: boolean;

	constructor(data?: any) {
		this.address = new Address();
		this.org = new Org();
		if (typeof data === 'number') {
			this.id = data;
		} else if (typeof data === 'object') {
			if (data.site) {
				this.userGroups = data.usergroups.map(userGroup => new UserGroup(userGroup));
				data = data.site;
			}
			this.address = new Address(data);
			this.title = data.title;
			this.id = data.id;
			this.org = new Org({ id: data.org_id || data.orgId, title: data.orgTitle, shortTitle: data.orgShortTitle });
			this.isActive = data.isActive === 1;
			this.isDeployable = data.isDeployable === 1;
			this.localWeatherTemp = data.localWeatherTemp || '';
			this.localWeatherIcon = data.localWeatherIcon || '';
			this.localWeatherTitle = data.localWeatherTitle || '';
			this.localWeatherLat = data.localWeatherLat ? +data.localWeatherLat : null;
			this.localWeatherLong = data.localWeatherLong ? +data.localWeatherLong : null;

			this.createdAt = data.createdAt;
			this.regionId = data.region_id;
			this.regionTitle = data.regionTitle || '';
			this.gatewayCount = data.gatewayCount || null;
			this.floorplanCount = data.floorplanCount || null;
			this.rag = data.siteRag;
			this.settings.floorplan_dashboard = data.floorplan_dashboard;
			this.metobsid = data.metobsId;
			this.hasDashboard = this.settings.floorplan_dashboard;
		}
	}

	serialise() {
		return { id: this.id, title: this.title, orgId: this.org.id };
	}
}
