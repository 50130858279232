export class BillingClient {
    id: number = null;
    title: string;
    shortTitle: string;
    notes: string;

    constructor(data?: any) {
        if (!data) {
            return;
        }
        this.id = data.billing_client_id || data.id;
        this.title = data.billingClientTitle || data.title;
        this.shortTitle = data.billingClientShortTitle || data.shortTitle;
        this.notes = data.billingClientNotes || data.notes;
    }

    serialise() {
        const response: any = {
            title: this.title,
            shortTitle: this.shortTitle,
            notes: this.notes
        };
        if (this.id) {
            response.id = this.id;
        }

        return response;
    }
}
