import { Component, OnInit, Input, OnDestroy, signal } from '@angular/core';
import { StoreService } from '../../shared/store.service';
import { WeatherService } from 'app/shared/weather.service';
import { Site } from 'app/classes/site';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-weather-temp',
  templateUrl: './weather-temp.component.html',
  styleUrls: ['./weather-temp.component.css']
})
export class WeatherTempComponent implements OnInit, OnDestroy {

  @Input()
  temperature: number;

  @Input()
  metobsid: number;

  _weather = signal<any>(null);
  @Input()
  public set weather(v: any) {

    if (!v) {
      this._weather.set(null);
      return;
    }
    this._weather.set(v);
    this.buildSrc();
  }

  public get weather(): any {
    return this._weather();
  }

  @Input()
  public set site(site: Site) {
    if (!site) {
      return;
    }
    this.weather = { icon: site.localWeatherIcon, temp: site.localWeatherTemp };
  }

  subscription: any;
  colour = 'black';
  src: string;
  fontIcon: string;

  constructor(private storeService: StoreService, protected weatherService: WeatherService, private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    if (!this.weather) {
      this.subscription = this.storeService.weather.subscribe(w => {
        if (w) {
          this.weather = w[0].filter(weather => weather.metobsid === this.metobsid)[0];
        }
      });
    }
  }

  buildSrc() {
    this.src = this.weatherService.textWeatherToURL(this.weather.icon);
    this.colour = this.weatherService.textWeatherToColour(this.weather.icon);
    this.fontIcon = this.weatherService.textWeatherToFont(this.weather.icon);
  }

  mouseenter() {
    if (typeof this.weather === 'undefined') {

      return;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
