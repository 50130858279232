<article>
	<main [ngClass]="{small: size()==='small', 'has-background':hasBackground()}">
		<section [ngClass]="{'toolbar-only-left': onlyLeft(), 'toolbar-has-center':hasCenter(), 'toolbar-no-center': noCenter(), 'toolbar-only-right':onlyRight()}">
			<div #left class="toolbar-left"><ng-content select=".left"></ng-content><ng-content></ng-content></div>

			<div #center class="toolbar-center"><ng-content select=".center"></ng-content></div>

			<div #right class="toolbar-right"><ng-content select=".right"></ng-content></div>
		</section>
	</main>
</article>
