<div class="chart-placeholder" style="height:200px" [style.width]="svgWidth" [class.loaded]="points">
  <span>Loading "{{asset.title}}" {{chartType}} chart...</span>
</div>
@if(points) {
<div class="chart-container">
  <svg [class.theme-black]="theme==='black'" [class.theme-white]="theme==='white'" [class.theme-bold]="theme==='bold'" #svg [style.width]="svgWidth" [attr.viewBox]="viewbox" xmlns="https://www.w3.org/2000/svg" style="height:200px" (click)="backgroundClicked($event)">
    <svg:defs>
      <svg:linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
        <svg:stop offset="0%" style="stop-color:#3f3f40;stop-opacity:1" />
        <svg:stop offset="100%" style="stop-color:#2A2A2B;stop-opacity:1" />
      </svg:linearGradient>

      <svg:pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
        <svg:path d="M-1,1 l2,-2
                   M0,4 l4,-4
                   M3,5 l2,-2" style="stroke:#666; stroke-width:1" />
      </svg:pattern>
    </svg:defs>

    <!-- legend -->
    <svg:g class="legend">
      <svg:text class="current-value" text-anchor="middle" [attr.x]="svgCenterX" y="14" style="font-weight: thin">
        <svg:tspan *ngIf="highlightedPoint">{{highlightedPoint.title}} &nbsp;</svg:tspan>
        <svg:tspan [style.stroke]="theme === 'white' ? 'purple' : 'yellow'" *ngIf="highlightedPoint">
          {{highlightedPoint.value}}</svg:tspan>
        <svg:tspan *ngIf="!highlightedPoint && !leftTopLegend">{{currentValue}}</svg:tspan>
      </svg:text>

      <svg:g *ngFor="let legend of legends">
        <svg:text text-anchor="middle" [attr.x]="legend.x + (blockWidth / 2)" y="180">{{legend.title}}</svg:text>
        <svg:line *ngIf="legend.x" stroke-dasharray="2, 2" [attr.x1]="legend.x" [attr.x2]="legend.x" [attr.y1]="svgOffset.y" [attr.y2]="blockHeight + svgOffset.y" stroke="#666"></svg:line>
      </svg:g>

      <!-- Left hand min/max values -->
      <svg:g class="left" *ngIf="!leftTopLegend">
        <svg:text text-anchor="left" x="0" [attr.y]="blockHeight + svgOffset.y + 10">{{minValue}}</svg:text>
        <svg:text text-anchor="left" x="0" [attr.y]="svgOffset.y - 1">{{maxValue}}</svg:text>
      </svg:g>

      <svg:g class="left" *ngIf="leftTopLegend">
        <svg:text text-anchor="left" x="0" [attr.y]="blockHeight + svgOffset.y + 10">{{leftBottomLegend}}</svg:text>
        <svg:text text-anchor="left" x="0" [attr.y]="svgOffset.y - 1">{{leftTopLegend}}</svg:text>
      </svg:g>
    </svg:g>

    <svg:g class="setpoints">
      <svg:g *ngFor="let point of setpointOutOfRange">
        <svg:rect fill="#d0d0d0" stroke="none" [attr.x]="point.x" [attr.width]="point.width" [attr.y]="point.y" [attr.height]="144"></svg:rect>
      </svg:g>
    </svg:g>

    <svg:g class="setpoints max-min">
      <svg:g *ngFor="let point of setpointMaxMin">
        <svg:rect [ngClass]="{'rag-amber': point.title==='amber', 'rag-red': point.title==='red'}" stroke="none" [attr.x]="point.x" [attr.width]="point.width" [attr.y]="point.y" [attr.height]="point.height"></svg:rect>
      </svg:g>
    </svg:g>
    <!-- telemetry line chart -->
    <svg:path *ngIf="dataPath" class="data-lines" [attr.d]="dataPath" fill="none" [style.stroke]="stroke"></svg:path>

    <svg:g *ngIf="highlightedPoint" class="highlighted">
      <!-- <svg:text fill="white" [attr.x]="0" [attr.y]="11" text-anchor="left" style="font-weight:100;opacity: 1">{{highlightedPoint.title}}</svg:text>-->
      <svg:line x1="0" x2="1008" [attr.y1]="highlightedPoint.y" [attr.y2]="highlightedPoint.y"></svg:line>
      <svg:line [attr.y1]="svgOffset.y" [attr.y2]="svgOffset.x + 144" [attr.x1]="highlightedPoint.x" [attr.x2]="highlightedPoint.x"></svg:line>
    </svg:g>

    <svg:g class="points" *ngIf="chartType === 'line'">
      <svg:g *ngFor="let point of points">
        <svg:circle *ngIf="showPoints || telemetryMax==point.value || telemetryMin==point.value" [class.selected]="point.selected" [class.other-value]=" telemetryMax != point.value && telemetryMin != point.value" [class.max-value]="telemetryMax==point.value" [class.min-value]="telemetryMin==point.value" (click)="onPointEnter(point)" (mouseenter)="onPointEnter(point)" [attr.cx]="point.x" [attr.cy]="point.y" r="2">
          <title>{{point.title}}</title>
        </svg:circle>
      </svg:g>
    </svg:g>

  </svg>
</div>
}
