import { Site } from "../site";
import { Asset } from "../asset";
import { ReviewAsset } from "./review-asset";
import { AssetFinding } from "./asset-finding";

export class Review {
    id: number;
    title: string;
    assets: ReviewAsset[] = [];
    findings: AssetFinding[] = [];
    telemetry: any[] = [];
    // Percentage complete.
    perc: number = 0;
    createdAt: Date = new Date();
    deployedAt: Date;
    counts: any = { findings: 0, assets: 0 };
    svg: any = {
        d: `M18 2.0845
    a 15.9155 15.9155 0 0 1 0 31.831
    a 15.9155 15.9155 0 0 1 0 -31.831`, strokeDashArray: "50,100"
    };

    constructor(data?: any) {
        if (data) {
            if (data.assets) {
                this.assets = data.assets.map(assetIn => {
                    return new ReviewAsset(assetIn);
                });;
            }
            if (data.findings) {
                this.findings = data.findings;
            }
            if (data.telemetry) {
                this.telemetry = data.telemetry;
            }
            this.id = data.review.id;
            this.createdAt = data.review.createdAt;
            this.title = data.review.title;
            this.counts.findings = data.review.findingsCount;
            this.counts.assets = data.review.assetCount;

        }
        this.calcSVG();
    }

    calcSVG() {
        // The percentage arc
        this.perc = +Number(100 / this.counts.assets * this.counts.findings).toFixed(0);

        this.svg.strokeDashArray = `${this.perc}, 100`;
    }
}

export class SiteReview extends Review {
    site: Site = new Site();
    startsAt: Date;
    endsAt: Date;
    constructor(siteReviewData?: SiteReviewDataInterface) {
        super(siteReviewData);
        if (siteReviewData) {
            if (siteReviewData.review.site_id) this.site.id = siteReviewData.review.site_id;
            if (siteReviewData.review.siteTitle) this.site.title = siteReviewData.review.siteTitle;
            this.startsAt = siteReviewData.review.startsAt;
            this.endsAt = siteReviewData.review.endsAt;
        }
    }
}

export interface SiteReviewDataInterface {
    assets?: any[];
    findings?: any[];
    review: any;
    telemetry?: any[];
}