<main class="theme4d">
    <header [class.no-background]="titleLink" [class.can-close]="canClose">
        <div *ngIf="titleLink || canNavigateBack || canClose" (click)="navigate()" class="can-click title">
            <i *ngIf="canNavigateBack" class="mdi mdi-chevron-left"></i><span *ngIf="logo"><img
					src="assets/svg/elogbooks-logo.svg" width="28px" height="28px"> </span> {{label}}
        </div>
        <div *ngIf="canClose" (click)="close()" class="can-click"><i class="fa fa-fw fa-times"></i></div>
        <div *ngIf="!titleLink && !canNavigateBack && !canClose" class="title">{{label}}</div>
    </header>

    <ng-content></ng-content>
</main>