import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { APIService } from './api.service';

@Injectable({ providedIn: 'root' })
export class ClipboardService {

    private _clipboard: BehaviorSubject<any> = new BehaviorSubject(null);
    public clipboard: Observable<any> = this._clipboard.asObservable();

    public list: any[] = [];

    constructor(private apiService: APIService) { }

    copy(type, object) {
        let clip = { type: type, obj: object, title: 'Clipboard', icon: 'clipboard' };
        switch (type) {
            case 'setpoint':
                clip.title = object.title || object.asset.gatewayTitle + ': ' + object.asset.title + ' ' + (object.startsAt || '') + ' - ' + (object.endsAt || '') + ' ' + (object.min || '') + '/' + (object.max || '') + ' ' + (object.allday ? 'all day' : '');
                clip.icon = 'bullseye';
                break;
        }

        this._clipboard.next(clip);
        this.list.push(clip);
    }

    static copyText(text: string) {
        navigator.clipboard.writeText(text);
    }
}
