import { ComplianceItem } from "./compliance-item";

export class ComplianceCollection {
	groups: ComplianceItem[] = [];
	title: string;
	values: any[] = [];
	assets: any[] = [];
	df: Date;
	dt: Date;
	constructor(data?: any) {
		this.df = typeof data.df === 'string' ? new Date(data.df) : data.df;
		this.dt = typeof data.dt === 'string' ? new Date(data.dt) : data.dt;
		this.values = data.values;
		this.assets = data.assets
			.map(r => { return { compliant: false, id: r.aId, title: r.aTitle, site: { id: r.sId, title: r.sTitle }, cId: r.cId } })
			.sort((a, b) => a.title < b.title ? -1 : 1);

		data.groups
			.forEach(item => {

				const group = new ComplianceItem(item);
				this.groups.push(group);

				group.assets = this.assets.filter(a => a.cId === group.id);
				group.values = [];
				group.sites = [];
				// Add group values to the group
				if (data.values) {
					data.values
						.filter(v => v.cId === group.id)
						.forEach(v => {
							const asset = this.assets.find(a => a.id === v.asset_id);
							group.values.push({ compliant: !!v.compliant, date: new Date(v.date), asset });
						});
					// Generate group sites

					group.values.forEach(v => {
						let site = group.sites.find(s => s.id === v.asset.site.id);
						if (!site) {
							site = { id: v.asset.site.id, title: v.asset.site.title, values: [] };
							group.sites.push(site);
						}
						let asset = group.assets.find(a => a.id === v.asset.id);

						if (!asset.compliant && v.compliant) {
							asset.compliant = true;
							asset.compliantAt = v.date
						}
						site.values.push(v);
					});
				}
				group.compliance = {
					noncompliant: group.assets.filter(a => !a.compliant).length,
					compliant: group.assets.filter(a => a.compliant).length
				};
			});

		if (this.groups[0]?.frequency === 'month' && this.df) {
			this.title = this.df.toLocaleString('default', { month: 'long' });
		} else {
			this.title = 'Compliance';
		}
	}
}
