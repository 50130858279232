export class LicensePackage {
	static readonly packages = [
		{ id: 1, title: 'Building, Quarterly Reporting', desc: 'A license that covers all unlicensed gateways for the building' },
		{ id: 2, title: 'Building, No Reporting', desc: 'A license that covers all unlicensed gateways for the building' },
		{ id: 3, title: 'Building, Trial - No Reporting', desc: 'A license that covers all unlicensed gateways for the building' },
		{ id: 4, title: 'Building, Trial - Quarterly Reporting', desc: 'A license that covers all unlicensed gateways for the building' },
		{ id: 5, title: 'Gateway, Quarterly Reporting', desc: 'A license that covers a gateway' },
		{ id: 6, title: 'Gateway, No Reporting', desc: 'A license that covers all unlicensed gateways for the building' },
	];

	static readonly PACKAGE_NAMES = {
		BUILDING_QUARTERLY_REPORTING: 1,
		GATEWAY_QUARTERLY_REPORTING: 5
	};

	_id: number;

	public get id(): number {
		return this._id;
	}

	public set id(v: number) {
		this._id = +v;
		if (v) {
			this._title = LicensePackage
				.packages.filter(lp => lp.id === +v)[0].title;
		} else {
			this._title = null;
		}
	}

	public get title(): string {
		return this._title;
	}

	_title: string;

	constructor(id?: any) {
		if (id) {
			this.id = id;
		} else {
			this.id = 1;
		}
	}

}
