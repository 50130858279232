<article>
    <section>
        <label for="title">Title</label>
        <input id="title" type="text" class="form-control" [(ngModel)]="asset.title">
   

        <label for="location">Location</label>
        <input id="location" type="text" class="form-control" [(ngModel)]="asset.location">
   
    </section>
      

    <section class="actions mt-1">

		<div class="text-center">
			<p-button [disabled]="isWorking" label="Cancel" icon="pi pi-times" styleClass="p-button-sm p-button-outlined p-button-secondary mr-1"
				(click)="cancel()">
			</p-button>
			<p-button [disabled]="isWorking" [label]="isWorking ? 'Updating....' : 'Update'" icon="mdi mdi-card-account-mail-outline" styleClass="p-button-sm" (click)="update()">
			</p-button>
		</div>

	</section>
</article>

