
export class SetpointTemplateCategory {
	id: number;
	title: string;
	notes: string;
	parent: SetpointTemplateCategory;
	isSelectable: boolean;

	constructor(data?: any) {
		if (!data) {
			return;
		}
		this.id = data.id;
		this.title = data.title;
		this.notes = data.notes;
		this.parent = new SetpointTemplateCategory(data?.parent);
		this.isSelectable = !!data.isSelectable;
	}

	serialise() {
		return {
			id: this.id,
			title: this.title,
			notes: this.notes
		};
	}
}
