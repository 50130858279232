<div [class.caption]="!!_caption()">
	@if(_caption()) {
	<label>{{_caption()}}</label>
	}
	<select [(ngModel)]="model" (ngModelChange)="changed()" [ngClass]="{'block':_block()}">
		@if(_placeholder()) {
		<option [ngValue]="undefined">{{_placeholder()}}</option>
		}
		@for (item of _list(); track item.id) {
		<option [value]="item.id">{{item.title}}</option>
		}
	</select>
</div>
