import { Component, OnInit, Input, OnChanges, OnDestroy, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Asset } from '../../classes/asset';
import { Point } from '../../classes/svg/point';
import { CalendarService } from '../../shared/calendar.service';
import { AssetService, IGetTelemetryForRangeAsObjectsResponse } from '../../shared/asset.service';
import { Setpoint } from '../../classes/setpoint';
import { APIService } from 'app/shared/api.service';
import { SetpointsService } from 'app/shared/setpoints.service';
import moment from 'moment';
@Component({
  selector: 'app-asset-chart',
  templateUrl: './asset-chart.component.html',
  styleUrls: ['./asset-chart.component.css']
})
export class AssetChartComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @ViewChild('svg') svg: ElementRef;

  @Input()
  rangeId = 2;

  @Input()
  stroke = '#00519e';

  @Input()
  startsAt: any;

  @Input()
  endsAt: any;

  @Input()
  fullwidth: boolean;

  @Output()
  onBlockClicked: EventEmitter<BlockClickedInterface> = new EventEmitter<BlockClickedInterface>();

  // 'black' | 'white'
  @Input()
  theme: string;

  dateEnd: any;
  dateStart: any;
  // Top part allocated for header
  svgOffset: any = { x: 20, y: 20 };
  svgWidth: string = '1008px';

  // The width of a week day
  blockWidth: number = 144;
  // The height of a block (thus all blocks)
  blockHeight: number = 144;
  // The number of blocks
  blockCount: number = 7;
  // How many minutes per pixel
  blockMinsPerPixel: number = 10;
  // Calculated total width of all blocks
  blocksWidth: number;
  // Maximum/Minimum chart value
  maxValue: number;
  minValue: number;

  telemetryMax: number;
  telemetryMin: number;
  leftTopLegend: string;
  leftBottomLegend: string;
  currentValue: number;

  // Parsed telemetry represented as svg points (value and title also)
  points: Point[];

  // The legends for each block
  legends: LegendInterface[] = [];

  // Th highlighted point, also points[] has the point.selected as true
  highlightedPoint: Point;

  // The viewport for the blocks (1008 is hardcoded as the 7*144 currently)
  viewbox = '0 0 1008 200';



  chartType = 'line';
  // The asset to plot the data for, if Asset.setpoints is populated this is used, otherwise use @Input for setpoints
  _asset: Asset;
  @Input()
  set asset(asset: Asset) {
    this._asset = asset;
    if (asset instanceof Asset) { } else {
      this.apiService.toastWarn('Asset error', '');
    }
    console.log('received', asset);
    if (asset.purpose.id === 6) {
      this.chartType = 'step';
    }

    setTimeout(() => {
      this.getValues();
    }, 200);


  }
  get asset(): Asset {
    return this._asset;
  }

  _setpoints: Setpoint[];
  @Input()
  set setpoints(setpoints: Setpoint[]) {
    console.log(`setpoints on graph`, setpoints);
    let refresh = null;
    if (this._setpoints) {
      refresh = true;
    }
    this._setpoints = setpoints;
    if (refresh && this.points) {
      this.plotSetpoints();
      this.plotTelemetry();
      this.plotLegend();
    } else {
      console.log('NO_REFRESH');
    }
  }
  get setpoints(): Setpoint[] {
    return this._setpoints;
  }

  // Pass true to http get setpoints here
  @Input()
  discoverSetpoints: boolean;

  // The top of the chart, the maximum number displayed
  @Input()
  chartMax: number;

  // The bottom of the chart , the minimum  number displayed
  @Input()
  chartMin: number;

  // Show the circles
  @Input()
  showPoints: boolean = false;

  // The received RAW tlemetry
  telemetry: IGetTelemetryForRangeAsObjectsResponse[];

  // The SVG <path> string of the telemetry
  dataPath: string = '';

  // The SVG <path> string for setpoints
  setpointOutOfRange: Point[];

  // The SVG <rect> string for min and max
  setpointMaxMin: Point[];

  // The ystep for values between min / max of graph.
  ystep: number;

  subs: any[] = [];
  svgCenterX = 0;

  constructor(
    private setpointService: SetpointsService,
    private calendarService: CalendarService,
    private assetService: AssetService,
    private apiService: APIService) {

    this.subs.push(assetService.setpoints.subscribe(setpoints => this.setpoints = setpoints));
    this.blocksWidth = this.blockCount * this.blockWidth;
  }

  ngOnInit() {
    this.svgCenterX = Math.floor((this.blockWidth * this.blockCount) / 2);

    const width = ((this.blockWidth * this.blockCount) + this.svgOffset.x * 2);
    if (this.fullwidth) {
      this.svgWidth = '100%';
    } else {
      this.svgWidth = width + 'px';
    }
    this.viewbox = '0 0 ' + width + ' 200';

    if (this.discoverSetpoints) {
      return this.setpointService.getSetpoints(this._asset).then(
        values => {
          this._setpoints = []; // force refresh
          this.setpoints = values.setpoints;
        });
    }
  }

  ngAfterViewInit() {

  }

  ngOnChanges() {
    console.log('change');
  }

  /**
   * Get the telemetry to plot onto a graph
   */
  getValues() {
    console.log(`getValues()`);
    // Retrieve the weeks data
    const self = this;

    if (this.startsAt) {
      this.dateEnd = this.endsAt;
      this.dateStart = this.startsAt;

      // ** REMOVED ** locale messes with this code.
      // Ensure we adjust for a full week of data
      // const daysToPlot = Math.round((this.endsAt.toDate() - this.startsAt.toDate()) / (1000 * 60 * 60 * 24));
      // if (daysToPlot < 6) {
      //   this.startsAt.subtract((6 - daysToPlot), 'days');
      // }

    } else {
      this.dateEnd = moment().endOf('day');
      this.dateStart = moment().subtract(6, 'days');
    }
    // Ensure the end date is the full day.
    this.dateEnd = this.dateEnd.set({
      'hour': 23,
      'minute': 59,
      'second': 59
    });

    this.assetService
      .getTelemetryForRangeAsObjects(this._asset, this.dateStart.toDate(), this.dateEnd.toDate())
      .then(values => {

        if (!values || (values && values.length === 0)) {
          this.points = [];
          return;
        }
        this.telemetry = values;
        // Most recent value
        this.currentValue = values[0].v;
        // Remove dates outside of range.
        const last = this.telemetry.length;
        for (let i = 0; i < last; i++) {
          const m = this.telemetry[i].m;
          const minutesOld = self.dateEnd.diff(m, 'minutes');
          // Greater than 7 days of minutes?
          if (minutesOld > 10080) {
            delete this.telemetry[i];
          }
        }

        this.plotSetpoints();
        if (this.chartType === 'line') {
          this.plotTelemetry();
        } else {
          this.plotSteps();
        }
        this.plotLegend();
        console.log('done getvalues');
      }
      );
  }

  plotSteps() {
    console.log(`plotSteps()`);
    if (typeof this.telemetry === 'undefined') {
      return;
    }
    let gmax = this.chartMax || 0;
    let gmin = this.chartMin || 99999;
    let ystep = 1;
    let self = this;

    let telemetryMin = 999999;
    let telemetryMax = -999999;
    this.telemetry.forEach(point => {
      if (point.v != null && +point.v > gmax) {
        gmax = +point.v;
      }
      if (point.v != null && +point.v < gmin) {
        gmin = +point.v;
      }
      // Record telemetry specific min/max
      if (point.v != null && +point.v > telemetryMax) {
        telemetryMax = +point.v;
      }
      if (point.v != null && +point.v < telemetryMin) {
        telemetryMin = +point.v;
      }
    });

    this.telemetryMin = telemetryMin;
    this.telemetryMax = telemetryMax;

    console.log(`min: ${gmin}, max:${gmax}`);

    if (gmax !== gmin) {
      ystep = this.blockHeight / (gmax - gmin);
    }

    this.ystep = ystep;

    // Update the chart min/max
    this.chartMax = gmax;
    this.chartMin = gmin;

    let path = '';
    let pathMode = 'M';
    this.points = [];


    let firstPoint = true;
    let previousPointY = null;

    // Create the path
    this.telemetry.forEach(telemetryPoint => {
      // Get the tlemetry timedate
      if (typeof telemetryPoint !== 'undefined') {
        const m = telemetryPoint.m;
        const y = +((((+telemetryPoint.v - gmin) * ystep)).toFixed(1));
        const minutesOld = self.dateEnd.diff(m, 'minutes');

        // Each pixel holds this.blockMinsPerPixel
        // Get the pixel to plot too, remove decimal portion.
        const pixelsOld = minutesOld > 0 ? Math.floor(minutesOld / this.blockMinsPerPixel) : 0;

        const point = this.calcXY(pixelsOld, y);
        point.x += this.svgOffset.x;
        point.value = telemetryPoint.v;
        point.title = m.format('dddd DD/MM/YYYY HH:mm');
        this.points.push(point);

        if (firstPoint) {
          firstPoint = false;
          previousPointY = point.y;
        }
        path += pathMode + `${point.x} ${previousPointY} `;
        path += pathMode + `${point.x} ${point.y} `;

        previousPointY = point.y;

        pathMode = 'L';
      }
    });

    this.minValue = gmin;
    this.maxValue = gmax;

    if ((this.asset.purpose && this.asset.purpose.id === 6)) {
      this.leftTopLegend = 'In Use';
      this.leftBottomLegend = 'Free';
    }

    this.dataPath = path;
  }

  plotLegend() {
    console.log(`plotLegend()`);
    this.legends = [];
    let dow = this.dateEnd.day();

    for (let idx = 1; idx <= this.blockCount; idx++) {
      const legend = {
        x: this.svgOffset.x + (this.blocksWidth) - (idx * this.blockWidth),
        title: this.calendarService.DOW_NAMES[dow],
        dow: dow
      };
      dow--;
      if (dow < 0) {
        dow = 6;
      }
      this.legends.push(legend);
    }
    this.plotMinMax();
  }

  /**
   * Populate the points[] array for the SVG template
   */
  plotTelemetry() {
    console.log(`plotTelemetry()`);
    if (typeof this.telemetry === 'undefined') {
      return;
    }
    let gmax = this.chartMax || 0;
    let gmin = this.chartMin || 99999;
    let ystep = 1;
    let self = this;

    let telemetryMin = 999999;
    let telemetryMax = -999999;
    this.telemetry.forEach(point => {
      if (point.v != null && +point.v > gmax) {
        gmax = +point.v;
      }
      if (point.v != null && +point.v < gmin) {
        gmin = +point.v;
      }
      // Record telemetry specific min/max
      if (point.v != null && +point.v > telemetryMax) {
        telemetryMax = +point.v;
      }
      if (point.v != null && +point.v < telemetryMin) {
        telemetryMin = +point.v;
      }
    });

    this.telemetryMin = telemetryMin;
    this.telemetryMax = telemetryMax;

    console.log(`min: ${gmin}, max:${gmax}`);

    if (gmax !== gmin) {
      ystep = this.blockHeight / (gmax - gmin);
    }

    this.ystep = ystep;

    // Update the chart min/max
    this.chartMax = gmax;
    this.chartMin = gmin;

    let path = '';
    let pathMode = 'M';
    this.points = [];

    // Create the path
    this.telemetry.forEach(telemetryPoint => {
      // Get the tlemetry timedate
      if (typeof telemetryPoint !== 'undefined') {
        const m = telemetryPoint.m;
        const y = +((((+telemetryPoint.v - gmin) * ystep)).toFixed(1));
        const minutesOld = self.dateEnd.diff(m, 'minutes');


        // Each pixel holds this.blockMinsPerPixel
        // Get the pixel to plot too, remove decimal portion.
        let pixelsOld = minutesOld > 0 ? Math.floor(minutesOld / this.blockMinsPerPixel) : 0;

        let point = this.calcXY(pixelsOld, y);
        point.x += this.svgOffset.x;
        point.value = telemetryPoint.v;
        point.title = m.format('dddd DD/MM/YYYY HH:mm');
        this.points.push(point);
        path += pathMode + `${point.x} ${point.y} `;

        pathMode = 'L';
      }
    });

    this.minValue = gmin;
    this.maxValue = gmax;
    this.dataPath = path;
  }

  plotMinMax() {
    console.log(`plotMinMax()`);
    let rects: Point[] = [];
    let x = this.blockWidth * (this.blockCount - 1);

    let dt = moment(this.dateEnd);

    for (let day = 7; day > 0; day--) {
      // Wekdays start at 0 for monday in setpoints
      let dow = (dt.day() - 1);
      if (dow < 0) {
        dow = 6;
      }
      //console.log(`Setpoint ${dt} is day ${dow}`);
      if (this.setpoints) {
        this.setpoints.forEach(setpoint => {
          if (setpoint.weekday === dow && setpoint.isActive) {

            if (setpoint.amber_min !== null) {
              let y1 = +((setpoint.amber_min - this.chartMin) * this.ystep).toFixed(1);

              if (y1 <= 0) {
                y1 = 1;
              }

              let pnt = new Point(this.svgOffset.x + x, (this.svgOffset.y + (this.blockHeight - y1)), this.blockWidth, y1);
              pnt.title = 'amber';
              rects.push(pnt);
            }

            if (setpoint.amber_max !== null) {
              let height2 = +(((this.chartMax - setpoint.amber_max) * this.ystep)).toFixed(1);
              if (height2 <= 0) {
                height2 = 1;
              }
              let pnt = new Point(this.svgOffset.x + x, this.svgOffset.y, this.blockWidth, height2);
              pnt.title = 'amber';
              rects.push(pnt);
            }

            if (setpoint.red_min !== null) {
              let y1 = +((setpoint.red_min - this.chartMin) * this.ystep).toFixed(1);

              if (y1 <= 0) {
                y1 = 1;
              }

              let pnt = new Point(this.svgOffset.x + x, (this.svgOffset.y + (this.blockHeight - y1)), this.blockWidth, y1);
              pnt.title = 'red';
              rects.push(pnt);
            }

            if (setpoint.red_max !== null) {
              let height2 = +(((this.chartMax - setpoint.red_max) * this.ystep)).toFixed(1);
              if (height2 <= 0) {
                height2 = 1;
              }
              let pnt = new Point(this.svgOffset.x + x, this.svgOffset.y, this.blockWidth, height2);
              pnt.title = 'red';
              rects.push(pnt);
            }
          }
        });
      }
      x = x - this.blockWidth;
      dt.subtract(1, 'day');
    }
    this.setpointMaxMin = rects;
    console.log('done plotMinMax');
  }

  plotSetpoints() {
    console.log(`plotSetpoints()`);
    if (!this._setpoints) {
      return;
    }

    let self = this;
    let setpoints: Setpoint[] = this.asset.setpoints || this._setpoints;

    this.chartMin = 999999;
    this.chartMax = 0;

    // Set the max/min startingpoint based on any enabled setpoints
    setpoints.forEach(setpoint => {
      if (setpoint.isActive) {
        if (typeof this.chartMin === 'undefined' || setpoint.amber_min < this.chartMin) {
          this.chartMin = setpoint.amber_min;
        }
        if (typeof this.chartMax === 'undefined' || setpoint.amber_max > this.chartMax) {
          this.chartMax = setpoint.amber_max;
        }

        if (typeof this.chartMin === 'undefined' || setpoint.red_min < this.chartMin) {
          this.chartMin = setpoint.red_min;
        }
        if (typeof this.chartMax === 'undefined' || setpoint.red_max > this.chartMax) {
          this.chartMax = setpoint.red_max;
        }
      }
    });

    // initialise the array or rectangles for the template
    this.setpointOutOfRange = [];

    // Clone the date
    let dt = moment(this.dateEnd);

    for (let day = 7; day > 0; day--) {
      // Wekdays start at 0 for monday in setpoints
      let dow = (dt.day() - 1);
      if (dow < 0) {
        dow = 6;
      }
      //console.log(`Setpoint ${dt} is day ${dow}`);

      setpoints.forEach(setpoint => {
        if (setpoint.weekday === dow && setpoint.isActive) {
          //console.log('MATCHED TO ', setpoint);
          // Point the date to the start of the range
          if (setpoint.startsAt) {
            let hour = +setpoint.startsAt.substring(0, 2);
            let min = +setpoint.startsAt.substring(3, 5);

            dt.set({
              'hour': hour,
              'minute': min,
            });
            let minutesOld = self.dateEnd.diff(dt, 'minutes');

            let pixelsOld = minutesOld > 0 ? Math.floor(minutesOld / this.blockMinsPerPixel) : 0;
            let xy = this.calcXY(pixelsOld, 0);
            let blockLeft = (day - 1) * this.blockWidth;

            let point = new Point(this.svgOffset.x + blockLeft, this.svgOffset.y, xy.x - blockLeft, this.blockHeight);

            this.setpointOutOfRange.push(point);
          }
          if (setpoint.endsAt) {


            let blockRight = ((day - 1) * this.blockWidth) + this.blockWidth;

            let hour = +setpoint.endsAt.substring(0, 2);
            let min = +setpoint.endsAt.substring(3, 5);

            dt.set({
              'hour': hour,
              'minute': min,
            });
            let minutesOld = self.dateEnd.diff(dt, 'minutes');

            let pixelsOld = minutesOld > 0 ? Math.floor(minutesOld / this.blockMinsPerPixel) : 0;
            let xy = this.calcXY(pixelsOld, 0);
            let point = new Point(this.svgOffset.x + xy.x, this.svgOffset.y, blockRight - xy.x, this.blockHeight);
            this.setpointOutOfRange.push(point);
          }
        }
      });

      dt.subtract(1, 'day');
    }
  }

  calcXY(pixelsOld: number, y: number): Point {

    let x = +(this.blocksWidth - pixelsOld).toFixed(0);
    y = +(this.svgOffset.y + (this.blockHeight - y)).toFixed(0);
    return { x: x, y: y };
  }

  /**
   * User has hovered over a data point on the graph
   * @param point Point
   */
  onPointEnter(point: Point) {
    // console.log(point);
    if (this.highlightedPoint) {
      // de-select old point
      this.highlightedPoint.selected = false;
    }
    point.selected = true;
    this.highlightedPoint = point;
  }

  onPointLeave(point: Point) {
    if (this.highlightedPoint) {
      // de-select old point
      this.highlightedPoint.selected = false;
    }
  }

  ngOnDestroy() {
    this.subs[0].unsubscribe();
  }

  backgroundClicked(e: any) {
    if (!this.points || this.points.length === 0) {
      return;
    }
    console.log(e);
    let x = e.offsetX;
    let y = e.offsetY;

    // Ignore out of bounds on legends
    if (x <= this.svgOffset.x || x > (this.svgOffset.x + (this.blockWidth * this.blockCount))) {

      return;
    }

    // Set X to be minimum of 0 so we can calculate which block
    x -= this.svgOffset.x;

    let block = Math.floor(x / this.blockWidth);

    this.onBlockClicked.emit({ index: block, legend: this.legends[6 - block] });

    console.log('on square', block);
  }
}

export interface BlockClickedInterface {
  index: number;
  legend: LegendInterface;
}

export interface LegendInterface {
  x: number;
  title: string;
  dow: number;
}

/**
 * PROCESS
 *
 * 1 Set chart min/max from setpoints min/max
 * 2 Set chart min/max starting with setpoints baseline, set min/max if over or under setpoints
 *   -- FINAL VALUE for this.chartMin/chartMax/ystep
 *
 * 3 Set min/max legend rects
 */
