import moment from 'moment';

export class S3SiteRagAsset {
    assetId: number;
    month: number;
    year: number;
    days: S3SiteRagAssetDay[] = [];

    constructor(asset?: { assetId: number, month: number, year: number, data: any[], key?: string }) {

        if (!asset) {
            return;
        }
        this.assetId = asset.assetId;
        this.month = asset.month;
        this.year = asset.year;

        // Fill days with empty placeholders
        const daysInMonth = new Date(this.year, this.month, 0).getDate();

        for (let day = 1; day <= daysInMonth; day++) {
            this.days.push({ day, totals: null });
        }

        asset.data.forEach(day => {
            const s3Day = new S3SiteRagAssetDay(day);
            this.days[s3Day.day - 1] = s3Day;
        });
    }
}

export class S3SiteRagAssetDay {
    day: number;
    totals: S3SiteRagAssetDayTotals;

    constructor(day?: { day: number, totals: any }) {
        if (!day) {
            return;
        }
        this.day = day.day;
        this.totals = new S3SiteRagAssetDayTotals(day.totals);
    }
}

export class S3SiteRagAssetDayTotals {
    avg: number;
    min: number;
    max: number;
    rag: number;
    ragText: string;
    counter: number;

    constructor(totals?: { avg: number, max: number, min: number, rag: number, counter: number }) {
        if (!totals) {
            return;
        }
        this.avg = totals.avg;
        this.max = totals.max;
        this.min = totals.min;
        this.rag = totals.rag;
        this.ragText = ['green', 'amber', 'red'][totals.rag];
        this.counter = totals.counter;
    }
}

export class S3SiteRagDay {
    date: Date;
    totals: { min: number, max: number, avg: number, rag: number, counter: number };
}

export class S3SiteRag {
    from: Date;
    to: Date;
    items: any;
    assetIds: string[];

    /** @deprecated */
    assets: S3SiteRagAsset[] = [];

    constructor(data?: any) {
        if (data.assets) {
            this.from = data.from;
            this.to = data.to;
            this.items = data.assets;
            this.assetIds = Object.keys(data.assets);
        } else {
            data.forEach(asset => {
                asset = new S3SiteRagAsset(asset);
                this.assets.push(asset);
            });
        }
    }

    getMonths() {
        return (moment(this.to).diff(this.from, 'months'));
    }
}
