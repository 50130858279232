import { Site } from './site';

export class SitePlan {
    id: number;
    title: string;
    site: Site;
    planKey: string;
    createdAt: Date;
    viewBoxX = 0;
    viewboxY = 0;
    viewboxWidth: number;
    viewboxHeight: number;
    vbx: number;
    vby: number;
    fontSize: number;
    order: number;
    type: string;
    scenario: string;
    isColour: boolean;
    floor: number;
    hasDashboard: boolean;
    hasOccupancy: boolean;
    filter: string;

    constructor(data?: any) {
        if (typeof data === 'object') {
            this.title = data.title;
            this.id = data.id;
            this.site = new Site({ id: data.site_id, title: data.siteTitle, orgShortTitle: data.orgShortTitle, floorplan_dashboard: data.floorplan_dashboard });
            this.site.rag = data.siteRag;
            this.createdAt = data.createdAt;
            this.planKey = data.planKey;
            this.viewboxHeight = data.viewboxHeight;
            this.viewboxWidth = data.viewboxWidth;
            this.vbx = data.vbx || 0;
            this.vby = data.vby || 0;
            this.fontSize = data.fontSize;
            this.order = data.order;
            this.type = data.type;
            this.scenario = data.scenario;
            this.isColour = !!(data.isColour);
            this.floor = data.floor;
            this.hasDashboard = !!data.floorplan_dashboard;
            this.hasOccupancy = !!data.hasOccupancy;
            this.filter = data.filter;
        } else {
            this.site = new Site();
            this.type = 'basic';
        }
    }
}
