/**
 * data: { groupings:[], siteNotification:boolean }
 */
import { Component, OnInit } from "@angular/core";
import { Grouping } from "app/classes/grouping";
import { APIService } from "app/shared/api.service";
import { OrgService } from "app/shared/org.service";
import { MessageService } from "primeng/api";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

@Component({
  selector: "app-dialog-group-select",
  templateUrl: "./dialog-group-select.component.html",
  styleUrls: ["./dialog-group-select.component.css"],
  providers: [DialogService],
})
export class DialogGroupSelectComponent implements OnInit {
  groupings: Grouping[] = [];
  dialogRef: DynamicDialogRef;

  selected: Grouping[];
  groupingActive: Grouping;

  constructor(
    public dialogService: DialogService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public messageService: MessageService,
    private orgService: OrgService
  ) {}

  ngOnInit(): void {
    this.orgService.getGroupingsAndUsers().then((groupings) => {
      this.groupings = groupings;
      this.groupingActive = groupings[0];
      if (this.config.data.groupings) {
        this.groupings.forEach(g => g.selected = this.config.data.groupings.findIndex(s => s.id === g.id) !== -1);
      }
    });
  }

  cancel() {
    this.ref.close();
  }

  async submit() {
    this.ref.close(this.groupings.filter(g => g.selected));
  }
}
