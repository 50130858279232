<article>

	<main>
		<section *ngFor="let collection of collections">
			<h3>{{collection.t}}</h3>
			<table>
				<!--	<thead>
					<tr>
						<th>Start Time</th>
						<th>End Time</th>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
					</tr>
				</thead> -->
				<tbody>
					<tr *ngFor="let day of collection.days">
						<td>{{day.startTime.substr(0,5)}} to {{day.endTime.substr(0,5)}}</td>
						<td *ngFor="let dayIndex of day.days; let i = index">
							<span [ngClass]="{'is-enabled': dayIndex}"><i class="mdi" [ngClass]="{
								'mdi-checkbox-marked-outline': dayIndex,
								'mdi-checkbox-blank-outline': !dayIndex
							}"></i> {{dayNames[i]}}</span>
						</td>
					</tr>
				</tbody>
			</table>
		</section>
	</main>

</article>
