export class Document {
	id: number;
	key: string;
	title: string;
	description: string;
	colour: string;
	contentType: string;
	ext: string;
	createdAt: Date;
	isRead: boolean;
	buildingTitle: string;
	buildingId: number;
	calendarStartAt: Date;
	orgLogo: string;
	orgId: number;
	rag: string;
	notes: string;
	notesColour: 'red' | 'green' | 'orange';
	notesAt: Date;
	orgShortTitle: string;
	siteIsActive: boolean;
	orgIsActive: boolean;
	isActive: boolean;

	constructor(data?: any) {
		if (data) {
			this.id = data.id;
			this.title = data.title;
			this.key = data.key;
			this.createdAt = data.createdAt;
			this.colour = data.colour;
			this.description = data.description;
			this.contentType = data.contentType;
			this.ext = data.ext;
			this.isRead = data.isRead;
			this.calendarStartAt = data.calendarStartAt;
			this.orgLogo = data.orgLogo;
			this.orgId = data.orgId;
			this.buildingTitle = data.buildingTitle;
			this.buildingId = data.buildingId;
			this.rag = data.rag;
			this.notes = data.notes;
			this.notesColour = data.notesColour;
			this.orgShortTitle = data.orgShortTitle;
			this.siteIsActive = !!data.siteIsActive;
			this.orgIsActive = !!data.orgIsActive;

			this.isActive = (this.siteIsActive && this.orgIsActive);

			if (data.notesAt) {
				this.notesAt = new Date(data.notesAt);
			}
		}
	}

	serialise() {
		return {
			id: this.id,
			title: this.title
		};
	}
}
