import { Component, EventEmitter, Input, Output, signal, input, computed } from '@angular/core';
import { ICalendarDay } from '../../layout/calendar/calendar.component';
import moment from 'moment';
import { SetpointQueryDateRange } from '../setpoint-query/setpoint-query.component';
import { ButtonSize } from 'app/layout/button/button.component';
import { APIService } from 'app/shared/api.service';
import { Asset } from 'app/classes/asset';

@Component({
  selector: 'app-setpoint-dates-button',
  templateUrl: './setpoint-dates-button.component.html',
  styleUrl: './setpoint-dates-button.component.css'
})
export class SetpointDatesButtonComponent {

  _onDialog = signal<boolean>(false);
  label = signal<string>('Select Date');
  _config = signal<SetpointQueryDateRange>(null);
  _caption = signal<string | null>(null);
  _size = signal<ButtonSize>(null);
  _originalConfig = signal<SetpointQueryDateRange>(null);
  asset = input<Asset>(null)

  @Input()
  public set caption(v: string) {
    this._caption.set(v);
  }

  @Input()
  public set value(v: SetpointQueryDateRange) {
    console.log(`set config`, v);
    // Use a clone
    this._config.set(new SetpointQueryDateRange(v));
    this._originalConfig.set(v);
    this._config.set(v);
    this.changed();
  }

  minmax = input<IMinMaxDates>();

  @Input()
  public set selected(v: boolean) {
    this._selected.set(v);
  }

  _selected = signal<boolean>(false);

  @Output()
  onSelect = new EventEmitter<SetpointQueryDateRange>();

  @Input()
  public set size(v: ButtonSize) {
    this._size.set(v);
  }

  showMinMaxButton = computed(() => {
    return !!this.minmax();
  });

  constructor(private apiService: APIService) {

  }

  closed(event?: any) {
    this.onSelect.emit(new SetpointQueryDateRange(this._config()));
    this._onDialog.set(false);
    this.buildLabel();
  }

  clickLastMonth() {
    const dateStart = moment().subtract(1, 'month').startOf('month').toDate();
    const dateEnd = moment().subtract(1, 'month').endOf('month').toDate();
    this.changeDate('from', { date: dateStart });
    this.changeDate('to', { date: dateEnd });
  }

  clickThisMonth() {
    const dateStart = moment().startOf('month').toDate();
    const dateEnd = moment().toDate();
    this.changeDate('from', { date: dateStart });
    this.changeDate('to', { date: dateEnd });
  }

  clickAllData() {
    this.gotoStartOfTelemetry();
    this.gotoEndOfTelemetry();
    this.closed();
  }

  clickDates() {
    this.closed();
  }

  buildLabel() {
    if (this._config()) {
      const dates = this._config();
      const from = dates?.from ? moment(dates.from).format('DD/MM/YYYY') : null;
      const to = dates?.to ? moment(dates.to).format('DD/MM/YYYY') : null;
      if (from === null || to === null) {
        this.label.set('Select Dates');
      } else {
        this.label.set(`${from} to ${to}`);
      }
    } else {
      this.label.set('Select Dates');
    }
  }

  changed() {
    this.buildLabel();
  }

  dateButtonClick(dateRange: any) {
    this._config.update(item => {

      return item;
    });
    this.changed();
  }

  changeDate(part: 'from' | 'to', day: ICalendarDay) {
    console.log(part, day);

    this._config.update(obj => {
      if (obj) {
        obj[part] = day.date;
      }
      return obj;
    });

    this.changed();
  }

  clickCancel() {
    this._config.set(this._originalConfig());
    this.buildLabel();
    this._onDialog.set(false);
    this.onSelect.emit(null);
  }

  gotoStartOfTelemetry() {
    const startOfTelemetry = moment(this.minmax().min);
    let to = moment(this._config().to);

    this.changeDate('from', { date: startOfTelemetry.toDate() });


    if (this.asset()?.settings?.length) {
      const rate = this.asset().settings.find(s => s.setting === 'ingest_rate_day');

      if (+rate?.value === 1) {
        // 1 a day, no limit on selection.
        return;
      }
    }

    if (Math.abs(to.diff(startOfTelemetry, 'months')) > 3) {
      this.apiService.toastSuccess('Limited data to 3 months', '');
      to = startOfTelemetry.clone().add(3, 'months').subtract(1, 'day');
      this.changeDate('to', { date: to.toDate() });
    }

  }

  gotoEndOfTelemetry() {
    const endOfTelemetry = moment(this.minmax().max);
    let from = moment(this._config().from);

    this.changeDate('to', { date: endOfTelemetry.toDate() });

    if (this.asset()?.settings?.length) {
      const rate = this.asset().settings.find(s => s.setting === 'ingest_rate_day');

      if (+rate?.value === 1) {
        // 1 a day, no limit on selection.
        return;
      }
    }

    if (Math.abs(from.diff(endOfTelemetry, 'months')) > 3) {
      this.apiService.toastSuccess('Limited data to 3 months', '');
      from = endOfTelemetry.clone().subtract(3, 'months').add(1, 'day');
      this.changeDate('from', { date: from.toDate() });
    }
  }
}

export interface IDateConfig {
  dateRange: 'lastweek' | 'custom';
  dates?: { from: Date, to: Date };
}


export interface IMinMaxDates {
  min?: Date;
  max?: Date;
}
