import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx-js-style';
// @ts-ignore
import { saveAs } from 'file-saver';
import { Site } from 'app/classes/site';
import moment from 'moment';
import { Asset } from 'app/classes/asset';
import { LicenseBuilding } from 'app/classes/license';
import { DashboardMonthly } from 'app/site-plan/site-plan-dashboard/spd-monthly/spd-monthly.component';
import { IGetMonthlyForSiteResponse } from './occupancy.service';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  constructor() { }

  public exportTriggerReport(data: ITriggerReportRow[], filename = 'trigger_report') {

    const rows = data.map(item => {
      return { id: item.a.id, title: item.a.title, value: item.v, ingested: item.d, site: item.a.siteTitle, gateway: item.a.gatewayId };
    });

    const ws0: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows);

    const wb: XLSX.WorkBook = { Sheets: { 'Report': ws0 }, SheetNames: ['Report'] };

    wb.Props = {
      Title: 'Trigger Report',
      Author: "4D Monitoring",
      CreatedDate: new Date()
    };

    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    this.saveExcelFile(excelBuffer, filename);
  }

  public exportOccupancyMonthly(dashboardMonthly: DashboardMonthly) {
    const filename = 'monthly_occupancy';
    const rows: any[] = [];
    const rowColours: any[] = [];
    const jsonData = dashboardMonthly.assets.sort((a, b) => a.title > b.title ? 1 : -1).map(asset => {

      asset.days.forEach(day => {
        if (day.d) {
          rows.push({
            id: asset.id, title: asset.shapeTitle || asset.title,
            floor: asset.floorTitle,
            d: day.d,
            p: day.p || 0,
            m: day.m
          });
          rowColours.push(day.c);
        }
      });
    });

    const ws0: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows);

    const wb: XLSX.WorkBook = { Sheets: { 'Monthly': ws0 }, SheetNames: ['Monthly'] };

    wb.Props = {
      Title: 'Monthly Occupancy',
      Author: "4D Monitoring",
      CreatedDate: new Date()
    };

    ws0["!cols"] = [
      { wpx: 50 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 70 },
      { wpx: 60 },
      { wpx: 60 }
    ];

    ws0['A1'].v = 'Asset Id';
    ws0['B1'].v = 'Room';
    ws0['C1'].v = 'Floor';
    ws0['D1'].v = 'Date';
    ws0['E1'].v = 'Percent';
    ws0['F1'].v = 'Minutes';

    const rgb: any = { 'grey': 'f0f0f0', 'red': 'ff0000', 'green': '00ff00', 'yellow': 'ffff00', 'lightgreen': '90EE90', 'orange': 'ffa500' };

    for (let index = 0; index < (rows.length - 1); index++) {
      const key = String(index + 2);
      if (ws0['B' + key]) {
        ws0['B' + key].t = 's';
        ws0['C' + key].t = 's';
        ws0['E' + key].s = {
          fill: { bgColor: { rgb: rgb[rowColours[index]] } }
        }
      }
    }


    console.log(ws0);
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    this.saveExcelFile(excelBuffer, filename);
  }

  public exportOccupancyForSite(occupancy: IGetMonthlyForSiteResponse) {

    if (!occupancy) {
      console.log('NO_DATA');

      return;
    }

    const fromDate = occupancy.months[0].dates.from;
    const toDate = occupancy.months[0].dates.to;
    const assets = Object.keys(occupancy.assets).map(key => occupancy.assets[key]);
    const sheetName = (fromDate.toLocaleDateString().replaceAll('/', '_') + ' to ' + toDate.toLocaleDateString().replaceAll('/', '_')).substring(0, 30);
    const colours = { a: '008000', b: 'ADE232 ', c: 'FFFB00', d: 'FF8C00', e: 'FF0000' };
    const filename = 'monthly_occupancy_site';
    const rows = [];
    const aoa: any = [['id', 'Sensor', 'Floor', 'Room', 'Tenant', 'Service']];
    const cols = [
      { wpx: 50 },
      { wpx: 100 },
      { wpx: 40 },
      { wpx: 110 },
      { wpx: 110 },
      { wpx: 110 }
    ];

    // Build day columns for all months
    for (let monthIndex = 0; monthIndex < occupancy.months.length; monthIndex++) {
      const month = occupancy.months[monthIndex];
      for (let day = 0; day < month.master.days; day++) {
        if (+new Date(month.dows[day].date) >= +fromDate && + new Date(month.dows[day].date) <= +toDate) {
          aoa[0].push(`${month.dows[day].day}`);
          cols.push({ wpx: 25 });
        }
      }
    }

    // Build asset rows for all months

    assets.sort((a, b) => a.title > b.title ? 1 : -1).forEach(asset => {
      const row: {
        v: string,
        t?: string,
        s?: { fill: { fgColor: { rgb: string } } }
      }[] = [{ v: String(asset.a_id) }, { v: asset.title }, { v: asset.floor }, { v: asset.shape?.shapeTitle }, { v: asset.tenantTitle }, { v: asset.serviceTitle }];

      for (let monthIndex = 0; monthIndex < occupancy.months.length; monthIndex++) {
        const month = occupancy.months[monthIndex];
        const dates = month.dows;
        const assetForMonth = month.assets[asset.a_id];

        for (let day = 0; day < month.master.days; day++) {
          const date = +new Date(month.dows[day].date);
          if (date >= +fromDate && date <= +toDate) {
            try {
              const rating = assetForMonth.rating[day].length ? assetForMonth.rating[day] : null;

              const colour: string = rating ? colours[rating] : 'f0f0f0';
              if (rating) {
                row.push(
                  { v: assetForMonth.perc ? String(assetForMonth.perc[day]) : '', t: 'n', s: { fill: { fgColor: { rgb: colour } } } }
                );
              } else {
                // No data for day (no collection defined, perhaps a Sa but Mo-Fri only)
                row.push(
                  { v: '', t: 'n', s: { fill: { fgColor: { rgb: colour } } } }
                );
              }
            } catch (e) {
              console.log(e);
              row.push(
                { v: '', t: 'n', s: { fill: { fgColor: { rgb: 'ffffff' } } } }
              );
            }
          }
        }
      }

      aoa.push(row);
    });

    const ws0: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(aoa);

    const wbSheet = { Sheets: {}, SheetNames: [] };
    wbSheet.Sheets[sheetName] = ws0;
    wbSheet.SheetNames.push(sheetName);
    const wb: XLSX.WorkBook = wbSheet;

    wb.Props = {
      Title: 'Monthly Occupancy ' + occupancy.plans[0].title,
      Author: "4D Monitoring",
      CreatedDate: new Date()
    };

    ws0["!cols"] = cols;

    for (let index = 0; index < (rows.length - 1); index++) {
      const key = String(index + 2);
      if (ws0['B' + key]) {
        ws0['B' + key].t = 's';
        ws0['C' + key].t = 's';
      }
    }

    console.log(ws0);
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    this.saveExcelFile(excelBuffer, filename);
  }

  public exportLicenses(licenses: LicenseBuilding[], filename: string): void {

    const sortedLicenses = licenses.toSorted((a, b) => a.expiresAt > b.expiresAt ? 1 : -1);

    const jsonData = sortedLicenses.map(l => {
      const obj: any = {
        for: l.title,
        client: l.billingClient.shortTitle,
        org: l.building?.org.shortTitle,
        expires: moment(l.expiresAt).format('DD/MM/YYYY'),
        value: l.value,
        commission: l.commission
      };

      return obj;
    });

    const ws0: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);

    const wb: XLSX.WorkBook = { Sheets: { 'licenses': ws0 }, SheetNames: ['licenses'] };

    wb.Props = {
      Title: filename,
      Author: "4D Monitoring",
      CreatedDate: new Date()
    };

    ws0["!cols"] = [
      { wpx: 300 },
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 70 },
      { wpx: 60 },
      { wpx: 60 }
    ];

    ws0['A1'].v = 'For';
    ws0['B1'].v = 'Client';
    ws0['C1'].v = 'Org';
    ws0['D1'].v = 'Expires';
    ws0['E1'].v = 'Value';
    ws0['F1'].v = 'Commission';


    for (let index = 0; index < (jsonData.length - 1); index++) {
      const key = String(index + 2);
      if (ws0['B' + key]) {
        ws0['B' + key].t = 's';
        ws0['C' + key].t = 's';
        ws0['D' + key].t = 's';
        ws0['E' + key].t = 'n';
        ws0['F' + key].t = 'n';
      }
    }

    ws0['A1'].fill = {
      fgColor: { rgb: 'FFA500' }, // Orange color in RGB format
      bgColor: { rgb: 'FFA500' } // Orange color in RGB format
    };

    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    this.saveExcelFile(excelBuffer, filename);
  }

  public exportExcel(jsonData: any[], site: Site, fileName: string): void {

    const ws0: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(site.rags
      .map(rag => {
        return { date: rag.day + '/' + rag.month + '/' + rag.year, rag: ['green', 'amber', 'red'][rag.rag] }
      })
      .reverse());
    const wb: XLSX.WorkBook = { Sheets: { 'assets': ws0, 'site': ws1 }, SheetNames: ['site', 'assets'] };

    wb.Props = {
      Title: fileName,
      Author: "4D Monitoring",
      CreatedDate: new Date()
    }

    ws0["!cols"] = [
      { wpx: 200 },
      { wpx: 100 },
      { wpx: 70 },
      { wpx: 70 },
      { wpx: 70 }
    ];

    ws1["!cols"] = [
      { wpx: 100 },
      { wpx: 80 }];

    ws0['A1'].v = 'Asset';
    ws0['B1'].v = 'Date';
    ws0['C1'].v = 'Minimum';
    ws0['D1'].v = 'Average';
    ws0['E1'].v = 'Maximum';

    ws1['A1'].v = 'Date';
    ws1['B1'].v = 'RAG';

    for (let index = 0; index < (jsonData.length - 1); index++) {
      const key = String(index + 2);
      if (ws0['B' + key]) {
        ws0['B' + key].t = 's';
        ws0['C' + key].t = 'n';
        ws0['D' + key].t = 'n';
        ws0['E' + key].t = 'n';
      }
    }

    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    this.saveExcelFile(excelBuffer, fileName);
  }

  public exportToExcel(payload: IPayload) {

    const ws0: XLSX.WorkSheet = XLSX.utils.json_to_sheet(payload.json);
    const wb: XLSX.WorkBook = { Sheets: { 'assets': ws0 }, SheetNames: ['assets'] };

    wb.Props = {
      Title: payload.filename,
      Author: "4D Monitoring",
      CreatedDate: new Date()
    }
    const json = payload.json.forEach(row => {
    });
  }

  public exportAssetsToExcel(jsonData: any[], fileName: string): void {

    jsonData = jsonData.filter(i => i.d !== 'WiFi' && i.d !== 'IP Address');

    for (let index = 0; index < jsonData.length; index++) {
      const item = jsonData[index];
      item.e = moment(item.e).format('DD/MM/YYYY HH:mm');
      item.g = moment(item.g).format('DD/MM/YYYY HH:mm');
    }


    const ws0: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { 'assets': ws0 }, SheetNames: ['assets'] };

    wb.Props = {
      Title: fileName,
      Author: "4D Monitoring",
      CreatedDate: new Date()
    }

    ws0["!cols"] = [
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 150 },
      { wpx: 110 },
      { wpx: 90 },
      { wpx: 90 },
      { wpx: 90 },
      { wpx: 90 }
    ];

    ws0['A1'].v = 'Site';
    ws0['B1'].v = 'Gateway';
    ws0['C1'].v = 'Asset';
    ws0['D1'].v = 'Type';
    ws0['E1'].v = 'Last updated';
    ws0['F1'].v = 'Value';
    ws0['G1'].v = 'Created';
    ws0['H1'].v = 'Asset ID';

    for (let index = 0; index < (jsonData.length - 1); index++) {
      const key = String(index + 2);
      if (ws0['B' + key]) {
        ws0['B' + key].t = 's';
        ws0['B' + key].t = 's';
        ws0['C' + key].t = 's';
        ws0['D' + key].t = 's';
        ws0['E' + key].t = 's';
        if (isNaN(jsonData[index].e)) {
          ws0['F' + key].t = 's';
        } else {
          ws0['F' + key].t = 'n';
        }
        ws0['G' + key].t = 's';
        ws0['H' + key].t = 'n';
      }
    }

    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    this.saveExcelFile(excelBuffer, fileName);
  }

  public exportAssetTelemetry(asset: Asset, data: any[], setpoints: any[], filename = 'export') {

    const dataForWS = data.map(item => {
      const humidity = item.w?.h;
      return {
        date: moment(item.d).format('DD/MM/YY HH:mm'),
        rag: item.rag,
        value: item.v,
        temperature: item.w?.t,
        wind: item.w?.w,
        humidity: humidity ? humidity / 100 : ''
      };
    });

    const ws0: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataForWS);

    const configuration = setpoints.map(setpoint => {
      return {
        day: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'][setpoint.weekday],
        from: setpoint.startsAt,
        to: setpoint.endsAt,
        allday: setpoint.allday ? 'Yes' : '',
        red_min: setpoint.red_min,
        amber_min: setpoint.amber_min,
        amber_max: setpoint.amber_max,
        red_max: setpoint.red_max,
        disabled: setpoint.isActive ? '' : 'Yes'
      };
    });

    const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(configuration);
    const wb: XLSX.WorkBook = { Sheets: { 'telemetry': ws0, 'configuration': ws1 }, SheetNames: ['telemetry', 'configuration'] };

    wb.Props = {
      Title: filename,
      Author: "4D Monitoring",
      CreatedDate: new Date()
    }

    ws0["!cols"] = [
      { wpx: 150 },
      { wpx: 70 },
      { wpx: 70 },
      { wpx: 60 },
      { wpx: 80 },
      { wpx: 80 }
    ];

    ws0['A1'].v = 'Time';
    ws0['B1'].v = 'RAG';
    ws0['C1'].v = 'Value';
    ws0['D1'].v = 'Temp.';
    ws0['E1'].v = 'Wind Speed';
    ws0['F1'].v = 'Humidity %';

    ws1["!cols"] = [
      { wpx: 90 },
      { wpx: 50 },
      { wpx: 50 },
      { wpx: 50 },
      { wpx: 80 },
      { wpx: 80 },
      { wpx: 80 },
      { wpx: 80 }
    ];

    for (let index = 0; index < (dataForWS.length); index++) {
      const key = String(index + 2);
      if (ws0['A' + key]) {
        ws0['A' + key].t = 's';
        ws0['B' + key].t = 's';
        ws0['C' + key].t = 'n';
      }
    }

    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    this.saveExcelFile(excelBuffer, filename);
  }

  public saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    saveAs(data, fileName + this.fileExtension);
  }
}


export interface IPayload {
  json: { value: string | number, type?: string, width: number }[];
  header: { value: string }[];
  filename: string;
}

export interface ITriggerReportRow {
  a: { id: number, title: string, assetType_id: number, gatewayId: string, siteTitle: string };
  v: number;
  d: Date;
}
