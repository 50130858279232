
export class Setpoint {
    id: number;
    weekday: number;
    startsAt: string;
    endsAt: string;
    allday: boolean;
    min: number;
    max: number;
    isActive: boolean;
    red_max: number;
    red_min: number;
    amber_max: number;
    amber_min: number;

    constructor(id: number, weekday: number, startsAt: string, endsAt: string, allday: boolean, min: number, max: number, isActive, amberMin?: number, amberMax?: number, redMin?: number, redMax?: number) {
        this.weekday = weekday;
        this.startsAt = startsAt;
        this.endsAt = endsAt;
        this.allday = allday ?? false;
        this.max = max;
        this.min = min;
        this.isActive = isActive ?? false;
        this.id = id;
        this.amber_max = amberMax;
        this.amber_min = amberMin;
        this.red_max = redMax;
        this.red_min = redMin;
    }

    serialise() {
        return this;
    }
}
