import { Injectable } from '@angular/core';
import { SiteService } from './site.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { APIService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class PowerService {
  static readonly API_URL = 'https://j3e0a633qh.execute-api.eu-west-2.amazonaws.com/prod';

  constructor(private siteService: SiteService, private http: HttpClient, private apiService: APIService) { }

  async fixAsset(assetId: number): Promise<any> {
    return new Promise(async (resolve) => {
      this.http
        .get(PowerService.API_URL + '/fix', { ...this.apiService.getUAOHeaders(), params: { a: assetId } })
        .subscribe(async response => {
          resolve(response);
        });
    });
  }

}
