
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APIService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class RedisService {

	API_URL = 'https://x04hio2ccd.execute-api.eu-west-2.amazonaws.com/v1';

	constructor(private apiService: APIService, private http: HttpClient) { }

	getAsset(id: number): Promise<IRedisAsset> {
		return new Promise((resolve) => {
			const qs = `action=asset&id=${id}`;
			const url = `${this.API_URL}/?${qs}`;

			return this.http.get<any>(url, this.apiService.getUAOHeaders()).subscribe(redisAsset => {

				if (redisAsset?.firstat) {
					redisAsset.firstat = new Date(redisAsset.firstat);
				}
				if (redisAsset?.lastat) {
					redisAsset.lastat = new Date(redisAsset.lastat);
				}

				resolve(redisAsset);
			});
		});
	}
}

export interface IRedisAsset {
	id: number;
	createdat: Date;
	title: string;
	firstat: Date;
	lastat: Date;
	timestamp: Date;
	value: string;
	orgid: number;
	ostitle: string;
	ident: string;
	gatewayid: string;
	typeid: number;
}
