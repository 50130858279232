import { Injectable } from '@angular/core';
import { IDatesFromTo } from 'app/interfaces/dates-from-to';
import moment, { FromTo, Moment } from 'moment';

@Injectable({ providedIn: 'root' })
export class CalendarService {

  public DOW_NAMES: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  public DOW_NAMES_SHORT: string[] = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  public DOW_NAMES_MIN: string[] = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  public DOW_ISO_NAMES_MIN: string[] = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

  public MONTH_NAMES: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  public MONTH_NAMES_SHORT: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  constructor() { }

  calculateDatesFromDateConfig(dateConfig: IDateConfig): IDatesFromTo {
    if (!dateConfig) {
      return { from: null, to: null };
    }

    if (dateConfig.dateRange === 'custom') {
      return dateConfig.dates;
    }

    return this.calculateDatesFromRangeText(dateConfig.dateRange);
  }

  calculateDatesFromRangeText(dateRange: IDateRange): IDatesFromTo {
    let from: Moment, to: Moment;
    switch (dateRange) {
      case 'today':
        from = moment().startOf('day');
        to = moment();
        break;
      case 'thisweek':
        from = moment().startOf('isoWeek');
        to = moment();
        break;
      case 'lastweek':
        from = moment().isoWeekday(1).subtract(1, 'week').startOf('isoWeek');
        to = moment().isoWeekday(1).subtract(1, 'week').endOf('isoWeek');
        break;
      case 'thismonth':
        from = moment().startOf('month');
        to = moment();
        break;
      case 'lastmonth':
        from = moment().subtract(1, 'month').startOf('month');
        to = moment().subtract(1, 'month').endOf('month');
        break;
    }

    return { from: from.toDate(), to: to.toDate() };
  }

}

export interface IDateConfig {
  dateRange: IDateRange;
  dates?: { from: Date, to: Date };
}

export type IDateRange = 'lastweek' | 'thisweek' | 'yesterday' | 'today' | 'lastmonth' | 'thismonth' | 'custom';
