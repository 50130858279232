<article>
	<section>
		<p-tree [value]="tree" selectionMode="single" [(selection)]="selectedNodes" (onNodeSelect)="nodeSelect($event)"
			(onNodeUnselect)="nodeUnselect($event)"></p-tree>
	</section>
	<section>
		<app-schematics-detail-container [schematic]="schematic" [selectedNode]="selectedNode">
		</app-schematics-detail-container>
	</section>
</article>
