/**
 * @version 1.0.3
 */
export class Collection<T extends ICollection> {
  list: T[] = [];
  marks: number[] = [];

  add(item: T) {
    this.list.push(item);
  }

  addAll(items: Array<T>) {
    items.forEach(item => this.add(item));
  }

  get(id: any): T {
    const matches = this.list.filter(t => {
      if (t.id === id) {
        return t;
      }
    });

    if (matches.length) {
      return matches[0];
    }

    return null;
  }

  // Pass object with .id or a value
  exists(value: any): boolean {
    let id;
    if (typeof value === 'object') {
      id = value.id;
    } else {
      id = value;
    }

    return !!this.get(id);
  }

  remove(item: T) {
    this.list = this.list.filter(listitem => listitem.id !== item.id);
  }

  getIndex(item: T): number {
    for (let index = 0; index < this.list.length; index++) {
      const listItem = this.list[index];
      if (item.id === listItem.id) {
        return index;
      }
    }
    return null;
  }

  moveToIndex(item: T, toIndex: number) {
    const idx = this.getIndex(item);
    const obj = item;
    this.list.splice(idx, 1);
    this.list.splice(toIndex, 0, obj);
  }
  removeAtIndex(index: number) {
    if (this.list.length) {
      this.list.splice(index, 1);
    }
  }

  markAtIndex(index: number) {
    if (this.list.length) {
      if (this.marks.filter(mark => mark === index).length === 0) {
        this.marks.push(index);
      }
    }
  }
  isMarkedAtIndex(index: number) {
    return !!this.marks.filter(mark => mark === index).length;
  }
}

export interface ICollection {
  id: any;
}
