/**
 * Asset Collections for generic groups of assets or a specific type of collection (AQ, footfall, reporting etc)
 */
import { Asset } from './asset';
import { Site } from './site';
import { Org } from './org';
import { Building } from './building';

export class AssetCollection {
	id: number;
	title: string;
	items: AssetCollectionItem[];
	typeId: number;
	site: Site;
	building: Building;
	org: Org;

	constructor(data?: any) {
		if (!data) {
			this.items = [];

			return;
		}

		this.typeId = data.collection.type_id;
		this.id = data.collection.id;
		this.title = data.collection.title;
		this.site = new Site({
			id: data.collection.siteId,
			title: data.collection.siteTitle,
			orgId: data.collection.orgId,
			orgTitle: data.collection.orgTitle
		});

		this.building = new Building({
			id: data.collection.buildingId,
			title: data.collection.buildingTitle
		});

		this.org = new Org({ id: data.collection.orgId, title: data.collection.orgTitle });
		this.items = data.items.map(item => new AssetCollectionItem(item));
	}

	serialise() {
		let payload: any =
		{
			id: this.id,
			title: this.title,
			site_id: this.site.id,
			org_id: this.org.id,
			type_id: this.typeId,
			items: this.items.map(item => item.serialise())
		};
		if (this.building) {
			payload.building_id = this.building.id;
		} else {
			payload.building_id = null;
		}

		return payload;
	}
}

export class SimpleAssetCollection {
	id: number;
	title: string;
	assets: Asset[];
	selected: boolean;

	constructor(data?: ISimpleAssetCollectionAPI) {
		if (!data) {
			return;
		}

		this.id = data.id;
		this.title = data.title;
		this.assets = data.assets.map(a => new Asset(a));
	}
}

export class AssetCollectionItem {
	asset: Asset;
	title: string;

	constructor(data?: any) {
		this.title = data.collectionTitle;
		this.asset = new Asset(data);
	}
	serialise() {
		return {
			asset_id: this.asset.id,
			title: this.title
		};
	}
}



export interface ISimpleAssetCollectionAPI {
	id: number;
	title: string;
	assets: [{ id: number, title: string, assetType_id: number }]
}
