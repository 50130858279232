import { Component, ElementRef, Input, OnInit, signal } from '@angular/core';
import { IgetHourlyForAssetFromRangeResponse } from 'app/shared/occupancy.service';

@Component({
  selector: 'app-setpoint-query-occ-plan',
  templateUrl: './setpoint-query-occ-plan.component.html',
  styleUrls: ['./setpoint-query-occ-plan.component.css']
})
export class SetpointQueryOccPlanComponent implements OnInit {

  width = signal<number>(0);

  @Input()
  stats: IgetHourlyForAssetFromRangeResponse;

  constructor(private el: ElementRef) {

  }

  ngOnInit(): void {
    const { offsetHeight, offsetWidth } = this.el.nativeElement;

    console.log(`OFFSETS`, offsetHeight, offsetWidth);
    this.width.set(offsetWidth);
  }
}
