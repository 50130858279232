export class CmsItemBlock {
    type: string;
    id: number;
    body: string;
    reference: string;
    order: number;
    align: string;
    constructor(data?:any) {
        if (data) {
            this.type = data.type;
            this.id = data.id;
            this.body = data.body;
            this.reference = data.reference;
            this.order = data.order;
            this.align = data.align;
        } else {
            this.align = 'left';
        }
    }
}

export class BuildCmsItemBlock extends CmsItemBlock {
    internalId: string = Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
    constructor(data?:any){
        super(data);
    }
}