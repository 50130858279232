import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Session } from '../classes/session';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private _session: BehaviorSubject<Session> = new BehaviorSubject(new Session());
  public session: Observable<Session> = this._session.asObservable();

  constructor() { }

  setSession(session: Session = null) {
    console.log('SET SESSION', session);
    this._session.next(session);
  }

  getSessionValue(): Session | null {
    return this._session.value;
  }

  getToken(): string {
    const session = this.getSessionValue();

    return session.token ? btoa(session.identityId + '|' + session.token) : '';
  }
}
