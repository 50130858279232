<article>
  <div class="w-75">
    <div class="tabview-list">
      <div [class.active]="_tab() === 'details'" (click)="_tab.set('details')"><i class="mdi mdi-view-list"></i> Details</div>
      <div [class.active]="_tab() === 'custom'" (click)="_tab.set('custom')">
        <i class="mdi mdi-view-list"></i> Custom fields <app-chip size="small" [noColour]="true" [canClick]="false" [label]="asset?.settings.length" />
      </div>
      <div></div>
    </div>

    @if (_tab() === 'details') {
      <section>
        <div class="field">
          <span>
            ID
            <i
              pTooltip="Mark this asset as a favourite, for quick access"
              class="mdi"
              [ngClass]="{ 'mdi-star-outline': !isFav, 'mdi-star': isFav }"
              [class.mdi-spin-x2]="isChangingFavState"
              (click)="clickFav()"></i
          ></span>
          {{ asset.id }}
        </div>
        <div class="field"><span>Type</span> {{ asset.assetTypeTitle }}</div>

        <div class="field">
          <span
            title="Created {{ asset.createdAt | amDateFormat: 'DD MMM YYYY' }}"
            tooltipPosition="top"
            [ngClass]="{ 'no-recent-updates': noUpdateFor24Hours }">
            Updated
          </span>
          <span [ngClass]="{ 'no-recent-updates': noUpdateFor24Hours }">
            {{ asset.updatedAt | amDateFormat: dateFormat + ' HH:mm:ss' }}, {{ asset.updatedAt | amTimeAgo }}.
          </span>
        </div>

        <div class="field hover flex" (click)="isEditingYourRef.set(true)">
          <div><span pTooltip="Your reference, click to edit" tooltipPosition="top">Your Ref</span> {{ asset.ref }}</div>
          @if (can.editAsset) {
            <app-button-asset-admin [assetId]="asset.id" />
          }
        </div>
      </section>
    }
    @if (_tab() === 'custom') {
      <section>
        <div>
          <table class="table table-sm" aria-label="Settings">
            <thead>
              <tr>
                <th>Name</th>
                <th class="text-right">Value</th>
              </tr>
            </thead>
            <tbody>
              @for (setting of asset.settings; track setting; let i = $index) {
                <tr>
                  <td>
                    @if (!setting.id) {
                      <span>
                        <input class="fourd" placeholder="e.g. floor" [(ngModel)]="setting.setting" autofocus />
                      </span>
                    }

                    <ng-template #chev [ngIf]="setting.id">
                      <div (click)="settingMenuShown(i, $event)" style="position: relative">
                        <span> {{ setting.setting }}</span>
                        <span class="mdi mdi-chevron-down"></span>
                        <div class="menu" [class.active]="settingIndex === i">
                          <div *ngIf="api" (click)="addToFilterClick($event)"><i class="fa fa-code"></i> Return in API</div>
                          <div (click)="deletePropertyClick($event)"><i class="fa fa-trash"></i> Delete</div>
                        </div>
                      </div>
                    </ng-template>
                  </td>
                  <td class="text-right">
                    @if (setting.id) {
                      <span>{{ setting.value }}</span>
                    } @else {
                      <span><input class="fourd" [(ngModel)]="setting.value" /></span>
                    }
                  </td>
                </tr>
              }
              <tr>
                <td colspan="2" class="text-right">
                  @if ((!isAddingProperty || isDirty) && !isSaving) {
                    <app-button size="small" label="Add property" (click)="addProperty()"></app-button>
                  }
                  &nbsp;
                  @if ((isAddingProperty || isDirty) && !isSaving) {
                    <app-button size="small" label="Save" (click)="saveProperty()"></app-button>
                  }
                  &nbsp;
                  <span *ngIf="isSaving">Saving...</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    }
  </div>
  <div class="w-25">
    <div class="value">
      <div class="">
        <div class="value-container">
          <h4>Current value</h4>
          <!-- text and number values -->
          <div
            class="latest"
            *ngIf="asset.value !== undefined && asset.assetType_id !== 43 && asset.assetType_id !== 44"
            tooltipPosition="bottom"
            pTooltip="{{ asset.value }}">
            {{ asset.displayValue() }}
          </div>
          <!-- images -->
          <p *ngIf="asset.value !== undefined && (asset.assetType_id === 43 || asset.assetType_id === 44)" pTooltip="{{ asset.value }}">
            <p-image
              src="https://s3.eu-west-2.amazonaws.com/4d-assets/{{ asset.id }}/images/{{ asset.value }}"
              alt="Image"
              width="450"
              [preview]="true"></p-image>
          </p>
          <p *ngIf="asset.value === undefined"><i class="fa fa-spin fa-fw fa-refresh"></i></p>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100">
    @defer {
      <app-setpoint-query [asset]="asset" [custom]="setpointQueryDateRange" [alwaysUseCustom]="alwaysUseCustom"></app-setpoint-query>
    } @placeholder (minimum 1000) {
      <div class="placeholder">Please wait...</div>
    }
  </div>
</article>
