/**
 * key at 4dmanage@
 */
import { environment } from '../../../../environments/environment';
import { Component, OnInit, AfterContentInit, Input } from '@angular/core';
import { Site } from 'app/classes/site';
import mapboxgl from 'mapbox-gl';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements AfterContentInit {

  mapId: string = crypto.randomUUID().replaceAll('-', '');
  map: mapboxgl.Map;
  latlng: mapboxgl.LngLatLike = { lat: 52.9, lng: -1.18 };
  _markers: mapboxgl.Marker[] = [];

  @Input()
  public set sites(sites: Site[]) {
    this._markers = [];
    for (const site of sites) {
      const marker = new mapboxgl.Marker();
      if (!site.address.addressLat || !site.address.addressLong) {
        continue;
      }
      marker.setLngLat({ lat: +site.address.addressLat, lng: +site.address.addressLong });
      this._markers.push(marker);
    }
  }

  @Input()
  style = 'mapbox://styles/mapbox/streets-v12';

  @Input()
  public set markers(markers: mapboxgl.Marker[]) {
    if (!markers || markers.length === 0) {
      return;
    }
    this._markers = markers;
  }

  @Input()
  dragPan = true;

  @Input()
  dragRotate = true;

  @Input()
  interactive = true;

  @Input()
  doubleClickZoom = true;

  @Input()
  zoom = 5.8;

  @Input()
  hasControls = true;

  @Input()
  public set isStatic(v: boolean) {
    if (typeof v !== "boolean") {
      return;
    }

    if (v) {
      this.dragPan = false;
      this.dragRotate = false;
      this.interactive = false;
      this.doubleClickZoom = false;
      this.hasControls = false;
    }

  }

  _center: mapboxgl.LngLatLike;

  @Input()
  public set center(latlng: mapboxgl.LngLatLike) {
    if (!latlng) {
      return;
    }
    this._center = latlng;
  }

  constructor() { }
  ngAfterContentInit(): void {

    setTimeout(() => {
      (mapboxgl as any).accessToken = environment.mapbox.accessToken;

      this.map = new mapboxgl.Map({
        container: this.mapId,
        style: this.style, // style URL
        zoom: this.zoom,
        center: this._center || this.latlng,
        dragPan: this.dragPan,
        dragRotate: this.dragRotate,
        doubleClickZoom: this.doubleClickZoom,
        interactive: this.interactive
      });
      if (this.hasControls) {
        this.map.addControl(new mapboxgl.NavigationControl());
      }

      this.applyMarkers();
    }, 150);

  }

  applyMarkers() {
    for (const marker of this._markers) {
      marker.addTo(this.map);
    }
  }
}
