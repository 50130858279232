<article>
  <header>
    <app-dropdown
      [dimensions]="{ height: 160 }"
      [hasBorder]="false"
      [autoFit]="true"
      placeholder="Select a year"
      [list]="years"
      [value]="year"
      (onSelected)="yearSelected($event)" />
  </header>
  <main>
    <section>
      @for (monthItem of months; track $index) {
        <div [class.selected]="month() === monthItem && year === useValue().getFullYear().toString()" (click)="monthClick(monthItem)">{{ monthItem }}</div>
      }
    </section>
  </main>
  <main></main>
</article>
