<article>

	<section *ngIf="occupancyStats">
		<table class="table table-sm">
			<thead>
				<tr>
					<th>Asset</th>
					<th>Mon</th>
					<th>Tue</th>
					<th>Wed</th>
					<th>Thu</th>
					<th>Fri</th>
					<th>Sat</th>
					<th>Sun</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let assetId of occupancyStats.assetIds">
					<td>{{occupancyStats.assets[assetId].title}}</td>
					<td *ngFor="let day of occupancyStats.assets[assetId].dow;let i = index"
						[class.rating-e]="occupancyStats.assets[assetId].perc[i] !== null && occupancyStats.assets[assetId].perc[i] < 20"
						[class.rating-d]="occupancyStats.assets[assetId].perc[i] >= 20 && occupancyStats.assets[assetId].perc[i] < 40"
						[class.rating-c]="occupancyStats.assets[assetId].perc[i] >= 40 && occupancyStats.assets[assetId].perc[i] < 60"
						[class.rating-b]="occupancyStats.assets[assetId].perc[i] >= 60  && occupancyStats.assets[assetId].perc[i] < 80"
						[class.rating-a]="occupancyStats.assets[assetId].perc[i] >= 80">
						<span [pTooltip]="day + ' minutes'">{{occupancyStats.assets[assetId].perc[i]}}</span>
					</td>
				</tr>
			</tbody>
		</table>
	</section>

	<div class="rating">
		<app-rating-ae [width]="240"></app-rating-ae>
	</div>
</article>
