import { Component, Input, OnInit } from '@angular/core';
import { Schematic } from 'app/classes/schematic/schematic';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-schematics-detail-container',
  templateUrl: './schematics-detail-container.component.html',
  styleUrls: ['./schematics-detail-container.component.css']
})
export class SchematicsDetailContainerComponent implements OnInit {

  @Input()
  schematic: Schematic;

  _selectedNode: TreeNode;

  schematicCategory: 'equipment' | 'asset' | 'room' | 'floor' | 'schematic';

  co = 0;

  @Input()
  public set selectedNode(v: TreeNode) {
    console.log(v);
    this._selectedNode = v;
    this.co++;
    this.processNode();
  }
  public get selectedNode(): TreeNode {
    return this._selectedNode;
  }

  constructor() { }

  ngOnInit(): void {
  }


  processNode() {
    if (!this._selectedNode) {
      return;
    }

    const parts = this.selectedNode.data.split('-');

    if (parts.length === 2) {
      this.schematicCategory = 'schematic';

      return;
    }

    this.schematicCategory = parts[1];
  }
}
