import { User } from './user';

export class ServiceForTenants {
	id: number;
	title: string;
	createdBy: User;
	createdAt: Date;
	notes: string;

	constructor(data?: { id: number, title: string, notes: string }) {
		if (!data) {
			return;
		}

		this.id = data.id;
		this.title = data.title;
		this.notes = data.notes;
	};

	serialise() {
		return {
			id: this.id,
			title: this.title,
			notes: this.notes
		};
	}
}
