export class Session {
	token: string;
	passwordToken: string;
	identityId: string;
	timestamp: any;

	constructor(data?: ISession) {
		this.token = data?.token;
		this.identityId = data?.identityId;
		this.timestamp = data?.timestamp;
		this.passwordToken = data?.passwordToken;
	}
}

export interface ISession {
	token: string;
	passwordToken: string;
	identityId: string;
	timestamp: any;
}
