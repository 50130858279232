import { Component, Input, signal } from '@angular/core';
import { ICollection, IGetHourlyForAssetFromRangeAsset, IServicePercentage, ITenantPercentage, IgetHourlyForAssetFromRangeResponse, OccupancyService } from 'app/shared/occupancy.service';


@Component({
  selector: 'app-setpoint-query-occ-tenserv',
  templateUrl: './setpoint-query-occ-tenserv.component.html',
  styleUrls: ['./setpoint-query-occ-tenserv.component.css']
})
export class SetpointQueryOccTenservComponent {
  dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  asset: IGetHourlyForAssetFromRangeAsset;
  collections: ICollection[] = [];

  tenantPercs = signal<ITenantPercentage[]>(null);
  servicePercs = signal<IServicePercentage[]>(null);

  _stats = signal<IgetHourlyForAssetFromRangeResponse>(null);

  @Input()
  public set stats(stats: IgetHourlyForAssetFromRangeResponse) {
    // There will only be one asset id
    this._stats.set(stats);
    this.asset = stats.assets[Object.keys(stats.assets)[0]]
    // Get the collection(s)
    this.collections = stats.collections.filter(c => stats.occupationCollectionAssets.some(a => a.collection_id === c.i));
    // Set the collection(s) days of hours c.i )
    console.log(`collections`, this.collections);


    const tenantId = this.asset.tenant_id;
    const serviceId = this.asset.service_id;

    if (tenantId) {
      this.occupancyService.getTenantsPercentages(stats.extra.dates, tenantId).then(percs => {
        percs.forEach(p => {
          p.forDate = new Date(p.forDate + ' 00:00:00');
        });
        this.tenantPercs.set(percs);
      });
      this.occupancyService.getServicePercentages(stats.extra.dates, serviceId).then(percs => {
        percs.forEach(p => {
          p.forDate = new Date(p.forDate + ' 00:00:00');
        });
        this.servicePercs.set(percs);
      });
    }
  }

  constructor(private occupancyService: OccupancyService) {


  }
}
