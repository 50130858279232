import { Asset } from "../asset";

export class ReviewAsset extends Asset {
    reviewAssetsId: number;
    telemetry = {
        min: null,
        max: null,
        data: []
    };

    constructor(data?: any) {
        super(data);

        if (data) {
            this.reviewAssetsId = data.reviewAssetsId;
        }
    }
}