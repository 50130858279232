import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { AssetCollection } from '../classes/asset-collection';
import { AssetService } from './asset.service';

@Injectable({
	providedIn: 'root'
})
export class AssetCollectionService {
	static readonly API_URL = 'https://09wuka10gg.execute-api.eu-west-2.amazonaws.com/4d/';

	constructor(private apiService: APIService, private http: HttpClient) { }

	getAllCollections(collectionType?: number) {
		return new Promise((resolve) => {
			this
				.get(`a=get_collections}`)
				.then(r => {
					resolve(r);
				});
		});
	}
	getAllCollectionsForType(collectionType: number) {
		return new Promise((resolve) => {
			this
				.get(`a=get_collections&id=${collectionType}`)
				.then(r => {
					resolve(r);
				});
		});
	}

	getCollectionTypes(): Promise<ICollectionType[]> {
		return Promise.resolve([
			{ id: 1, title: 'Clean Room' },
			{ id: 2, title: 'Gowning room' },
			{ id: 3, title: 'Office air quality' },
			{ id: 4, title: 'Footfall' },
			{ id: 5, title: 'Reporting' },
			{ id: 6, title: 'Equipment' },
		]);
	}

	postCollection(item: AssetCollection): Promise<any> {
		return new Promise((resolve) => {
			this
				.post(`a=post_collections`, item.serialise())
				.then(r => {
					resolve(r);
				});
		});
	}

	get(qs: string = ''): Promise<any> {
		return new Promise((resolve) => {
			const url = `${AssetCollectionService.API_URL}?${qs}`;
			return this.http
				.get<any>(url, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

	post(body: any, qs: string = ''): Promise<any> {
		return new Promise((resolve) => {
			const url = `${AssetCollectionService.API_URL}?${qs}`;
			return this.http
				.post<any>(url, body, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

}

export interface ICollectionType {
	id: number;
	title: string;
}
