import { Tenant } from '../classes/tenant';
import { ServiceForTenants } from '../classes/service-for-tenant';

import moment from 'moment-timezone';
export class OccupancySiteMonthlyStats {
	assets: {
		[assetId: number]: {
			day: number[], //[mins of duration,...]
			dow: number[], // [0-6,0-6,...]
			perc?: number[],
			rating?: OccupancyRatingType[],
			gateway_id: string,
			title: string,
			a_id: number,
			coll_id: number,
			tenant: Tenant,
			service: ServiceForTenants,
			planAsset: any,
			shape: any,
			tenantTitle: string,
			serviceTitle: string,
			roomTitle: string,
			floor: number

		}
	};
	assetArray: {
		day: number[],
		dow: number[],
		perc?: number[],
		rating?: OccupancyRatingType[],
		gateway_id: string,
		title: string,
		a_id: number,
		coll_id: number,
		tenant: Tenant,
		service: ServiceForTenants,
		planAsset: any,
		shape: any,
		tenantTitle: string,
		serviceTitle: string,
		roomTitle: string,
		floor: number
	}[] = [];
	master: { from: Date, to: Date, days: number };
	collections: { [collectionId: number]: { i: number, org_id: number, t: string, days: { coll_id: number, d: number, days: number[], endHour: number, startHour: number, startTime: string, endTime: string }[] } };
	values: { d: number, a_id: number, forDate: Date }[];
	assetIds: number[];
	dows: { date: Date, dow: number, day: number }[];
	dates: { from: Date, to: Date, daysInMonth: number };
	grandTotals: number[];
	shapes: { id: number, floorplanId: number, shapeTitle: string, settingTitle: string, category: string, coll_id: number, tenantId: number, serviceId: number }[];
	tenants: Tenant[];
	services: ServiceForTenants[];
	plans: { id: number, title: string, floor: number, planKey: string }[];

	// Can only show totals when no filtering currently
	showTotals: boolean;

	constructor(data?: any) {
		if (!data) {
			this.assets = [];
			this.collections = [];
			this.values = [];
			this.dows = [];

			return;
		}
		this.master = { from: new Date(data.master.from), to: new Date(data.master.to), days: data.master.days };
		this.assets = data.assets;
		this.collections = data.collections;
		this.values = data.values;
		this.dows = data.dows;
		this.dates = { from: new Date(data.dates.from + ' 00:00:00'), to: new Date(data.dates.to + ' 23:59:59'), daysInMonth: data.dates.daysInMonth };
		this.assetIds = Object.keys(data.assets).map(a => +a);
		this.grandTotals = new Array(this.master.days - 1).fill(0);
		this.shapes = data.shapes.map(shape => { return { id: shape.id, floorplanId: shape.floorplan_id, shapeTitle: shape.shape_title, settingTitle: shape.setting_title, coll_id: shape.occ_collection_id, category: shape.category, tenantId: shape.tenant_id, serviceId: shape.service_id } });
		this.tenants = data.tenants.map(t => new Tenant(t));
		this.services = data.services.map(s => new ServiceForTenants(s));
		// calc percentages for days
		this.plans = data.plans;

		this.assetIds.forEach(id => {
			const asset = this.assets[id];
			// Don't need motion in the title
			asset.title = asset.title.replace(' motion', '');
			this.assetArray.push(asset);

			const floorplan = this.plans.find(p => p.id === asset.planAsset.floorplan_id);

			asset.perc = [];//new Array(this.dates.daysInMonth).fill(0);
			asset.rating = [];//new Array(this.dates.daysInMonth);
			asset.shape = this.shapes.find(s => s.id === asset.planAsset.shape_id);
			asset.floor = floorplan?.floor || 0;
			if (asset.shape) {
				asset.tenant = this.tenants.find(t => t.id === asset.shape.tenantId) || new Tenant();
				asset.service = this.services.find(s => s.id === asset.shape.serviceId) || new ServiceForTenants();
				asset.tenantTitle = asset.tenant?.title;
				asset.serviceTitle = asset.service?.title;
				asset.roomTitle = asset.shape?.shapeTitle;
			}
			let counter = 0;
			for (let index = 0; index < this.master.days; index++) {
				const dt = +new Date(this.dows[index].date);
				if (dt >= +this.master.from && dt <= +this.master.to) {
					this.grandTotals[counter] += asset.day[index];
					asset.perc.push(0);
					asset.rating.push('');

					const duration = asset.day[index];

					const dow = this.dows[index].dow;
					const collection = this.collections[asset.coll_id].days.find(day => day.days[dow]);
					if (duration) {
						asset.perc[counter] = duration && collection?.d ? +(duration / (collection.d / 100)).toFixed(0) : 0;
						if (asset.perc[counter] > 100) {
							asset.perc[counter] = 100;
						}
						this.grandTotals[index] += asset.perc[index];
					} else if (!collection) {
						//asset.perc[counter] = null;
					} else {
						asset.perc[counter] = 0;
					}
					if (collection) {
						asset.rating[counter] = this.getRating(asset.perc[counter]);
					} else {
						asset.rating[counter] = null;
					}
					counter++;
				}
			}
			if (asset.rating.length !== this.master.days || asset.perc.length !== this.master.days) {
				console.error('MISMATCH');
			}
			console.log(asset.perc.length, asset.rating.length, this.grandTotals.length, this.master.days);
		});

		this.sortBy('floor');

		this.grandTotals = this.grandTotals.map(total => +(total / this.assetIds.length).toFixed(0));
	}

	sortBy(column: 'floor') {
		switch (column) {
			case 'floor':
				this.assetIds = this.assetIds.sort((a, b) => this.assets[a].floor > this.assets[b].floor ? 1 : -1);
				break;
		}
	}

	getRating(value: number): OccupancyRatingType {
		if (value === null) {
			return '';
		} else if (value < 20) {
			return 'e';
		} else if (value <= 40) {
			return 'd';
		} else if (value <= 60) {
			return 'c';
		} else if (value <= 80) {
			return 'b';
		} else {
			return 'a';
		}
	}
}

export type OccupancyRatingType = '' | 'a' | 'b' | 'c' | 'd' | 'e';
