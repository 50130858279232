<article>
	<app-toolbar>
		<div class="left flex">
			{{redis()?.firstat | date:'MMMM YYYY'}} - {{redis()?.lastat | date:'MMMM YYYY'}} available.
		</div>
		<div class="right">
			<!--<app-button-dates [value]="dates()" (onSelect)="dateChange($event)"></app-button-dates>-->

			<app-button [label]="wantsPreviousYear() ? 'Compare previous year' : 'Only requested year'" icon="mdi mdi-3d-rotate-variant" (click)="toggleComparePrevioousYear()" />
		</div>
	</app-toolbar>
	<main>
		<header>

			<section class="flex">
				<div class="flex-1 " pTooltip="Selected year" tooltipPosition="left">
					<div>
						@if(granularity() === 'month') {
						{{dates().dates.from | date:'YYYY'}}
						} @else {
						{{dates().dates.from | date:'MMMM YYYY'}}
						}

					</div>
					<div>{{stats().total}}</div>
				</div>
				<div class="flex-1 " pTooltip="Previous year" tooltipPosition="left">
					<div>Previous year</div>
					<div>{{stats().previous}}</div>
				</div>
				<div class="flex-1" [ngClass]="{'worse': stats().diff > 0, 'better': stats().diff < 0}">
					<div>Difference</div>
					<div>{{stats().diff}} <span>{{stats().perc}}%</span></div>
				</div>
			</section>

		</header>
		<div class="chart">
			<canvas id="chart-1" (onSelect)="datesSelected($event)"></canvas>
		</div>
	</main>
</article>
