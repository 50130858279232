import { Gateway } from "./gateway";
import { InitialState } from "./initialstate";

export class GatewayIS extends Gateway {

	initialstate: InitialState;

	constructor(data?: any) {
		super(data);
		this.initialstate = new InitialState({ embedLink: data.isEmbedLink, version: data.isVersion });
	}

}