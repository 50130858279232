import { User } from './user';
import { ServiceForTenants } from "./service-for-tenant";

export class Tenant {
	id: number;
	title: string;
	createdBy: User;
	createdAt: Date;
	notes: string;
	shapeCount: number;
	serviceCollection: TenantServiceCollectionItem[] = [];
	floorplans: any[] = [];
	assets: any[] = [];

	constructor(data: { id?: number, title: string, notes: string, shapeCount?: number, floorplans?: any[], assets?: any[] } = null) {
		if (!data) {
			return;
		}

		this.id = data.id;
		this.title = data.title;
		this.notes = data.notes;
		this.shapeCount = data.shapeCount;
		this.floorplans = data.floorplans || [];
		this.assets = data.assets || [];
	};

	addServiceForTenants(serviceForTenants: ServiceForTenants) {
		if (this.serviceCollection.some(a => a.serviceForTenant.id === serviceForTenants.id)) {
			// No duplicates
			return;
		}

		const item = new TenantServiceCollectionItem();

		item.serviceForTenant = serviceForTenants;
		this.serviceCollection.push(item);
	}

	serialise() {
		return {
			id: this.id,
			title: this.title,
			notes: this.notes,
			services: this.serviceCollection.map(s => { return { id: s.id, servceId: s.serviceForTenant.id } })
		};
	}
}


export class TenantServiceCollectionItem {
	id: number;
	serviceForTenant: ServiceForTenants;

	constructor() {
	}
}
