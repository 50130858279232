/**
 * @version 1.0
 */

export class InitialState {
	static readonly IFRAME_PREFIX = [
		,
		`https://app.initialstate.com/embed/#/tiles/`,
		`https://iot.app.initialstate.com/embed/#/tiles/`
	];
	bucketKey: string;
	prefix: string;
	createdAt: Date;
	isKey: string;

	_embedLink: string;
	public get embedLink(): string {
		return this._embedLink;
	}
	public set embedLink(v: string) {
		if (v && v.indexOf('iframe')) {
			const regex = /#\/tiles\/(.*)"/gm;
			let m;
			while ((m = regex.exec(v)) !== null) {
				if (m.index === regex.lastIndex) {
					regex.lastIndex++;
				}
				m.forEach((match, groupIndex) => {
					console.log(`Found match, group ${groupIndex}: ${match}`);
					if (groupIndex === 1) {

						if (v.indexOf('iot.app') > 0) {
							this._version = 2;
						} else {
							this._version = 1;
						}
						v = match;
					}
				});
			}
		}
		this._embedLink = v;
		this.updateIframeSrc();
	}

	_version: number;
	public get version(): number {
		return this._version;
	}
	public set version(v: number) {
		this._version = v;
		this.updateIframeSrc();
	}

	iframeSrc: string;

	constructor({ bucketKey = null, prefix = '4d', createdAt = null, isKey = null, embedLink = null, version = null }) {
		this.bucketKey = bucketKey;
		this.prefix = prefix;
		this.createdAt = createdAt ? new Date(createdAt) : null;
		this.isKey = isKey;
		this.embedLink = embedLink;
		this.version = version;
		this.updateIframeSrc();
	}

	updateIframeSrc() {
		if (!this.embedLink) {
			this.iframeSrc = null;
		} else {
			this.iframeSrc = InitialState.IFRAME_PREFIX[this.version] + this.embedLink;
		}
	}

	serialise() {
		return {
			bucketKey: this.bucketKey,
			prefix: this.prefix,
			isKey: this.isKey,
			embedLink: this.embedLink,
			version: this.version
		}
	}
}
