import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit, NgZone, Output, EventEmitter, signal } from '@angular/core';
import { Asset, AssetType } from 'app/classes/asset';
import { APIService } from 'app/shared/api.service';
import { fromEvent } from 'rxjs';
import { Telemetry } from 'app/classes/telemetry';
import { AssetService } from 'app/shared/asset.service';
import { D3ChartService, ITelemetryWeather } from 'app/shared/d3chart.service';
import { ChartPin } from 'app/classes/chart-pin';
import { Purpose } from 'app/classes/purpose';
import { LimitLine } from 'app/setpoints/setpoint-query/setpoint-query.component';

import * as d3 from 'd3';
import moment from 'moment';
import { DateService } from 'app/shared/date.service';

@Component({
  selector: 'app-d3-chart',
  templateUrl: './d3-chart.component.html',
  styleUrls: ['./d3-chart.component.css']
})
export class D3ChartComponent implements OnInit, AfterViewInit {

  readonly D3_CHART_PADDING = 12;
  readonly MARGIN_WEATHER_RIGHT = 12;
  @Input()
  assetId: number;

  _asset: Asset;
  @Input()
  public get asset(): Asset {
    return this._asset;
  }
  public set asset(v: Asset) {
    this._asset = v;
    this.assetPurpose = v.purpose;
    this.assetType = this.assetService.getAssetTypes().find(at => at.id === v.assetType_id);
  }

  _telemetry: Telemetry[];
  @Input()
  public get telemetry(): Telemetry[] {
    return this._telemetry;
  }

  public set telemetry(v: Telemetry[]) {
    this._telemetry = v || [];
    if (!v) {
      console.log('NO TELEMETRY');
    }
  }

  _annotations: any[];
  @Input()
  public get annotations(): any[] {
    return this._annotations;
  }

  assetType: AssetType;
  assetPurpose: Purpose;

  public set annotations(v: any[]) {
    this._annotations = v;
    if (v) {
      if (this.svg) {
        this.processAnnotations();
      } else {
        // svg has not rendered yet, give it a second
        setTimeout(() => {
          this.processAnnotations();
        }, 800);
      }
    }
  }

  @Input()
  pins: ChartPin[];

  @Output()
  dimensionsChanged = new EventEmitter<DOMRect>();

  @Output()
  chartCompletedMinMax = new EventEmitter<IChartCompletedMinMax>();

  dataHourly: any[];
  dataTrue: any[];
  data: any[];
  originalData: any[];

  PIN_RADIUS: 10;

  svg: any;
  g: any;

  @Input()
  width: number;

  @Input()
  height = 400;

  @Input()
  setpoints: any[];

  @Input()
  dateFrom: Date;

  @Input()
  dateTo: Date;

  @Input()
  limitLines: LimitLine[];

  @Input()
  showLegend = true;

  @Input()
  showXLegend = true;

  @Input()
  xLegendTicks = null;

  @Input()
  legendTitles: { x: string, y: string, x2?: string };

  @Input()
  minMaxFromData = false;

  @Input()
  canAnnotate = true;

  // The chart is zoomable (only when pure telemetry is passed)
  @Input()
  canZoom = true;

  // The user needs to click on the chart to interact
  @Input()
  hasToClickToInteract = false

  @Input()
  showMinMax = true;

  @Input()
  weather: ITelemetryWeather[];

  // Trap first click
  hasClicked = false;

  // Telemetry dates
  telemetryFrom: Date;
  telemetryTo: Date;

  @Input()
  margin: { top: number, right: number, bottom: number, left: number } = { top: 20, right: 30, bottom: 20, left: 40 };

  @Input()
  penWidth: number;

  @Input()
  blocks: IGraphBlock[];

  history: any[] = [];

  hticks = 60;

  hostElement;
  x;
  y;
  yWeather;
  xDomain;
  yDomain;
  focus: any;

  /*
   * 1 = week
   * 2 = month
   * 3 = 3 months // Hourly
   * 4 = 6 months // Hourly
   * 5 = 1 year // Day
   */
  previousZoom = 3;
  zoom = 3;

  dataAccuracy: 'true' | 'hourly' | 'day' = 'hourly';
  dateSelected: moment.Moment;
  showLabel = 1;

  item: { telemetry: Telemetry, pin?: ChartPin, annotation?: any };

  range: any[];
  mouseX: number;
  mouseY: number;
  pause: boolean;
  // free - nothing selected , range - start selected (itemFrom), ranged (itemTo/ItemFrom selected)
  cursorMode: 'free' | 'range' | 'ranged' = 'free';
  ragColours = { red: 'red', amber: 'orange', green: 'green' };
  inputMode: 'annotate' = null;
  inputText: string;

  isShowingPanel = signal<boolean>(false);
  isWorking: boolean = true;
  isShowingAssetDashboard = signal<boolean>(false);
  annotation: any = { text: '', users: [] };
  weatherMax = 30;
  weatherMin = 0;
  // The min max of the chart
  max: number;
  min: number;
  // The min max of the telemetry
  dataMax: number;
  dataMin: number;

  // Organisation users for annotations
  orgUsers: any[];

  isAdmin: boolean;
  showAdminData: boolean;

  hasWeather: boolean;

  @ViewChild('info') infoBox: ElementRef;

  @ViewChild('annotationText') annotationText: ElementRef;

  constructor(private apiService: APIService, private d3Service: D3ChartService, private assetService: AssetService, private elRef: ElementRef, private ngZone: NgZone) {
    this.hostElement = this.elRef.nativeElement;
    this.isAdmin = this.apiService.isAdmin()
  }

  ngOnInit(): void {

    const self = this;
    this.isWorking = true;
    if (!this.width) {
      this.width = (this.hostElement.parentNode.getBoundingClientRect().width - this.D3_CHART_PADDING);
    }
    this.log(`D3_CHART_WIDTH`, this.width);
    this.dimensionsChanged.emit(this.hostElement.parentNode.getBoundingClientRect());

    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.hostElement, 'mousemove')
        .subscribe(e => {
          if (this.pause && this.cursorMode === 'free') {
            return;
          }
          let ev: any = e;
          if (ev.target.localName === 'rect') {
            this.mouseX = ev.offsetX + 16;
            this.mouseY = ev.offsetY;
            const rect = self.hostElement.getBoundingClientRect();
            if (this.mouseX > (rect.width / 2)) {
              this.mouseX = ev.offsetX - 212;
            }
            if (this.mouseY > this.height - 160) {
              this.mouseY = this.height - 160;
            }
          }
        });
    });

    if (this.telemetry) {
      if (!this.weather) {

        this.telemetry
          .filter(row => row.w)
          .map(row => {
            if (row.w.t !== null && typeof row.w.t !== "undefined") {
              if (row.w.t < this.weatherMin) {
                this.weatherMin = row.w.t;
              }
              if (row.w.t > this.weatherMax) {
                this.weatherMax = row.w.t;
              }
            }
            return { t: row.w.t, d: row.w.d, desc: row.w.i };
          });
      } else {
        this.weather.forEach(w => {
          if (w.t < this.weatherMin) {
            this.weatherMin = w.t;
          }
          if (w.t > this.weatherMax) {
            this.weatherMax = w.t;
          }
        });
      }

      this.hasWeather = this.weather?.length > 0;

      if (this.hasWeather && this.legendTitles?.x) {
        this.margin.right += 10;
        this.margin.left += 10;
      }
      this.buildFromTelemetry();
    }
  }

  clickAdmin() {
    this.showAdminData = !this.showAdminData;
  }

  ngAfterViewInit() {
    // this.log(this.hostElement);
  }

  log(...txt: any) {
    // console.log(...txt);
  }


  private removeExistingChartFromParent() {
    d3.select(this.hostElement).select('svg').remove();
  }


  private setChartDimensions() {
    if (this.width > 0) {
      let viewBoxHeight = this.height
      let viewBoxWidth = this.width
      this.svg = d3.select(this.hostElement)
        .append('svg')
        .attr('width', this.width)
        .attr('height', this.height)
        .style('user-select', 'none')
        .attr('viewBox', '0 0 ' + viewBoxWidth + ' ' + viewBoxHeight);
      if (this.hasToClickToInteract && !this.hasClicked) {
        this.svg.attr('class', 'can-click');
      }
    }
  }

  private addGraphicsElement() {
    this.g = this.svg.append("g")
      .attr("transform", "translate(0,0)");
  }

  // User wants to start a range
  nextPointClick() {
    this.range = [this.item.telemetry];
    this.cursorMode = 'range';
  }

  // Do something with this selection
  addPoint() {

  }

  svgClick() {
    if (!this.hasClicked) {
      // First click
      this.hasClicked = true;
      // No longer need the pointer on hover
      this.svg.classed('can-click', false);

      if (this.hasToClickToInteract) {
        // dont process click on first click as user wants to scroll over without hover.
        return;
      }
    }
    if (!this.pause) {
      // We have our first click! 
      this.pause = true;
    } else {
      // Stop the pause
      this.pause = false;
    }

    if (this.cursorMode === 'range') {
      this.cursorMode = 'ranged';

    } else {
      /* DISABLED UNTIL ANNOTATIONS IMPLEMENTED 
      this.pause = !this.pause;
      */
    }
  }

  processAnnotations() {
    this._annotations.forEach(a => {
      let i = this.telemetry.findIndex(item => item.i === a.asset_value_id);
      if (typeof i !== 'undefined') {
        this.telemetry[i].annotation = a;
        a.d = this.telemetry[i].d;
      }
    });

    this.svg
      .select('g.annotations')
      .selectAll('circle')
      .data(this._annotations)
      .enter()
      .append('circle')
      .attr('cx', (annotation: any) => {
        this.log(`d is `, annotation);
        return this.x(annotation.d);
      })
      .attr('cy', (annotation: any) => { return this.y(+annotation.value); })
      .attr('r', 5)
      .attr('class', 's3g-circle-annotation');
  }

  svgDoubleClick() {
    this.dateSelected = moment(this.item.telemetry.d);
  }

  annotatePoint() {
    this.inputMode = 'annotate';
  }

  buildFromTelemetry() {
    let self = this;
    this.dataAccuracy = 'true';

    this.log('building chart');
    this.removeExistingChartFromParent();
    this.setChartDimensions();

    this.addGraphicsElement();
    if (!this.telemetry || this.telemetry.length === 0) {
      return;
    }

    let xFrom, xTo;

    if (this.dateFrom && this.dateTo) {
      xFrom = this.dateFrom;
      xTo = this.dateTo;
    } else {
      // Get dates from telemetry
      // Clone as we will be modifiying this
      xFrom = new Date(this.telemetry[0].d);
      xTo = new Date(this.telemetry[this.telemetry.length - 1].d);
    }

    // Make sure low to high dates
    if (xFrom > xTo) {
      [xTo, xFrom] = [xFrom, xTo];
    }

    // Make sure telemetry is in the right order
    if (this.telemetry.length > 1 && +this.telemetry[0].d > +this.telemetry[1].d) {
      this.telemetry.reverse();
    }

    // dates start from time 00:00:00 so hourly weather is aligned.
    if (!this.dateFrom) {
      xFrom.setHours(0, 0, 0);
      xTo.setHours(23, 59, 59);
    }

    this.telemetryFrom = xFrom;
    this.telemetryTo = xTo;
    // const filtered = this.telemetry.filter(row => row.d >= xFrom && row.d <= xTo);

    const days = Math.abs(moment(xTo).diff(moment(xFrom), 'days') + 1);
    this.log(`Chart has ${days} days`);

    const filtered = this.telemetry;

    if (this.dateFrom) {
      // Explicity set the start/end date
      this.xDomain = [xFrom, xTo];
    } else {
      this.xDomain = d3.extent(filtered, (d: any) => d.d);
    }

    // Purpose takes priority over asset type
    let assetMax = null;
    let assetMin = null;

    if (!this.minMaxFromData) {
      assetMax = this.assetPurpose && this.assetPurpose.max !== null ? this.assetPurpose.max : (this.assetType?.max !== undefined ? this.assetType.max : null);
      assetMin = this.assetPurpose && this.assetPurpose.min !== null ? this.assetPurpose.min : (this.assetType?.min !== undefined ? this.assetType.min : null);
    }

    this.dataMax = Math.max(...filtered.map(f => f.v));
    this.dataMin = Math.min(...filtered.map(f => f.v));
    this.log('MIN/MAX', this.dataMin, this.dataMax, assetMax, assetMin);


    // The min / max of telemetry
    this.max = this.max > this.dataMax ? this.max : this.dataMax;
    this.min = this.min < this.dataMin ? this.min : this.dataMin;

    if ((this.assetType && assetMin !== null && assetMax !== null) || !this.assetType) {
      // The min/max for the chart (may be higher than this.min/max)
      this.yDomain = [assetMax > this.max ? assetMax : this.max, assetMin < this.min ? assetMin : this.min];
    } else {
      //this.yDomain = d3.extent(filtered, (d: any) => d.v).reverse();
      this.yDomain = [this.max, this.min];
    }
    this.log('Domain calc...done');
    this.x = d3
      .scaleTime()
      .range([this.margin.left, this.width - this.margin.right])
      .domain(this.xDomain);

    if (this.showLegend) {
      this.svg.append('g')
        .attr('class', 'xaxis')
        .attr("transform", `translate(0,${(this.height - (this.margin.top + this.margin.bottom))})`)
        .call(d3.axisBottom(this.x).ticks(this.xLegendTicks));
    }

    this.y = d3.scaleLinear()
      .domain(this.yDomain)
      .range([this.margin.top, this.height - (this.margin.top + this.margin.bottom)]);

    if (this.showLegend) {
      this.svg.append('g')
        .attr('class', 'yaxis')
        .attr("transform", `translate(${this.margin.left},0)`)
        .call(d3.axisLeft(this.y));

      /*.append('text')
      .attr('text-anchor', 'middle')
      .attr('transform', `translate(${this.margin.left},0)`)
      .text('Y Axis Label');*/

      if (this.legendTitles?.x) {
        this.svg.append("text")
          .attr("transform", "rotate(-90)")
          .attr("x", - this.height / 2)
          .attr("y", 12)
          .style("text-anchor", "middle")
          .style("font-size", "14px")
          .text(this.legendTitles.x);
      }
    }

    const xAxis2 = [];
    for (let day = 0; day < days; day++) {
      xAxis2.push({ d: moment(xFrom).add(day, 'days') });
      //const wd = moment(xFrom).add(day, 'days').weekday();
    }
    /*
        this.svg.append('g')
          .attr('class', 'xaxis-line2')
          .selectAll('text')
          .data(xAxis2)
          .enter()
          .append('text')
          .text(day => {
            if (days <= 14) {
              return day.d.format('dddd');
            } else {
              if (day.d.date() === 1)
                return day.d.format('MMMM');
            }
          })
          .attr('font-size', 10)
          .attr('x', (d => this.x(new Date(d.d))))
          .attr('y', (+this.height + 30) - (+this.margin.bottom + this.margin.top));
     */
    let filteredWeather: ITelemetryWeather[];

    if (!this.weather) {
      // No weather, use the weather from telemetry.

      // this.weather.filter(row => row.date >= xFrom && row.date <= xTo);
      // const weatherDomainDynamic = d3.extent(filteredWeather, (d: any) => d.t).reverse()
      this.weather = this.telemetry
        .filter(t => t.w)
        .map(t => {
          return { t: t.w.t, d: t.w.d, i: t.w.i, desc: t.w.i };
        });



    }

    if (this.weather && this.weather.length) {
      filteredWeather = this.weather.filter(row => row.d >= this.xDomain[0] && row.d <= xTo);
      // const weatherDomainDynamic = d3.extent(filteredWeather, (d: any) => d.t).reverse();
      const weatherDomainFixed = [this.weatherMax, this.weatherMin];
      // Weather Y axis
      this.yWeather = d3.scaleLinear()
        .domain(weatherDomainFixed)
        .range([this.margin.top, this.height - (this.margin.top + this.margin.bottom)]);
      // Weather legend
      if (this.showLegend) {
        this.svg.append('g')
          .attr("class", "weather-axis")
          .attr("transform", "translate( " + (this.width - this.margin.right) + ", 0 )")
          .call(d3.axisRight(this.yWeather));


        if (this.legendTitles?.x) {
          this.svg.append("text")
            .attr("transform", "translate( " + (this.width - this.MARGIN_WEATHER_RIGHT) + ", 0 ) rotate(-90)")
            .attr("x", - this.height / 2)
            .attr("y", 12)
            .style("text-anchor", "middle")
            .style("font-size", "14px")
            .text("Weather Temperature");
        }
      }
    }

    this.svg.append('g')
      .attr('class', 'ranges');

    const bisectDate = d3.bisector((d: any) => {
      return d.d || d.date;
    }).left;

    const bisectDateRight = d3.bisector((d: any) => {
      return d.d || d.date;
    }).right;

    if (this.assetType?.vType === 'step') {
      const barType: string = 'onoff';
      switch (barType) {
        case "bar":
          this.svg.append("g")
            .attr("fill", "steelblue")
            .selectAll("rect")
            .data(filtered)
            .join("rect")
            .attr("x", (d: Telemetry) => this.x(d.d))
            .attr("y", (d: Telemetry) => this.y(d.v))
            .attr("height", (d: Telemetry) => self.y(0) - self.y(d.v))
            .attr("width", 10); //  .attr("width", 10 || this.x.bandwidth()); 
          break;
        default:
          //this.log('bandwidth', this.x.bandwidth());
          // const barWidth = this.width / filtered.length;
          this.svg.append("g")
            .selectAll("rect")
            .data(filtered)
            .join("rect")
            .attr("fill", (d: Telemetry) => d.v === 0 ? '#f6f6f6' : 'steelblue')
            .attr("x", (d: Telemetry) => this.x(d.d))
            .attr("y", () => this.y(1))
            .attr("height", self.y(0) - self.y(1))
            .attr("width", (d: Telemetry) => {
              const index = filtered.findIndex(i => i.i === d.i);
              const start = self.x(d.d);

              const end = index < filtered.length - 1 ? self.x(filtered[index + 1].d) : (self.width - (self.margin.right));

              if (start > end) {
                this.log('oops');
              }
              return (end - start);
            });
          break;
      }
    } else {
      if (!this.penWidth) {
        this.penWidth = filtered.length < 1000 ? 3 : 1;
      }
      const path = this.svg
        .append('path')
        .style('fill', 'none')
        .datum(filtered)
        .attr('stroke', 'green')
        .attr('stroke-width', this.penWidth)
        .attr('d', d3.line()
          .x((d: any) => this.x(d.d))
          .y((d: any) => this.y(d.v))
          .curve(d3.curveLinear)
        );

      // Animate
      const pathLength = path.node().getTotalLength();

      const transitionPath = d3
        .transition()
        .duration(1000);

      path.attr('stroke-dashoffset', pathLength)
        .attr('stroke-dasharray', pathLength)
        .transition(transitionPath)
        .attr('stroke-dashoffset', 0);
    }

    // Apply weather 
    if (filteredWeather) {
      const path = this.svg
        .append('path')
        .style('fill', 'none')
        .datum(filteredWeather)
        .attr('class', 'weather')
        .attr('stroke', 'rgb(2, 58, 104)')
        .attr('opacity', '.4')
        .attr('stroke-width', 1)
        .attr('d', d3.line()
          .x((d: any) => this.x(d.d))
          .y((d: any) => this.yWeather(d.t))
          .curve(d3.curveLinear)
        );

      const pathLength = path.node().getTotalLength();

      const transitionPath = d3
        .transition()
        .ease(d3.easeSin)
        .duration(1000);

      path.attr('stroke-dashoffset', pathLength)
        .attr('stroke-dasharray', pathLength)
        .transition(transitionPath)
        .attr('stroke-dashoffset', 0);

    }

    const ragData = [];

    if (this.setpoints) {
      for (let day = 0; day < days; day++) {

        const dt = moment(xFrom).add(day, 'days').toDate();
        const { weekday, index: wd } = DateService.getWeekday(dt);

        const r = this.setpoints.find(s => s.weekday === wd);

        console.log(`setpoint ${day}`, dt, weekday, wd, r);

        if (r && r.isActive) {
          let f = moment(xFrom).add(day, 'days').startOf('day');
          let t = moment(xFrom).add(day + 1, 'days').subtract(1, 'millisecond');

          if (!r.allday) {
            // Set the times
            f.hour(+r.startsAt.substring(0, 2)).minutes(+r.startsAt.substring(3, 5));
            t.hour(+r.endsAt.substring(0, 2)).minutes(+r.endsAt.substring(3, 5));
          }

          let tDate = t.toDate();
          let fDate = f.toDate();

          if (+tDate > +this.xDomain[1]) {
            tDate = this.xDomain[1];
          }
          if (+fDate < +this.xDomain[0]) {
            tDate = this.xDomain[0];
          }

          if (r.amber_max) {
            ragData.push({ f: fDate, t: tDate, v: r.amber_max, c: 'orange' });
          }
          if (r.amber_min) {
            ragData.push({ f: fDate, t: tDate, v: r.amber_min, c: 'orange' });
          }
          if (r.red_min) {
            ragData.push({ f: fDate, t: tDate, v: r.red_min, c: 'red' });
          }
          if (r.red_max) {
            ragData.push({ f: fDate, t: tDate, v: r.red_max, c: 'red' });
          }
        }
      }

      const ragG = this.svg
        .append('g')
        .attr('class', 'rag-lines');

      ragG.selectAll('line')
        .data(ragData)
        .enter()
        .append('line')
        .attr('class', 'rag-line')
        .attr('x1', ((d: any) => this.x(d.f)))
        .attr('x2', ((d: any) => this.x(d.t)))
        .attr('y1', ((d: any) => this.y(d.v)))
        .attr('y2', ((d: any) => this.y(d.v)))
        .style('stroke', ((d: any) => d.c))
        .style('stroke-dasharray', '5,5')
        .style('opacity', .6)
        .style('stroke-width', 1);
    }

    if (this.limitLines) {
      const limitData: ILimitDataItem[] = [];

      const max = Math.max(...filtered.map(f => f.v));
      const min = Math.min(...filtered.map(f => f.v));


      for (let limitLineIndex = 0; limitLineIndex < this.limitLines.length; limitLineIndex++) {
        const limitLine = this.limitLines[limitLineIndex];

        for (let day = 0; day < days; day++) {
          const { index: wd } = DateService.getWeekday(moment(xFrom).add(day, 'days').toDate());
          // Is weekday sent to true
          if (limitLine.dayOfWeek[wd] && limitLine.value >= min && limitLine.value <= max) {

            let f = moment(xFrom).add(day, 'days').startOf('day');
            let t = moment(xFrom).add(day + 1, 'days').subtract(1, 'millisecond');

            if (limitLine.startTimeAt) {
              // Set the times
              f.hour(+limitLine.startTimeAt.substring(0, 2)).minutes(+limitLine.startTimeAt.substring(3, 5));
              t.hour(+limitLine.endTimeAt.substring(0, 2)).minutes(+limitLine.endTimeAt.substring(3, 5));
            }

            let tDate = t.toDate();
            let fDate = f.toDate();

            if (+tDate > +this.xDomain[1]) {
              tDate = this.xDomain[1];
            }
            if (+fDate < +this.xDomain[0]) {
              tDate = this.xDomain[0];
            }

            limitData.push({ f: fDate, t: tDate, v: limitLine.value, c: limitLine.colour });
          }
        }
      }

      const limitLinesG = this.svg
        .append('g')
        .attr('class', 'limit-lines');

      limitLinesG.selectAll('line')
        .data(limitData)
        .enter()
        .append('line')
        .attr('class', 'limit-line')
        .attr('x1', ((d: ILimitDataItem) => this.x(d.f)))
        .attr('x2', ((d: ILimitDataItem) => this.x(d.t)))
        .attr('y1', ((d: ILimitDataItem) => this.y(d.v)))
        .attr('y2', ((d: ILimitDataItem) => this.y(d.v)))
        .style('stroke', ((d: ILimitDataItem) => d.c))
        .style('opacity', .7)
        .style('stroke-width', 1);
    }

    if (this.blocks) {
      const max = Math.max(...filtered.map(f => f.v));
      const min = Math.min(...filtered.map(f => f.v));

      const blocksG = this.svg
        .append('g')
        .attr('class', 'blocks');

      blocksG.selectAll('line')
        .data(this.blocks)
        .enter()
        .append('rect')
        .attr('class', 'block')
        .attr('x', ((d: IGraphBlock) => this.x(d.from)))
        .attr('y', ((d: IGraphBlock) => this.y(max)))
        .attr('width', ((d: IGraphBlock) => this.x(d.to) - this.x(d.from)))
        .attr('height', ((d: IGraphBlock) => this.y(0)))
        .style('fill', ((d: IGraphBlock) => d.colour))
        .style('opacity', ((d: IGraphBlock) => d.opacity ?? .5))
        .style('stroke-width', 1);
    }

    this.svg.append('g')
      .attr('class', 'annotations');
    this.svg.append('g')
      .attr('class', 'pins');
    this.svg.append('g')
      .attr('class', 'minmax');

    if (this.pins) {
      // Pins must be ordered
      this.pins = this.pins.sort((a, b) => a.date > b.date ? 1 : -1);

      this.svg
        .select('g.pins')
        .selectAll('circle')
        .data(this.pins)
        .enter()
        .append('circle')
        .attr('cx', (pin: ChartPin) => {
          this.log(`pin d is `, pin);
          return this.x(pin.date);
        })
        .attr('cy', (pin: ChartPin) => {

          let i = bisectDate(filtered, pin.date); // index of current data item
          if (i >= filtered.length) {
            i = filtered.length - 1;
          }
          if (i < 0) {
            i = 0;
          }
          return this.y(+filtered[i].v);
        })
        .attr('r', (pin: ChartPin) => pin.size)
        .attr('fill', (pin: ChartPin) => pin.fill)
        .attr('stroke', (pin: ChartPin) => pin.stroke)
        .attr('class', 's3g-circle-pin');
    }

    if (this.showMinMax) {
      //const plotData = filtered.filter(f => (f.v <= this.min || f.v >= this.max ));
      const plotData = [];
      const plotMax = filtered.find(f => f.v >= this.dataMax);
      const plotMin = filtered.find(f => f.v <= this.dataMin);
      if (plotMax && plotMin) {
        plotMax.annotation = { offset: 4 };
        plotMin.annotation = { offset: -4 };
      }

      plotData.push(plotMax);
      plotData.push(plotMin);

      this.svg
        .select('g.minmax')
        .selectAll('path')
        .data(plotData)
        .enter()
        .append('path')
        .attr("d", d3.symbol().type(d3.symbolTriangle).size(14))
        .attr("fill", '#000fff')
        .attr("transform", (d: Telemetry) => `translate(${this.x(d.d)},${this.y(d.v) - (d.annotation?.offset || 0)}) scale(1,${d.v >= this.max ? -1 : 1})`);
    }

    // Annotations go here
    if (this.annotations && this.annotations.length) {
      // console.info('WE_HAVE_ANNOTATIONS');
      this.svg
        .selectAll('g.annotations')
        .data(this.annotations)
        .enter()
        .append('circle')
        .attr('cx', (d: any) => {
          this.log(`d is `, d);
          return this.x(d.createdAt);
        })
        .attr('cy', (d: any) => { return this.y(+d.value); })
        .attr('r', 5)
        .attr('class', 's3g-circle-annotation');
    }

    /* DO YOU WANT CIRCLES?
        this.svg
          .selectAll('circle')
          .data(filtered)
          .enter()
          .append('circle')
          .attr('cx', (d: any) => { return this.x(d.date); })
          .attr('cy', (d: any) => { return this.y(d.max); })
          .attr('r', 3)
          .attr('class', 's3g-circle');
    */

    /**
     * 
     *  FOCUS
     * 
     */
    const focus = this.svg
      .append('g')
      .attr('class', 'focus')
      .style('display', 'none');
    // True lines
    focus.append('line')
      .attr('id', 'focusLineX')
      .attr('class', 's3g-focusLine');

    // Min/Max lines
    focus.append('line')
      .attr('id', 'focusLineY2')
      .attr('class', 's3g-focusLine');

    focus.append('line')
      .attr('id', 'focusLineY1')
      .attr('class', 's3g-focusLine');

    focus.append('line')
      .attr('id', 'focusLineY')
      .attr('class', 's3g-focusLine');

    // Weather line
    focus.append('line')
      .attr('id', 'focusLineYW')
      .attr('class', 's3g-focusLine');
    /*
     * 
     * RANGE
     * 
     */
    const range = this.svg;

    range.append('rect')
      .attr('id', 'ranged-rect')
      .attr('class', 's3g-range-rect');

    focus.append('circle')
      .attr('id', 'focusCircle')
      .attr('r', 5)
      .attr('class', 's3g-circle s3g-focusCircle');

    this.focus = focus;
    /*
        focus.append('circle')
          .attr('id', 'focusCircleW')
          .attr('r', 5)
          .attr('class', 's3g-circle s3g-focusCircle');
    */
    this.svg.append('rect')
      .attr('class', 's3g-overlay')
      .attr('width', this.width)
      .attr('height', this.height)
      .on('click', this.svgClick.bind(this))
      .on('dblclick', this.svgDoubleClick.bind(this))
      .on('mouseover', () => {
        if (self.hasToClickToInteract && !self.hasClicked) {
          // user needs to click to enable
          return;
        }
        self.focus.style('display', null);
      })
      .on("wheel", () => {
        if (self.pause) {
          // Allow scrolling if paused.
          d3.event.preventDefault();
          // const direction = d3.event.wheelDelta < 0 ? 'up' : 'down';
          // this.log(d3.event.wheelDelta);
          // self.zoom(direction);
        }
      })
      .on('mouseout', () => {
        // focus.style('display', 'none');
        // this.item = null;
        // this.pause = false;
      })
      .on('mousemove', function () {
        // this.log('mousemove', self.pause, self.hasToClickToInteract, self.hasClicked);

        if (self.pause && self.cursorMode === 'free') {
          // The user is interacting with options.
          return;
        }
        if (self.hasToClickToInteract && !self.hasClicked) {
          // user needs to click to enable
          return;
        }
        self.focus.style('display', null);
        self.ngZone.runOutsideAngular(() => {
          const mouse = d3.mouse(this);
          const mouseDate = self.x.invert(mouse[0]);
          const i = bisectDate(filtered, mouseDate); // index of current data item
          const d0: Telemetry = (i > 1) ? filtered[i - 1] : null;
          const d1: Telemetry = (i <= filtered.length) ? filtered[i] : null;
          let d: Telemetry;
          // date value closest to mouse
          if (!d0 && d1) {
            d = d1;
          } else if (d0 && !d1) {
            d = d0;
          } else {
            d = mouseDate - +d0?.d > +d1?.d - mouseDate ? d1 : d0;
          }
          if (!d?.w && self.weather) {
            let iw = bisectDate(self.weather, mouseDate);
            if (iw >= self.weather.length) {
              iw = self.weather.length - 1;// index of current data item
            }
            if (!isNaN(iw) && self.weather[iw] && d) {
              d.w = { d: self.weather[iw].d, t: self.weather[iw].t, w: 0, h: 0, i: self.weather[iw].i };
            }
          }

          if (!d) {
            return;
          }
          let pin: ChartPin;
          if (self.pins) {
            let ip = bisectDate(self.pins, mouseDate); // index of current data item
            if (!isNaN(ip)) {
              ip = ip - 1;
              if (ip >= self.pins.length) {
                ip = self.pins.length - 1;
              }
              if (ip <= 0) {
                ip = 0;
              }
              pin = self.pins[ip];
            }
          }
          let annotation;
          if (self.annotations) {
            let ip = bisectDate(self.annotations, mouseDate); // index of current data item
            if (!isNaN(ip)) {
              ip = ip - 1;
              if (ip >= self.annotations.length) {
                ip = self.annotations.length - 1;
              }
              if (ip <= 0) {
                ip = 0;
              }
              // this.log('PIN INDEX', ip, self.pins[ip].date.toDateString());
              annotation = self.annotations[ip];
            }
          }

          // Store the datapoint for on hover div
          self.item = { telemetry: d, pin, annotation };
          // Store the weather for on hover div
          const x = self.x(d.d);
          let y: number;

          y = self.y(d.v);
          focus.select('#focusCircle')
            .attr('cx', x)
            .attr('cy', y)
            .style('stroke', 'white')
            .style('fill', self.ragColours[d.rag]);

          focus.select('#focusLineX')
            .attr('x1', x).attr('y1', self.y(self.yDomain[0]))
            .attr('x2', x).attr('y2', self.y(self.yDomain[1]));

          focus.select('#focusLineY')
            .attr('x1', self.x(self.xDomain[0])).attr('y1', y)
            .attr('x2', self.x(self.xDomain[1])).attr('y2', y);

          if (self.cursorMode === 'range') {
            const rectItem = self.range[0];
            let rectX = self.x(rectItem.d);
            let rectWidth = x - rectX;
            if (rectWidth < 0) {
              rectWidth = Math.abs(rectWidth);
              rectX = x;
            }
            range.select('#ranged-rect')
              .attr('x', rectX)
              .attr('y', self.y(self.yDomain[0]))
              .attr('opacity', .1)
              .attr('height', self.y(self.yDomain[1]) - self.y(self.yDomain[0]))
              .attr('width', rectWidth);
          }
        });
      });

    this.isWorking = false;
    this.chartCompletedMinMax.emit({ dataMin: this.dataMin, dataMax: this.dataMax, chartMin: this.min, chartMax: this.max });
  }

  applyWeatherToChart() {
    this.buildFromTelemetry();
  }

  annotateClick() {
    if (!this.canAnnotate) {
      return;
    }
    this.annotation = { text: this.item.telemetry.annotation?.text || '', users: [] };
    this.isShowingPanel.set(true);
    this.apiService.getUsersForOrg()
      .then(users => {
        this.orgUsers = users.map(user => { return { name: user.name, code: user.id } });
      });
    setTimeout(() => {
      // We don't want user list to get the focus
      try {
        this.annotationText.nativeElement.focus();
      } catch (e) { }
    }, 500);
  }

  annotationDelete() {
    this.annotation.text = '';
    this.annotationSave();
  }

  annotationSave() {

    if (this.annotation.text.trim().length < 1) {
      this.apiService.toastWarn('Please enter some notes', '');
      return;
    }

    const body = {
      annotation: {
        avid: this.item.telemetry.i,
        uid: this.apiService.getUserId(),
        orgId: this.apiService.getUserOrg().id,
        text: this.annotation.text,
        users: this.annotation.users
      }
    }
    this.log('Saving annotation', body);

    let a = { text: this.annotation.text, asset_value_id: this.item.telemetry.i, value: this.item.telemetry.v };

    this.apiService.postAsset(this.asset.id, body)
      .then(r => {
        this.log(r);
        this.isShowingPanel.set(false);
        this.annotations.push(a);
        this.item.telemetry.annotation = a;
        this.processAnnotations();
      })
      .catch(e => {
        this.log(e);
        this.isShowingPanel.set(false);
        this.annotations.push(a);
        this.item.telemetry.annotation = a;
        this.processAnnotations();
      });
  }

  assetDashboardClick() {
    this.log(this.item);
    this.isShowingAssetDashboard.set(true);
  }

  zoomOut() {
    const restore = this.history.pop();
    if (!restore || !restore.dateFrom || !restore.dateTo) {
      return;
    }
    this.dateFrom = new Date(restore.dateFrom);
    this.dateTo = new Date(restore.dateTo);
    this.getTelemetry();
  }

  zoomIn(date: Date) {
    this.log(`Zoom into ${date}`);

    if (this.dateTo) {
      this.history.push({ dateFrom: +this.dateFrom, dateTo: +this.dateTo });
    } else {
      this.history.push({ dateFrom: +this.telemetryFrom, dateTo: +this.telemetryTo });
    }
    const df = moment(date).subtract(12, 'hours').startOf('day').toDate();
    const dt = moment(date).add(12, 'hours').endOf('day').toDate();
    this.dateFrom = df;
    this.dateTo = dt;
    this.getTelemetry();
  }

  getTelemetry() {
    this.d3Service.getTelemetry(this.asset.id, this.dateFrom, this.dateTo, this.setpoints)
      .then(r => {
        this.item = null;
        this.pause = false;
        this.telemetry = r.packets;
        this.annotations = r.annotations;

        this.buildFromTelemetry();
        this.processAnnotations();
      });
  }

  onResize(event: any) {
    this.log('RESIZE', event);
    this.width = this.hostElement.parentNode.getBoundingClientRect().width;
    this.buildFromTelemetry();
  }
}


export interface ILimitDataItem {
  f: Date;
  t: Date;
  v: number;
  c: string;
}

export interface IChartCompletedMinMax {
  dataMin: number;
  dataMax: number;
  chartMax: number;
  chartMin: number;
}

export interface IGraphBlock {
  from: Date;
  to: Date;
  colour: string;
  opacity?: number;
}
