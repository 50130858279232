import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Schematic } from 'app/classes/schematic/schematic';
import { TreeNode } from 'primeng/api/treenode';
import { find } from 'rxjs-compat/operator/find';

@Component({
  selector: 'app-schematics-card',
  templateUrl: './schematics-card.component.html',
  styleUrls: ['./schematics-card.component.css']
})
export class SchematicsCardComponent implements OnInit, OnDestroy {

  _schematic: Schematic;
  @Input()
  public set schematic(v: Schematic) {
    this._schematic = v;

    if (v) {
      this.tree = [this._schematic.getTreeNode()];
    } else {
      this.tree = [];
    }
  }

  public get schematic(): Schematic {
    return this._schematic;
  }

  selectedNodes: TreeNode[];

  selectedNode: TreeNode;

  tree: TreeNode[];

  constructor() {
  }

  ngOnInit(): void {
    this.getLocal();
  }

  saveLocal() {

    const ids = [];
    const processTree = (treeNodes: TreeNode[]) => treeNodes.forEach(treeNode => {

      if (treeNode.expanded) {
        ids.push(treeNode.data);
      }

      if (treeNode.children != null) processTree(treeNode.children);
    });

    processTree(this.tree);

    localStorage.setItem(`schematics-card:${this.schematic.id}`, JSON.stringify(ids));
  }

  getLocal() {
    const idsString = localStorage.getItem(`schematics-card:${this.schematic.id}`);

    if (idsString) {
      const ids: string[] = JSON.parse(idsString);
      const processTree = (treeNodes: TreeNode[]) => treeNodes.forEach(treeNode => {

        treeNode.expanded = ids.includes(treeNode.data);

        if (treeNode.children != null) processTree(treeNode.children);
      });

      processTree(this.tree);
    }
  }

  nodeSelect(nodeEvent: any) {
    console.log(nodeEvent);
    this.selectedNode = nodeEvent.node;
  }

  nodeUnselect(nodeEvent: any) {
    console.log(nodeEvent);
  }

  onNodeExpand(node: TreeNode) {
    console.log(node);
    this.saveLocal();

  }

  onNodeCollapse(node: TreeNode) {
    console.log(node);
    this.saveLocal();
  }

  ngOnDestroy(): void {
    this.saveLocal();
  }
}
