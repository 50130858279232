<article>
	<app-theme-page-container label="Columns">

		<app-theme-page-section>
			<div *ngFor="let column of columns" class="columns">
				<div>
					<p-checkbox name="column" [value]="key" [label]="column.title" [binary]="true" [(ngModel)]="column.checked"
						(onChange)="changed()" (onBlur)="changed()"></p-checkbox>
				</div>
			</div>
		</app-theme-page-section>

		<app-theme-page-section>
			<div class="buttons">
				<div class="text-right">
					<p-button label="Cancel" icon="pi pi-times" styleClass="p-button-outlined p-button-secondary mr-1"
						(click)="cancel()">
					</p-button>
					<p-button label="Export" icon="mdi mdi-content-save" styleClass="" (click)="submit()">
					</p-button>
				</div>
			</div>
		</app-theme-page-section>
	</app-theme-page-container>
</article>
