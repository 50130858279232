import { Asset } from "./asset";

export class Api {
	id: number;
	title: string;
	url: string;
	headers: Map<string, string>;
	payload: string;
	authorization: string;

	constructor(api?: any) {
		this.headers = new Map();

		if (api) {
			this.id = api.id;
			this.payload = api.payload;
			this.title = api.title;
			this.url = api.url;
			this.authorization = api.authorization;
		} else {
			this.title = '';
			this.payload = '';
			this.authorization = null;
			this.url = '';
		}
	}

	pretty(asset: Asset) {
		let json = this.payload || '{}';
		json = json.replace('$asset_id', String(asset.id));
		json = json.replace('$asset_ref', asset.ref === null ? '""' : '"' + asset.ref + '"');
		json = json.replace('$asset_type', '"' + asset.assetTypeTitle + '"');
		json = json.replace('$asset_value', asset.value);
		json = json.replace('$asset_timestamp', String(+new Date(asset.updatedAt)));
		json = json.replace('$asset_datetime', '"' + this.dbDateTime(new Date(asset.updatedAt)) + '"');
		json = json.replace('$api_action', '"realtime"');

		return JSON.stringify(JSON.parse(json), null, 2);
	}

	dbDateTime(date: Date) {
		return date.getFullYear() + '-' +
			('00' + (date.getMonth() + 1)).slice(-2) + '-' +
			('00' + date.getDate()).slice(-2) + ' ' +
			('00' + date.getHours()).slice(-2) + ':' +
			('00' + date.getMinutes()).slice(-2) + ':' +
			('00' + date.getSeconds()).slice(-2);
	}
}

export class ApiAction {
	action: string;
	payload: string;
	headers: Map<string, string>;

	constructor(data?: any) {
		this.headers = new Map();
		if (data) {
			this.action = data.action;
			this.payload = data.payload;
		}
	}


	pretty(asset: Asset) {
		let json = this.payload;
		json = json.replace('$asset_id', String(asset.id));
		json = json.replace('$asset_ref', asset.ref === null ? '""' : '"' + asset.ref + '"');
		json = json.replace('$asset_type', '"' + asset.assetTypeTitle + '"');
		json = json.replace('$asset_value', asset.value);
		json = json.replace('$asset_timestamp', String(+new Date(asset.updatedAt)));
		json = json.replace('$asset_datetime', '"' + this.dbDateTime(new Date(asset.updatedAt)) + '"');
		json = json.replace('$api_action', '"realtime"');

		return JSON.stringify(JSON.parse(json), null, 2);
	}

	dbDateTime(date: Date) {
		return date.getFullYear() + '-' +
			('00' + (date.getMonth() + 1)).slice(-2) + '-' +
			('00' + date.getDate()).slice(-2) + ' ' +
			('00' + date.getHours()).slice(-2) + ':' +
			('00' + date.getMinutes()).slice(-2) + ':' +
			('00' + date.getSeconds()).slice(-2);
	}
}
