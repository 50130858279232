<div [class.caption]="_caption()">
	@if(_caption()) {
	<label>{{_caption()}}</label>
	}
	<app-button [size]="size" [label]="label()" (click)="_onDialog.set(true)"></app-button>
</div>

@if(_onDialog() ) {
<app-dialog [canScroll]="true" (onClose)="closed($event)" [width]="500" [hasToolbar]="true">
	<div class="toolbar">
		<app-toolbar>
			<div class="right">
				<app-button size="medium" (click)="selectClear()"> <i class="mdi mdi-eraser"></i>&nbsp; Clear </app-button>
				<app-button size="medium" (click)="selectSave()"> <i class="mdi mdi-check"></i>&nbsp; Use Selected </app-button>
			</div>
		</app-toolbar>
	</div>
	<table class="table table-striped table-hover" aria-label="Sensors">
		<thead>
			<tr>
				<th>Sensor</th>
				<th>Category</th>
			</tr>
		</thead>
		<tbody>
			@for(sensor of assetTypes(); track sensor.id) {
			<tr (click)="select(sensor, $event)" (mouseenter)="rowMouseEnter(sensor)">
				<td>
					<app-chip [checked]="sensor.checked"></app-chip>
					<span>{{sensor.title}}</span>
				</td>
				<td>{{sensor.category}}</td>
			</tr>
			}
		</tbody>
	</table>

</app-dialog>
}
