@defer( when asset) {
<article>

  <div class="flex">
    <app-page-title [canGoBack]="true" (click)="back()" class="flex-1"> {{subtitle}}</app-page-title>
    <app-chip [label]="rangeText" [canClick]="false" />
  </div>

  <div>
    <div class="tabview-list">
      <div [class.active]="defaultTab===0" (click)="handleTabChange(0)"><i class="mdi mdi-text-box-edit-outline"></i> Configuration </div>
      <div [class.active]="defaultTab===1" (click)="handleTabChange(1)" pTooltip="updated {{asset.updatedAt | date:'dd/MM/YY HH:mm' }}"><i class="mdi mdi-chart-line"></i> Query </div>
      <div></div>
    </div>

    @switch(defaultTab) {
    @default {
    @if(asset) {
    <div>
      @if(!setpoints) {
      <div class="loader-container">
        <div class="loader">
          Please wait...
        </div>
      </div>
      } @else {
      <section>
        <div style="display: none">
          <div>
            Operational hours
          </div>
          <div>
            Contacts
          </div>
        </div>
        <div>

          <app-toolbar>
            <div class="right">
              @if(!isUpdating) {
              <app-button (click)="updateSetPoints()">
                <span><i class="pi pi-check"></i> Update</span>
              </app-button>
              } @else {
              <app-button>
                <span><i class="mdi mdi-loading mdi-spin-x2"></i> Updating...</span>
              </app-button>
              }
            </div>
          </app-toolbar>

          <table class="table table-sm setpoint-table mt-1" aria-label="Setpoints">
            <thead>
              <th>Day</th>
              <th tooltipPosition="left" [pTooltip]="rangeId===3?'Trigger before this time':'Trigger from this time'">
                @switch(rangeId) {
                @case(3) {
                <span>Trigger<p>Before</p></span>
                }
                @case(2) {
                <span>From</span>
                }
                }
              </th>
              <th tooltipPosition="left" [pTooltip]="rangeId===3?'Trigger after this time':'Trigger before this time'">
                @switch(rangeId) {
                @case(3) {
                <span>Trigger<p>After</p></span>
                }
                @case(2) {
                <span>To</span>
                }
                }
              </th>
              <th class="rag-red" [ngClass]="{'enabled':alarms.red_min}">Red Min
                <p pTooltip="Enable alarm - Red minimum breached" tooltipPosition="top">
                  <label class="switch" title=""><input name="typetoggle" type="checkbox" [(ngModel)]="alarms.red_min" (click)="linkRule()"><span class="checkboxslider alarm round"></span>
                  </label>
                </p>
              </th>
              <th class="rag-amber" [ngClass]="{'enabled':alarms.amber_min}">Amber Min
                <p pTooltip="Enable alarm - Amber minimum breached" tooltipPosition="top">
                  <label class="switch" title=""><input name="typetoggle" type="checkbox" [(ngModel)]="alarms.amber_min" (click)="linkRule()"><span class="checkboxslider alarm round"></span>
                  </label>
                </p>
              </th>
              <th class="green-gap">Green
                <p></p>
              </th>
              <th class="rag-amber" [ngClass]="{'enabled':alarms.amber_max}">Amber Max
                <p pTooltip="Enable alarm - Amber maximum breached" tooltipPosition="top">
                  <label class="switch" title=""><input name="typetoggle" type="checkbox" [(ngModel)]="alarms.amber_max" (click)="linkRule()"><span class="checkboxslider alarm round"></span>
                  </label>
                </p>
              </th>
              <th class="rag-red" [ngClass]="{'enabled':alarms.red_max}">Red Max
                <p pTooltip="Enable alarm - Red maximum breached" tooltipPosition="top">
                  <label class="switch" title=""><input name="typetoggle" type="checkbox" [(ngModel)]="alarms.red_max" (click)="linkRule()"><span class="checkboxslider alarm round"></span>
                  </label>
                </p>
              </th>
              <th class="all-day" *ngIf="rangeId === 2">All Day</th>
              <th>Enabled</th>
              <th></th>
            </thead>
            <tbody>
              @for(setpoint of setpoints; let i = $index; track setpoint) {
              <tr [class.chart-clicked-weekday]="selectedWeekday===i" [class.forCopy]="copySetpoint && copySetpoint.asset.id === asset.id && copySetpoint.index === i">

                <td>
                  {{weekdays[i].title}}
                </td>

                @if(!setpoint.allday) { <td *ngIf="!setpoint.allday">
                  <input type="time" [(ngModel)]="setpoint.startsAt" (blur)="onBlur()" [disabled]="!setpoint.isActive" (keyup)="generateGreenRAGTooltip()">
                </td>
                } @else {
                <td>00:00:00</td>
                }

                @if(!setpoint.allday) {
                <td>
                  <input type="time" [(ngModel)]="setpoint.endsAt" (blur)="onBlur()" [disabled]="!setpoint.isActive" (keyup)="generateGreenRAGTooltip()">
                </td>
                } @else {
                <td>23:59:59</td>
                }

                <td class="rag-red">
                  <input [(ngModel)]="setpoint.red_min" style="width:60px" [disabled]="!setpoint.isActive" (blur)="change('red_min')" (keyup)="generateGreenRAGTooltip()">
                </td>
                <td class="rag-amber">
                  <input [(ngModel)]="setpoint.amber_min" style="width:60px" [disabled]="!setpoint.isActive" (blur)="change('amber_min')" (keyup)="generateGreenRAGTooltip()">
                </td>
                <td class="green-gap" [pTooltip]="setpoint.tooltips?.green" [showDelay]="1000"></td>
                <td class="rag-amber">
                  <input [(ngModel)]="setpoint.amber_max" style="width:60px" [disabled]="!setpoint.isActive" (blur)="change('amber_max')" (keyup)="generateGreenRAGTooltip()">
                </td>

                <td class="rag-red">
                  <input [(ngModel)]="setpoint.red_max" style="width:60px" [disabled]="!setpoint.isActive" (blur)="change('red_max')" (keyup)="generateGreenRAGTooltip()">
                </td>

                <td class="all-day" *ngIf="rangeId === 2">

                  <label class="switch" title="Enable to run all day"><input name="typetoggle" type="checkbox" [(ngModel)]="setpoint.allday" (click)="allday(i)"><span class="checkboxslider round"></span></label>
                </td>

                <td>
                  <label class="switch" title="Enable this line"><input name="typetoggle" type="checkbox" [(ngModel)]="setpoint.isActive" (click)="enableWeekday(i)"><span class="checkboxslider round"></span></label>
                </td>

                <td class="actions">
                  <i class="mdi mdi-content-copy" [class.disabled]="!setpoint.isActive " pTooltip="Copy" tooltipPosition="left" (click)="copy(i)"></i>

                  <i class="mdi mdi-content-paste" [class.disabled]="!copySetpoint" pTooltip="Paste" tooltipPosition="left" (click)="paste(i)"></i>
                </td>
              </tr>
              }
            </tbody>
          </table>
          <div class="explorer-container hidden-xs hidden-sm">
            <app-asset-chart theme="white" [asset]="asset" [setpoints]="setpoints" (onBlockClicked)="assetChartBlockClicked($event)"></app-asset-chart>
          </div>

          <div class="delay">
            <div>
              <h4>Delay in minutes</h4>
              <input max="1000" min="0" type="number" [(ngModel)]="master.delay" style="font-size: 18px;">
              <span style="font-style: italic;"> The delay in minutes before the the setpoint triggers a red or
                orange
                status.</span>
            </div>
          </div>

          <div class="notifications">
            <div>
              <div class="notification-container">
                <div>
                  <h4>User groups</h4>
                </div>
                <div>
                  <app-button-notifications [groupings]="groupings()" (onUpdate)="updateNotifications($event)" />
                </div>
              </div>

              <div class="table-wrapper">
                @if(groupings().length) {
                <table class="table table-sm" aria-label="groups">
                  <tbody>
                    @for(grouping of groupings(); track grouping.title) {
                    <tr>
                      <td>{{grouping.title}}</td>
                    </tr>
                    }
                  </tbody>
                </table>
                } @else {
                <p>No user groups selected.</p>
                }
              </div>

              <div class="notification-container">
                <div>
                  <span pTooltip="Toggle site notifications" tooltipPosition="top" (click)="toggleSiteNotifications()" class="can-click"><i class="fa fa-fw" [class.fa-square-o]="!master.hasSiteNotifications" [class.fa-check-square-o]="master.hasSiteNotifications"></i> Site notifications
                    {{!master.hasSiteNotifications ? ' off' : ''}}</span>
                </div>
                <div>
                </div>
              </div>

              <div class="table-wrapper" [class.is-disabled]="!master.hasSiteNotifications">
                <table class="table table-sm" aria-label="users">
                  <tbody>
                    @for(contact of contacts; track contact.email) {
                    <tr>
                      <!-- Site contacts -->
                      <td>{{contact.name}}</td>
                      <td>{{contact.email}}</td>
                    </tr>
                    }
                  </tbody>
                </table>
                @if(contacts.length === 0) {
                <p>No contacts defined for the site.</p>
                }
              </div>
            </div>
          </div>

          <div class="notes">
            <h4>Notes</h4>
            <textarea class="fourd" [(ngModel)]="master.notes" rows="4"></textarea>
          </div>
        </div>
      </section>
      }
    </div>
    }
    }
    @case(1) {
    <app-setpoint-query [asset]="asset"></app-setpoint-query>
    }
    }
  </div>
</article>
} @placeholder (minimum 100ms) {

}

@if(loading || isUpdating) {
<app-please-wait></app-please-wait>
}
