<section [class.wants-border]="border" [class.is-warning]="isWarning" [class.no-background]="noBackground()" [class.is-muted]="isMuted">
  @if (label) {
    <header>
      @if (titleLink) {
        <div (click)="navigate()" class="can-click title"><i class="fa fa-fw fa-long-arrow-left"></i> {{ label }}</div>
      } @else {
        <div class="title">
          @if (logo) {
            <span> <img src="assets/svg/elogbooks-logo.svg" width="32px" height="32px" alt="logo" /> </span>
          }
          {{ label }}
        </div>
      }
      @if (checkboxes) {
        <div>
          <ng-template ngFor [ngForOf]="checkboxes" let-checkbox>
            <span> {{ checkbox.title }}<i class="fa fa-fw fa-check-square-o"></i></span>
          </ng-template>
        </div>
      }
      <div *ngIf="toolbar" class="toolbar no-print">
        <span *ngFor="let item of toolbar" [class.text-link]="item.text">
          <i *ngIf="item.icon" class="mdi {{ item.icon }}" (click)="toolbarClick(item)" pTooltip="{{ item.label }}" tooltipPosition="top"></i>
          <span *ngIf="item.text" pTooltip="{{ item.label }}" [tooltipPosition]="item.tooltipposition" (click)="toolbarClick(item)"> {{ item.text }}</span>

          <span *ngIf="item.select && item.select.length">
            <select (change)="selectChanged()">
              <option *ngFor="let o of item.select" [value]="o.id">{{ o.label }}</option>
            </select>
          </span>
        </span>
      </div>
      <div *ngIf="rag">
        <div class="colours" [class.can-change]="canChangeRag">
          <div class="note-colour-red" (click)="clickRag('red')">
            <i class="fa fa-fw" [class.fa-circle-o]="rag !== 'red'" [class.fa-circle]="rag === 'red'"></i>
          </div>
          <div class="note-colour-orange" (click)="clickRag('amber')">
            <i class="fa fa-fw" [class.fa-circle-o]="rag !== 'amber'" [class.fa-circle]="rag === 'amber'"></i>
          </div>
          <div class="note-colour-green" (click)="clickRag('green')">
            <i class="fa fa-fw" [class.fa-circle-o]="rag !== 'green'" [class.fa-circle]="rag === 'green'"></i>
          </div>
        </div>
      </div>
    </header>
  }
  <ng-content></ng-content>
</section>
