import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Asset } from '../classes/asset';
import { Gateway } from '../classes/gateway';
import { SectionBannerBack } from '../classes/section-banner-back';
import { FilterMenus } from '../classes/filter-menus';
import { PageOption } from "./page.service";
import { Alarm } from 'app/classes/alarm';
import { Org } from 'app/classes/org';
import { IDateConfig } from './calendar.service';

@Injectable({ providedIn: 'root' })
export class StoreService {

  private _assets: BehaviorSubject<Asset[]> = new BehaviorSubject([]);
  public assets: Observable<Asset[]> = this._assets.asObservable();

  private _orgs: BehaviorSubject<Org[]> = new BehaviorSubject(null);
  public orgs: Observable<Org[]> = this._orgs.asObservable();

  private _rootContainer: BehaviorSubject<any> = new BehaviorSubject(null);
  public rootContainer: Observable<any> = this._rootContainer.asObservable();

  private _sectionBannerBack: BehaviorSubject<SectionBannerBack> = new BehaviorSubject(null);
  public sectionBannerBack: Observable<SectionBannerBack> = this._sectionBannerBack.asObservable();

  private _sectionBannerChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  public sectionBannerChanged: Observable<any> = this._sectionBannerChanged.asObservable();

  public _trackChanges: BehaviorSubject<any> = new BehaviorSubject(null);
  public trackChanges: Observable<any> = this._trackChanges.asObservable();

  public _isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isLoading: Observable<boolean> = this._isLoading.asObservable();

  public _dataLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public dataLoading: Observable<boolean> = this._dataLoading.asObservable();

  public _weather: BehaviorSubject<any> = new BehaviorSubject(null);
  public weather: Observable<any> = this._weather.asObservable();

  public _filtering: BehaviorSubject<FilterMenus> = new BehaviorSubject(null);
  public filtering: Observable<FilterMenus> = this._filtering.asObservable();

  public _filterSelect: BehaviorSubject<any> = new BehaviorSubject(null);
  public filterSelect: Observable<any> = this._filterSelect.asObservable();

  private _recentGateways: BehaviorSubject<any> = new BehaviorSubject(null);
  public recentGateways: Observable<any> = this._recentGateways.asObservable();

  private _bytesIn: BehaviorSubject<BytesIn> = new BehaviorSubject(null);
  public bytesIn: Observable<BytesIn> = this._bytesIn.asObservable();

  private _visits: BehaviorSubject<Visits> = new BehaviorSubject(null);
  public visits: Observable<Visits> = this._visits.asObservable();

  private _telemetryCounts: BehaviorSubject<number[]> = new BehaviorSubject(null);
  public telemetryCounts: Observable<number[]> = this._telemetryCounts.asObservable();

  private _gateways: BehaviorSubject<Gateway[]> = new BehaviorSubject([]);
  public gateways: Observable<Gateway[]> = this._gateways.asObservable();

  private _issues: BehaviorSubject<Gateway[]> = new BehaviorSubject([]);
  public issues: Observable<Gateway[]> = this._issues.asObservable();

  private _pageOptions: BehaviorSubject<PageOption[]> = new BehaviorSubject(null);
  public pageOptions: Observable<PageOption[]> = this._pageOptions.asObservable();

  private _selectedPageOption: BehaviorSubject<PageOption> = new BehaviorSubject(null);
  public selectedPageOption: Observable<PageOption> = this._selectedPageOption.asObservable();

  private _engagement: BehaviorSubject<EngagementStore> = new BehaviorSubject(null);
  public engagement: Observable<EngagementStore> = this._engagement.asObservable();

  private _theme: BehaviorSubject<ThemeType> = new BehaviorSubject(null);
  public theme: Observable<ThemeType> = this._theme.asObservable();

  private setPointableAssets: Asset[] = [];
  private alarms: Alarm[] = [];

  private _dataChanged: BehaviorSubject<DataChanged> = new BehaviorSubject(null);
  public dataChanged: Observable<DataChanged> = this._dataChanged.asObservable();

  private _notificationChange: BehaviorSubject<any> = new BehaviorSubject(null);
  public notificationChange: Observable<any> = this._notificationChange.asObservable();

  private _filterMenuSelect: BehaviorSubject<any> = new BehaviorSubject(null);
  public filterMenuSelect: Observable<any> = this._filterMenuSelect.asObservable();

  public _menuAsText: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public menuAsText: Observable<boolean> = this._menuAsText.asObservable();

  constructor() { }

  setMenuAsText(isMenuAsText: boolean) {
    this._menuAsText.next(isMenuAsText);
  }

  // Holds assets available to user
  setAssets(assets: Asset[]) {
    this._assets.next(assets);
  }

  getAssets() {
    return this._assets.value;
  }

  setNotificationChange(changeSet: NotificationChangeset[]) {
    this._notificationChange.next(changeSet);
  }

  setFilterMenuSelect(menuItem: any) {
    this._filterMenuSelect.next(menuItem);
  }

  setRootContainer(value: any) {
    this._rootContainer.next(value);
  }

  change(value: any) {
    this._trackChanges.next(value);
  }

  getRootContainer(): any {
    const value = this._rootContainer.value.nativeElement;

    return value;
  }

  getPageWidth(): Dimension {
    const rc = this.getRootContainer();
    return { width: rc.clientWidth, height: rc.clientHeight };
  }

  setSectionBannerBack(state: SectionBannerBack) {
    if (state) {
      console.log(`setSectionBannerBack:${state.section}:${state.state}`);
    } else {
      console.log(`setSectionBannerBack:null`);
    }
    this._sectionBannerBack.next(state);
  }

  setSectionBannerChangedDetail(detail) {
    this._sectionBannerChanged.next({ detail: detail });
    console.log(`banner detail: ${detail}`);
  }

  setIsLoading(state: boolean) {
    this._isLoading.next(state);
  }

  setDataLoading(state: boolean) {
    this._dataLoading.next(state);
  }

  setGateways(gateways: Gateway[]) {
    this._gateways.next(gateways);
  }

  setOrgs(orgs: Org[]) {
    this._orgs.next(orgs);
  }

  setEngagementStore(engagement: EngagementStore) {
    this._engagement.next(engagement);
  }

  setIssues(gatewaysWithIssues: Gateway[]) {
    this._issues.next(gatewaysWithIssues);
  }

  setFiltering(filters: FilterMenus) {
    this._filtering.next(filters);
  }

  setFilterMenuItem(menuItem: any) {
    this._filterSelect.next(menuItem);
  }

  setDataChanged(target, action, data = null) {
    this._dataChanged.next({ target, action, data, timestamp: +new Date() });
  }

  setRecentGateways(gatewayTimestamps: any[]) {
    // Convert '00000001231234:1430002' into an array of {g:,t:}
    const gateways = gatewayTimestamps.map(r => { return { g: r.split(':')[0], t: r.split(':')[1] }; });
    if (gateways.length === 0) {
      // No gateways to push
      return;
    }
    if (this._recentGateways.value !== null && gateways[0].t === this._recentGateways.value[0].t) {
      // Last value is the same as the most recent
      return;
    }
    this._recentGateways.next(gateways);
  }

  setTelemetryCounts(numbers: number[]) {
    this._telemetryCounts.next(numbers);
  }

  setBytesIn(bytesIn: BytesIn) {
    this._bytesIn.next(bytesIn);
  }

  setVisits(visits: Visits) {
    this._visits.next(visits);
  }

  setPageOption(option: PageOption) {
    this._selectedPageOption.next(option);
    this._selectedPageOption.next(null);
  }

  setPageOptions(options: PageOption[]) {
    this._pageOptions.next(options);
  }

  setAlarms(alarms: Alarm[]) {
    this.alarms = alarms;
  }

  setNotifications(data: any) {

  }

  setTheme(theme?: ThemeType) {
    if (theme) {
      localStorage.setItem('theme', theme);
      console.log('set theme', theme);
    } else {
      theme = <ThemeType>localStorage.getItem('theme') || 'light';
    }
    this._theme.next(theme);
  }

  getTheme(onlyDarkOrLight = true) {
    if (onlyDarkOrLight) {
      return this._theme.value === 'light' ? 'light' : 'dark';
    } else {
      return this._theme.value;
    }
  }

  getThemeColours() {
    switch (this.getTheme()) {
      case 'light':
        return {
          background_color: "white",
          background_colour_muted: "#a0a0a0",
          background_colour_slightly_muted: "#f0f0f0",
          background_colour_menu_selected: "#dfe6ff",
          background_colour_menu_hover: "#f0f0f0",
          background_colour_tabs: "white",
          foreground_colour: "black",
          foreground_colour_muted: "#333",
          foreground_colour_menu: "#253dac",
          foreground_colour_menu_selected: "#2196f3",
          foreground_colour_menu_hover: "#253dac",
          foreground_colour_tabs: "#2196F3",
          border_color_muted: "#f0f0f0",
          border_colour_muted: "#f0f0f0",
          table_border_color: "#f0f0f0",
          table_header_color: "#333",
          table_hover_background_color: "#f5f5f5",
          table_hover_foreground_color: "black",
          toolbar_background_colour: "#f0f0f0",
          toolbar_foreground_colour: "#888",
          toolbar_border_border_colour_selected: "#2196F3",
          toolbar_button_secondary_background_colour: "#fefefe",
          toolbar_button_secondary_foreground_colour: "black",
          toolbar_button_selected_background_colour: "blue",
          toolbar_button_selected_foreground_colour: "white",
          button_primary_foreground_colour: "#607D8B",
          button_primary_background_colour: "transparent",
          button_secondary_background_colour: "#e0e0e0",
          button_secondary_foreground_colour: "black",
          search_background_colour: "white",
          search_foreground_colour: "#495057",
          search_border_colour: "#e0e0e0",
          radios_background_colour: "#f0f0f0",
          link_foreground_colour: "rgb(0, 0, 238)",
          info_panel_background_colour: "#f2f2f2",
          info_panel_foreground_colour: "#888",
          scrollbar_foreground_colour: "silver",
          dropdown_background_colour: "white",
          dropdown_foreground_colour: "black",
          select_background_colour: "white",
          select_foreground_colour: "black"
        };
      case 'dark':
        return {
          background_colour: "black",
          background_colour_slightly_muted: "#222",
          background_colour_muted: "#444",
          background_colour_menu_selected: "rgb(14, 13, 13)",
          background_colour_menu_hover: "orange",
          background_colour_tabs: "black",
          foreground_colour: "#fafafa",
          foreground_colour_muted: "#f0f0f0",
          foreground_colour_menu: "#81bae7",
          foreground_colour_menu_selected: "orange",
          foreground_colour_menu_hover: "white",
          foreground_colour_tabs: "#82c4fa",
          border_color_muted: "#202020",
          border_colour_muted: "#202020",
          table_border_color: "#333",
          table_header_color: "#f0f0f0",
          table_hover_background_color: "orange",
          table_hover_foreground_color: "white",
          table_row_odd_background_colour: "#222",
          toolbar_background_colour: "#333",
          toolbar_foreground_colour: "white",
          toolbar_border_border_colour_selected: "#2196F3",
          toolbar_button_secondary_background_colour: "#888",
          toolbar_button_secondary_foreground_colour: "black",
          toolbar_button_selected_background_colour: "orange",
          toolbar_button_selected_foreground_colour: "black",
          button_primary_foreground_colour: "orange",
          button_primary_background_colour: "transparent",
          button_secondary_background_colour: "#222",
          button_secondary_foreground_colour: "white",
          search_background_colour: "#222",
          search_foreground_colour: "white",
          search_border_colour: "#333",
          radios_background_colour: "#222",
          link_foreground_colour: "orange",
          info_panel_background_colour: "#333",
          info_panel_foreground_colour: "#f0f0f0",
          scrollbar_foreground_colour: "orange",
          dropdown_background_colour: "black",
          dropdown_foreground_colour: "#f0f0f0",
          select_background_colour: "#333",
          select_foreground_color: "#f0f0f0"
        }
    }
  }

  getSetpointableAssets() {
    return this.setPointableAssets;
  }

  setSetpointableAssets(assets: Asset[]) {
    this.setPointableAssets = assets;
  }

  clearStorage() {
    this.setPointableAssets = [];
  }

  getOrgs(): Org[] {
    return this._orgs.value || [];
  }

  getOrg(orgId: number): Org {
    return this._orgs.value.find(o => o.id === orgId);
  }
}

export interface BytesIn {
  monthly: number;
  today: number;
  total: number;
}

export interface Visits {
  today: number;
  now: number;
}

export interface EngagementStore {
  startsAt?: Date;
  endsAt?: Date;
  view?: string;
  wantsAdjustment: boolean;
  users: any[];
  calendarDates?: IDateConfig;
}

export interface Dimension {
  width: number;
  height: number;
}

export interface DataChanged {
  target: string;
  action: string;
  data: any;
  timestamp: number;
}

export interface NotificationChangeset {
  target: string;
  data: any;
}

export type ThemeType = 'dark' | 'light' | 'dark-fourd';
