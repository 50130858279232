import { Component, OnInit, Input, signal } from '@angular/core';
import { StoreService, ThemeType } from 'app/shared/store.service';

@Component({
  selector: 'app-please-wait',
  templateUrl: './please-wait.component.html',
  styleUrls: ['./please-wait.component.css']
})
export class PleaseWaitComponent implements OnInit {

  _px = signal(21);
  _message = signal('Please Wait');

  @Input()
  public set message(v: string) {
    this._message.set(v);
    this._px.set(8);
  }

  _theme = signal<ThemeType>(null);

  constructor(private storeService: StoreService) {
    this._theme.set(storeService.getTheme());
  }

  ngOnInit(): void {
  }

}
