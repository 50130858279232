import { AssetTicket } from "./asset-ticket";
import { GatewayTicket } from "./gateway-ticket";
import { SiteTicket } from "./site-ticket";

import { Ticket } from "./ticket";
import { Asset, Camera } from "./asset";

export enum TicketKind {
    Site,
    Gateway,
    Asset
}

export enum AssetKind {
    Asset,
    Camera
}
export enum PurposeKind {
    Timelapse,
    LightMonitor
}

export function createTicket(kind: TicketKind, data?: any): Ticket {
    switch (kind) {
        case TicketKind.Asset:
            return new AssetTicket(data);
        case TicketKind.Site:
            return new SiteTicket(data);
        case TicketKind.Gateway:
            return new GatewayTicket(data);
    }
}

export function createAsset(kind: AssetKind, data?: any): Asset | Camera {
    switch (kind) {
        case AssetKind.Camera:
            return new Camera(data);
        default:
            return new Asset(data);
    }
}
