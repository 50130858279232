@defer( when _custom()) {
<app-toolbar size="small">
	<div class="left">
		<app-button tooltipPosition="top" size="small" [selected]="_custom().type==='today'" (click)="dateTypeSelect('today')" [isDisabled]="!enabled.today" label="Today" [pTooltip]="enabled.today ? '' : 'No data today'" />
		<app-button tooltipPosition="top" size="small" [selected]="_custom().type==='yesterday'" (click)="dateTypeSelect('yesterday')" [isDisabled]="!enabled.yesterday" label="Yesterday" [pTooltip]="enabled.yesterday ? '' : 'No data yesterday'" />
		<app-button tooltipPosition="top" size="small" [selected]="_custom().type==='thisweek'" (click)="dateTypeSelect('thisweek')" [isDisabled]="!enabled.thisweek" label="This	week" [pTooltip]="enabled.thisweek ? '' : 'No data this week'" />
		<app-button tooltipPosition="top" size="small" [selected]="_custom().type==='lastweek'" (click)="dateTypeSelect('lastweek')" [isDisabled]="!enabled.lastweek" label="Last week" [pTooltip]="enabled.lastweek ? '' : 'No data last week'" />

		<app-button tooltipPosition="top" size="small" [selected]="_custom().type==='lastx'" (click)="dateTypeSelect('lastx')" [label]="lastXLabel" [pTooltip]="lastXTitleFromUpdate" />

		<app-setpoint-dates-button [asset]="asset" [selected]="_custom().type==='custom'" size="small" [value]="custom" (onSelect)="datesDialogClosed($event)" [minmax]="minmax()" />
	</div>
	<div class="right">
		<p-menu #menu [popup]="true" [model]="items" (onClick)="onClickMenu($event)"></p-menu>
		<button class="btn btn-sm" (click)="menu.toggle($event)" *ngIf="items"><i class="mdi mdi-dots-vertical"></i></button>
	</div>

</app-toolbar>
} @placeholder (minimum 100ms) {
<app-toolbar></app-toolbar>
}
