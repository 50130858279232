@defer() {
<div [class.caption]="_caption()">
	@if(_caption()) {
	<label>{{_caption()}}</label>
	}
	<app-button [size]="size" [label]="label()" (click)="showDialog()"></app-button>
</div>
} @placeholder (minimum 100ms) {}
@if(_onDialog() ) {
<app-dialog title="Condition" (onClose)="conditionClosed($event)" [canSave]="true" [dimensions]="{width:480, height:330}">
	<app-data-form [isDebugging]="false" [dataForm]="_triggerDataForm()"></app-data-form>
</app-dialog>
}
