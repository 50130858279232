import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-theme-page-container',
  templateUrl: './theme-page-container.component.html',
  styleUrls: ['./theme-page-container.component.css']
})
export class ThemePageContainerComponent implements OnInit {

  @Input()
  canNavigateBack: boolean;

  @Input()
  canClose: boolean;

  @Input()
  label: string;

  @Input()
  titleLink: any[];

  @Input()
  logo: 'elogbooks';

  @Output()
  onBack: EventEmitter<any> = new EventEmitter();


  @Output()
  onClose: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigate() {
    if (this.canClose) {
      this.close();

      return;
    }
    if (this.titleLink) {
      this.router.navigate(this.titleLink);
    } else {
      this.onBack.emit(true);
    }
  }

  close() {
    this.onClose.emit(true);
  }
}
