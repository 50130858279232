import { Asset } from "./asset";
import { ProfilingHistoryItem } from "./profiling-history-item";
export class ProfileAsset {
    grouping: any[];
    asset: Asset;
    history: ProfilingHistoryItem[];
    plotX: number;
    plotY: number;
    colour: string;
    
    fontSize: number;
    path: string;
    gateway_profile_id: number;
    constructor(data? : any) {
        this.asset = new Asset();
        this.history = [];
        if (data) {
            this.asset.title = data.assetTitle;
            this.asset.id = data.asset_id;
            this.asset.identifier = data.assetIdentifier;
            this.asset.assetType_id = data.assetType;
            this.asset.location = data.assetLocation;
            this.asset.value = data.assetValue;
            this.asset.maxAlert = data.maxAlert;
            this.asset.minAlert = data.minAlert;
            this.fontSize = data.fontSize;
            this.plotX = data.valueX;
            this.plotY = data.valueY;
            this.colour = data.colour;
            this.path = data.path;
            this.gateway_profile_id = data.gateway_profile_id;
            this.transformAssetValue(this.asset);
        } else {

        }
    }
    /**
     * Transform asset values to user freindly
     */
    transformAssetValue(asset) {
        switch(asset.assetType_id) {
            case 17: //door
            if (!isNaN(asset.value)) {
                // For numerical values use the string
                asset.value = ['closed', 'open'][+asset.value];
            }
            break;
        }
    }
}