export class Point {
    x: number;
    y: number;
    width?: number;
    height?: number;
    value?: any;
    title?: string;
    selected?: boolean;
    
    static  mouseToSVGCords(svgElement: any, clientX: number, clientY: number) {
        //var svg = document.querySelector('svg');
        var pt = svgElement.createSVGPoint();
        pt.x = clientX;
        pt.y = clientY;
        return pt.matrixTransform(svgElement.getScreenCTM().inverse());
    }

    constructor(x: number, y: number, width?: number, height?: number) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        if (width < 0) {
            this.width = Math.abs(width);
            console.log(`${width} was negative`);
        }
    }

    public toString = (): string => {
        if (this.width) {
            return `${this.x} ${this.y} ${this.width} ${this.height}`;
        }

        return `${this.x} ${this.y}`;
    }


}
