@if(isLoading()) {
<app-please-wait></app-please-wait>
} @else {
<div>
    <div class="slider" [hidden]=" selectedAsset">
        <div class="component">
            <div>
                <div class="search" *ngIf="filtered.text && assets">
                    <app-search-input [searchText]="filtered.text.assets" (onSearchChanged)="searchChanged('assets',  $event)" tooltipText="Search assets" [resultCount]="filteredCount()">
                    </app-search-input>
                </div>
            </div>
        </div>
        <div class="asset-types">
            <div class="icon fa fa-fw fa-thermometer-quarter" [class.deselected]="!selected.options.temperature" (click)="toggle('temperature')" title="Temperature "></div>
            <div class="icon fa fa-fw fa-tint" (click)="toggle('humidity')" [class.deselected]="!selected.options.humidity" title="Humidity"></div>
            <div class="icon fa fa-fw fa-lightbulb-o" (click)="toggle('lux')" [class.deselected]="!selected.options.lux" title="Light"></div>
            <div class="icon fa fa-fw fa-compress" (click)="toggle('pressure')" [class.deselected]="!selected.options.pressure" title="Pressure"></div>
            <div class="icon fa fa-fw fa-arrows-v" (click)="toggle('distance')" [class.deselected]="!selected.options.distance" title="Distance"></div>
            <div>
                <div class="rag-required">
                    <div class="ooh" [class.dark-red]="rangeId===3">
                        <span class="mdi" style="opacity:1" [class.mdi-office-building-outline]="rangeId===2" [class.mdi-weather-night]="rangeId===3"></span>
                        <select [(ngModel)]="rangeId" (change)="rangeChanged()">
                            <option [ngValue]="2">Operational hours</option>
                            <option [ngValue]="3">Out of hours</option>
                        </select>
                    </div>

                    <!--

                    [ngClass]="{'mdi-checkbox-marked-outline':selected.options.red, 'mdi-checkbox-blank-outline':!selected.options.red}"
                    [class.mdi-checkbox-marked-outline]="" [class.mdi-checkbox-blank-outline]=""
                -->

                    <span pTooltip="Setpoints at RED" tooltipPosition="bottom" (click)="toggle('red')" class="ml-1" [class.active]="selected.options.red">
                        <i class="mdi mdi-checkbox-blank red"></i> <i class="mdi" [ngClass]="{'mdi-checkbox-marked-outline':selected.options.red, 'mdi-checkbox-blank-outline':!selected.options.red}"></i>
                    </span>

                    <span pTooltip="Setpoints at AMBER" tooltipPosition="bottom" (click)="toggle('amber')" [class.active]="selected.options.amber">
                        <i class="mdi mdi-checkbox-blank amber"></i> <i class="mdi" [ngClass]="{'mdi-checkbox-marked-outline':selected.options.amber, 'mdi-checkbox-blank-outline':!selected.options.amber}"></i>
                    </span>

                    <span pTooltip="Setpoints at GREEN" tooltipPosition="bottom" (click)="toggle('green')" [class.active]="selected.options.green">
                        <i class="mdi mdi-checkbox-blank green"></i> <i class="mdi" [ngClass]="{'mdi-checkbox-marked-outline':selected.options.green, 'mdi-checkbox-blank-outline':!selected.options.green}"></i>
                    </span>

                    <span pTooltip="Setpoints with alarms" tooltipPosition="bottom" (click)="toggle('bell')" [class.active]="selected.options.bell">
                        <i class="fa fa-fw fa-bell"></i> <i class="fa fa-fw" [class.fa-check-square-o]="selected.options.bell" [class.fa-square-o]="!selected.options.bell"></i>
                    </span>
                </div>
                <select (change)="setpointFilterChange()" [(ngModel)]="setpointFilter">
                    <option value="all">All Setpoints</option>
                    <option value="configured">Configured </option>
                    <option value="unset">Not configured</option>
                </select>
            </div>
        </div>
    </div>
    @if(assets?.length) {
    <div class="content-container">
        <table class="table table-sm table-hover sticky" aria-label="Assets">
            <thead [hidden]="selectedAsset">
                <tr>
                    @if(columns.org) {
                    <th class="isDesktop">Org</th>
                    }
                    <th class="isDesktop">Site</th>
                    <th>Gateway</th>
                    <th>Asset</th>
                </tr>
                <tr>
                    <th *ngIf="isInMultiSelect">
                        <i class="fa fa-fw" [class.fa-check-square-o]="true" (click)="selectAllToggle()"></i>
                    </th>
                    <th class="isDesktop org" *ngIf="columns.org">
                        <ng-template [ngIf]="orgs">
                            <select name="orgs" class="form-control" [(ngModel)]="selected.org.shortTitle" (change)="orgChanged($event)">
                                <option value="">All</option>
                                <option *ngFor="let org of orgs" [value]="org.shortTitle" title="{{org.title}}">
                                    {{org.shortTitle}}
                                </option>
                            </select>
                        </ng-template>
                    </th>
                    <th class="site isDesktop">
                        <select name="sites" class="form-control" id="sites" [(ngModel)]="selected.site.title" (change)="siteChanged($event)">
                            <option value="">All</option>
                            <option *ngFor="let site of sites" [value]="site.title">{{site.title}}</option>
                        </select>
                    </th>
                    <th class="gateway">
                        <select name="gateways" class="form-control" id="gateways" [(ngModel)]="selected.gateway.title" (change)="gatewayChanged($event)">
                            <option value="">All</option>
                            <option *ngFor="let gateway of gateways" [value]="gateway.title">{{gateway.title}}</option>
                        </select>
                    </th>
                    <th></th>
                </tr>
                <tr *ngIf="isInMultiSelect">
                    <th colspan="5">
                        <div class="multiselect-toolbar">
                            <span (click)="disableSetpoints()"><i class="mdi mdi-bell-off"></i> Disable selected</span> &nbsp;
                        </div>
                    </th>
                </tr>

            </thead>
            <tbody>
                @for(asset of filteredAssets; track asset.id; let i=$index) {
                <tr class="asset" [class.was-selected]="asset.id === selected.asset.id" [class.no-title]="i>0 && asset.gateway.site.title === filteredAssets[i-1].gateway.site.title" [class.hassetpoints]="asset.setpointCount" [class.hassetpointrules]="asset.setpointRuleCount" (click)="selectAsset($event, asset)" [class.unfiltered]="asset.filtered===false">

                    <td *ngIf="isInMultiSelect" (click)="asset.isSelected = !asset.isSelected">
                        <i class="fa fa-fw" [class.fa-square-o]="!asset.isSelected" [class.fa-check-square-o]="asset.isSelected"></i>
                    </td>

                    <td class="logo isDesktop org" *ngIf="columns.org">
                        <span *ngIf="(asset.gateway.site.org.title) && (i===0 || asset.gateway.site.title !== filteredAssets[i-1].gateway.site.title)" pTooltip="{{asset.gateway.site.org.title}}">
                            {{asset.gateway.site.org.shortTitle}}
                        </span>
                    </td>

                    <td class="site" class="isDesktop">
                        <span *ngIf="i===0 || asset.gateway.site.title !== filteredAssets[i-1].gateway.site.title">
                            <b>{{asset.gateway.site.title}}</b>
                        </span>
                    </td>
                    <td class="gateway"><span></span>{{asset.gateway.title}}</td>
                    <td class="asset-title"><i class="fa fa-square rag {{asset.rag}}"></i>

                        <span><i class="fa fa-fw fa-bell"></i></span> {{asset.title}}
                    </td>
                </tr>
                }
            </tbody>
        </table>
    </div>
    }
    @if(filteredAssets?.length === 0) {
    <div>
        <!--
        <div [ngSwitch]="setpointFilter">
            <p *ngSwitchCase="'all'"> </p>
            <p class="can-click" *ngSwitchCase="'configured'" pTooltip="Click to list sensors to configure">
                <app-info-panel title="No sensors configured" (click)="setpointFilterChange('unset')">
                    You need to configure a sensors setpoints for them to appear here, <span class="link">click
                        here</span> to show sensors not configured
                </app-info-panel>
            </p>
            <p class="can-click" *ngSwitchCase="'unset'" pTooltip="Click to list configured sensors">
                <app-info-panel title="All sensors are configured" (click)="setpointFilterChange('configured')">All of
                    your sensors for this view are configured.
                </app-info-panel>
            </p>
        </div> -->
    </div>
    }
    <p style="opacity: .1;text-align: right;font-style: italic;font-size: 12px;">
        <span pTooltip="[CTRL] + click a row to multi select ">[CTRL] + click a row to multi select.</span>
    </p>

</div>
}
