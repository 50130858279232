<main class="fullscreen">
	<nav>
		<div class="title" (click)="onGoBack.emit(true)">
			<span ptooltip="Back">
				<i class="fa fa-fw fa-chevron-left"></i>
			</span>
			<span title="{{asset.id}}">{{asset.title}} Dashboard</span>
		</div>
	</nav>
	<section>
		<div class="value">
			{{asset.value}}
		</div>
	</section>

</main>


<!--
		<ul>
			<li title="#{{asset.id}}">
				<label>value</label>
		
			</li>
			<li>
				<label></label>
			</li>
		</ul>
	-->