import { Site } from "./site";
import { Address } from "./address";
import { OrderSurveyItem } from "./order-survey-item";
export class Order {
    id: number;
    site_id: number;
    siteTitle: string;
    status: string;
    site: Site;
    createdAt: Date;
    deliverAt: Date;
    installAt: Date;
    user_id: number;
    org_id: number;
    surveyItems: OrderSurveyItem[];
    constructor(data?: any) {
        if (data) {
            // Import the order details
            let details = data[0] ? data[0][0] : data;
            this.id = details.id;
            this.status = details.status;
            this.site_id = details.site_id;
            this.installAt = details.installAt ? new Date(details.installAt) : null;
            this.createdAt = details.createdAt ? new Date(details.createdAt) : null;
            this.deliverAt = details.deliverAt ? new Date(details.deliverAt) : null;
            this.siteTitle = details.siteTitle; 
            this.site = new Site();
            this.site.id = details.site_id;
            this.site.title = details.siteTitle;
            this.site.address = new Address();
            /* deprecated address */
            this.site.address.addressId = details.siteAddressId;
            this.site.address.address1 = details.siteAddress1;
            this.site.address.address2 = details.siteAddress2;
            this.site.address.address3 = details.siteAddress3;
            this.site.address.addressCounty = details.siteAddressCounty;
            this.site.address.addressPostcode = details.siteAddressPostcode;
            this.site.address.addressTitle = details.siteAddressTitle;
            // Import the inventory items
            this.surveyItems = [];
            if (data[1]) {
                data[1].forEach(s => {
                    let orderSurveyItem = new OrderSurveyItem(s);
                    this.surveyItems.push(orderSurveyItem);
                });
            }

        }
    }
}
