import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import moment from 'moment';
import { Annotation } from '../classes/annotation';
import { Packet } from '../classes/packet';
import { Weather } from '../classes/weather';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class D3ChartService {

  private _chartCompleted: BehaviorSubject<IChartCompleted> = new BehaviorSubject(null);
  public chartCompleted: Observable<IChartCompleted> = this._chartCompleted.asObservable();

  constructor(private apiService: APIService) { }

  getTelemetry(assetId: number, df: Date, dt: Date, setpoints: any[], combineWeather = false): Promise<GetTelemetry> {
    return new Promise((resolve, reject) => {
      let packets: Packet[] = [];
      let annotations: Annotation[] = [];
      let dfdb, dtdb;

      dfdb = this.apiService.dbDate(df);
      dtdb = this.apiService.dbDate(dt);

      const q = `c=id as i,value as v,createdAt as d&df=${dfdb}&dt=${dtdb}&w=1&q=1&`;
      this.apiService.getTelemetry(assetId, 15000, q)
        .then(dataIn => {

          let weather: Weather[] = dataIn[1];
          let packets: Packet[] = dataIn[0];

          if (weather) {
            weather = weather.map(w => {
              w.d = new Date(w.d);
              w.h = w.h * 100;

              return w;
            });
          }

          if (packets) {
            packets = packets.map(packet => {
              if (!setpoints) {
                return packet;
              }

              const v = packet.v;
              const dt = moment(packet.d);
              const dw = dt.get('day') === 0 ? 6 : dt.get('day') - 1;
              const rag = setpoints[dw];
              const time = +(dt.format('HHmm'));
              let r = 'green';

              if (rag && rag.isActive) {
                const startsAt: number = rag.allday ? 0 : +(rag.startsAt.split(':'))[0] + (rag.startsAt.split(':'))[1];
                const endsAt: number = rag.allday ? 2359 : +(rag.endsAt.split(':'))[0] + (rag.endsAt.split(':'))[1];
                if (rag.allday || (time >= startsAt && time <= endsAt)) {
                  // If no value exists ignore
                  if (rag.amber_min !== null && v <= rag.amber_min) {
                    r = 'amber';
                  }
                  if (rag.amber_max !== null && v >= rag.amber_max) {
                    r = 'amber';
                  }
                  if (rag.red_min !== null && v <= rag.red_min) {
                    r = 'red';
                  }
                  if (rag.red_max !== null && v >= rag.red_max) {
                    r = 'red';
                  }
                  packet.inScope = true;
                } else {
                  packet.inScope = false;
                }
              }
              packet.rag = r;
              packet.d = new Date(packet.d);
              packet.v = +packet.v;

              if (combineWeather) {
                packet.w = weather.find(w => +w.d <= +dt);
              }

              return packet;
            }).sort((a, b) => a.d > b.d ? 1 : -1);
          }
          if (packets?.length) {
            // No weather beyond the chart bounds
            if (weather && weather.length) {
              weather = weather.filter(w => packets.findIndex(p => p.d >= w.d) !== -1).reverse();
              // Make sure the last hours weather is at the end of the chart
              weather.push({ ...weather[weather.length - 1], d: packets[packets.length - 1].d });
            }
            this.apiService
              .getAssetAnnotations(assetId, packets[0].i, packets[packets.length - 1].i)
              .then(annotations => {
                resolve({ annotations, packets, weather: weather });
              });
          } else {
            resolve({ annotations, packets, weather: [] });
          }
        });
    });
  };

  setChartCompleted(data: IChartCompleted) {
    this._chartCompleted.next(data);
  }
}

export interface GetTelemetry {
  packets: Packet[];
  annotations: Annotation[];
  weather: ITelemetryWeather[];
}

export interface IChartCompleted {
  id: number;
  min: number;
  max: number;
  chartMin: number;
  chartMax: number;
}


export interface ITelemetryWeather {
  i: string; // icon
  t: number; // Temperature
  d: Date; // Date
}
