@defer(when days()) {
<article [ngClass]="{ 'mini': mini}">
	<header class="day">
		<div>
			<span *ngIf="!mini">Date</span>
		</div>

		@for(hour of days()[0].hours; track $index; let i = $index) {
		<div class="hour" tooltipPosition="top" pTooltip="{{i}}:00 to {{i}}:59">
			{{i}}
		</div>
		}
	</header>

	@for(day of days(); track $index) {
	<div class="day">
		<div>
			<span *ngIf="!mini">{{day.datetime | date:'EEE dd MMM YY' }}</span>
		</div>
		@if(day.collectionForDow) {
		@for(hour of day.hours;let i = $index; track $index) {
		<div class="hour" [class]=" (i >= day.collectionForDow.startHour && i < day.collectionForDow.endHour) ? ' rating rating-' + day.rating : 'no-rating' ">
			<span>{{hour.d}}</span>
		</div>
		}
		} @else {
		<div class="hour no-rating" *ngFor="let hour of day.hours;let i = index">
			<span>{{hour.d}}</span>
		</div>
		}
	</div>
	}
</article>
} @placeholder( minimum 1000ms) {
<i class="mdi mdi-loading mdi-spin-x2"></i>
}
