import { Injectable } from '@angular/core';
import { APIService, PostAlarmInterface } from './api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Grouping } from 'app/classes/grouping';
import { StoreService } from './store.service';
import { Asset } from 'app/classes/asset';
import { AssetService } from './asset.service';
import { SetpointTemplateCategory } from "app/classes/setpoint-template-category";
import { SetpointTemplate } from "app/classes/setpoint-template";
import { SetpointTemplateItemComponent } from 'app/setpoints/setpoint-templates/setpoint-template-item/setpoint-template-item.component';
import { SetpointSchedule } from 'app/classes/setpoint-schedule';

@Injectable({
	providedIn: 'root'
})
export class SetpointsService {
	API_URL = 'https://7e8g4nd2rj.execute-api.eu-west-2.amazonaws.com/4d/';

	static readonly RANGE_TEXT = ['none', '24 Hours', ' In operational hours', 'Out of operational hours'];

	constructor(private assetService: AssetService, private apiService: APIService, private http: HttpClient, private storeService: StoreService) { }

	async getList(rag?: string): Promise<IGetList[]> {
		const qs = rag ? `rag=${rag}` : '';
		console.log('getList()');
		return await this.get(qs);
	}

	/**
	 * disable Setpoints
	 * @param {number[]} setpoint id
	 */
	disableSetpoints(assetIdList: number[], rangeId: 2 | 3): Promise<any> {
		console.log(`disableSetpoints(${assetIdList.join(',')})`);

		return this.http
			.delete<any>(this.API_URL, { ...this.apiService.getUAOHeaders(), body: { action: 'setpoints_disable', rangeId, assets: assetIdList } })
			.toPromise()
			.catch(this.apiService.handleError);
	}

	/**
	 * delete setpoints
	 * @param {number[]} setpoint id
	 */
	deleteSetpoints(assetIdList: number[], rangeId: 2 | 3): Promise<any> {
		console.log(`deleteSetpoints(${assetIdList.join(',')})`);

		return this.http
			.delete<any>(this.API_URL, { ...this.apiService.getUAOHeaders(), body: { action: 'setpoints_delete', rangeId, assets: assetIdList } })
			.toPromise()
			.catch(this.apiService.handleError);
	}

	getSetpointableAssetsForUser(rangeId = 2): Promise<any[]> {
		return new Promise((resolve) => {
			if (this.storeService.getSetpointableAssets().length) {
				resolve(this.storeService.getSetpointableAssets());
			} else {
				this.assetService.getAssetsForUser(null, '%22site_title%22:1%2C%22gatewayTitle%22:1%2C%22title%22:1', 'setpointable')
					.then(r => {
						// Only interested in assets that can be used here
						this.storeService.setSetpointableAssets(r.filter(v => v.isSetpointable));

						resolve(this.storeService.getSetpointableAssets());
					});
			}
		});
	}

	getSetpoints(asset: Asset) {
		return this.getSetpointsForAssetAndRange(asset, 2).then(values => values);
	}

	/**
	 * Get assets with setpoints of rangeType provided (1 = operational rules)
	 * @param {number} rangeType
	 */
	getAssetsWithSetpointsForUser(rangeType: number = 1): Promise<Asset[]> {
		console.log('getAssetsWithSetpointsForUser()');
		const options = { headers: this.apiService.getHttpHeaders() };
		return this.http.get<any[]>(this.apiService.apiUrl + 'orgs/' + this.apiService.getUserOrg().id + '/assets?sort=%7B%22gateway_id%22:1%2C%22satellite_id%22:1%7D&setpoints=', options)
			.toPromise()
			.then((response) => {
				const body = response;
				const assets = body.map(asset => this.assetService.createAssetObject(asset));
				this.storeService.setAssets(assets);

				return assets;
			})
			.catch(this.apiService.handleError);
	}

	/**
	 * Get the setpoint asset ids that fall within the time range
	 * - used to filter out a list of setpoint assets
	 * - 24 hour setpoints are ignored as all assets are included on 24hr
	 * @param {string} timeFrom
	 * @param {string} timeTo
	 */
	getAssetsWithSetpointsForTime(rangeId: number, timeFrom: string, timeTo: string): Promise<any[]> {
		console.log('getAssetsWithSetpointsForTime()');
		let columns = encodeURIComponent('ar.id');
		let filter = encodeURIComponent(``);
		const options = { headers: this.apiService.getHttpHeaders() };

		/*
				let data = {
					filter: [{ c: 'startsAt', v: timeFrom, o: '>=' }, { c: 'endsAt', v: timeTo, o: '<=' }],
					columns: ''
				};
		*/

		let data = { startsAt: timeFrom, endsAt: timeTo };

		return this.http.post(this.apiService.getUserAPI() + '/assets/setpoints/range/' + rangeId, data, options)
			.toPromise()
			.then((response) => {

				return response;
			})
			.catch(this.apiService.handleError);
	}

	getSetpointsForAssetAndRange(asset: Asset, rangeId: number): Promise<any> {
		// console.log('getSetpointsForAsset()');
		const options = { headers: this.apiService.getHttpHeaders() };
		const orgId = asset.gateway.site.org.id;

		return this.http.get<any[]>(this.apiService.apiUrl + `gateways/${asset.gateway_id}/assets/${asset.id}/setpoints?v=2&rules=1&master=1&groupings=1&contacts=1&range=${rangeId}&orgid=${orgId}`, options)
			.toPromise()
			.then((response) => {
				let [setpointRows, rules, master, contacts, groupings] = response;
				// console.log('PRE RULES', rules);
				// Strip out ooh tag, the rangeid is used instead
				for (let index = 0; index < rules.length; index++) {
					rules[index].tag = rules[index].tag.replace(' ooh', '');
				}
				// console.log('POST RULES', rules);

				let setpoints = [];
				for (let idx = 0; idx < 7; idx++) {
					setpoints.push({ weekday: idx, startsAt: null, endsAt: null, allday: false, isActive: false, min: null, max: null, amber_min: null, amber_max: null, red_min: null, red_max: null });
				}
				if (setpointRows && setpointRows.length) {
					setpointRows.forEach((sp) => setpoints[sp.weekday] = sp);
				} else {
					// console.log('ERROR ', setpointRows);
				}

				if (master.length === 0) {
					master.push({ notes: '' });
				}

				return { setpoints, rules, master: master[0], contacts, groupings };
			})
			.catch(this.apiService.handleError);
	}

	setSetPointsForAsset(asset: Asset, rangeId: number, setpoints: any[], alarms: PostAlarmInterface, master: any, groupings: any): Promise<any> {
		const options = { headers: this.apiService.getHttpHeaders() };
		const url = this.apiService.getUserAPI() + '/assets/' + asset.id + '/setpoints/ranges/' + rangeId;
		const payload = { setpoints, alarms, master, groupings };
		if (!master || !groupings) {
			console.error('NO_MASTER_OR_GROUPINGS', master, groupings);
			return;
		}
		return this.http.post(url, payload, options)
			.toPromise()
			.then(response => {

				return response;
			})
			.catch(this.apiService.handleError);
	}

	/**
	 * Get setpoint values by day for the number of days - starting with today.
	 * @param asset
	 * @param rangeId
	 * @param days
	 */
	getSetPointsHistoryForAsset(asset: Asset, rangeId: number, days: number): Promise<any> {
		return this.http.get<any[]>(this.apiService.getUserAPI() + `/assets/${asset.id}/setpoints/ranges/${rangeId}/history?days=${days}`, { headers: this.apiService.getHttpHeaders() })
			.toPromise()
			.then(response => response)
			.catch(this.apiService.handleError);
	}

	/**
	 * Get setpoint values by day for the number of days - starting with today.
	 * @param asset
	 * @param rangeId
	 * @param days
	 */
	getSetPointsHistoryForUserOrg(rangeId: number, days: number): Promise<any> {
		return this.http.get<any[]>(this.apiService.getUserAPI() + `/assets/setpoints/range/${rangeId}/history?days=${days}`, { headers: this.apiService.getHttpHeaders() })
			.toPromise()
			.then(response => response)
			.catch(this.apiService.handleError);
	}

	async getSchedules(): Promise<SetpointSchedule[]> {
		return new Promise((resolve) => {
			console.log('getSchedules()');
			const url = `${this.API_URL}schedules`;

			return this.http
				.get<any>(url, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(<SetpointSchedule[]>b.map(item => new SetpointSchedule(item)));
				});
		});
	};

	async getSchedule(id: number, options?: { wantsSitesAndAssets: boolean }): Promise<SetpointSchedule> {
		return new Promise((resolve) => {
			console.log('getSchedule()');
			const url = `${this.API_URL}schedules`;
			const params = new HttpParams().set('id', id);
			if (options?.wantsSitesAndAssets) {
				params.set('complete', 1);
			}
			return this.http
				.get<any>(url, { ...this.apiService.getUAOHeaders(), params })
				.subscribe(item => {
					resolve(new SetpointSchedule(item));
				});
		});
	};

	async getTemplates(): Promise<SetpointTemplate[]> {
		return new Promise((resolve) => {
			console.log('getTemplates()');
			const url = `${this.API_URL}templates`;

			return this.http
				.get<any>(url, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(<SetpointTemplate[]>b.map(item => new SetpointTemplate(item)));
				});
		});
	};

	async getTemplate(templateId: number): Promise<SetpointTemplate> {
		return new Promise((resolve) => {
			console.log('getTemplate()');
			const url = `${this.API_URL}templates`;
			const params = new HttpParams().set('id', templateId);

			return this.http
				.get<any>(url, { ...this.apiService.getUAOHeaders(), params })
				.subscribe(b => {
					resolve(new SetpointTemplate(b));
				});
		});
	};

	async deleteTemplate(templateId: number): Promise<any> {
		return new Promise((resolve) => {
			console.log('deleteTemplate()');
			const url = `${this.API_URL}templates`;
			const params = new HttpParams().set('id', templateId);

			return this.http
				.delete<any>(url, { ...this.apiService.getUAOHeaders(), params })
				.subscribe(b => {
					resolve(new SetpointTemplate(b));
				});
		});
	};

	async deleteSchedule(scheduleId: number): Promise<any> {
		return new Promise((resolve) => {
			console.log('deleteTemplate()');
			const url = `${this.API_URL}schedules`;
			const params = new HttpParams().set('id', scheduleId);

			return this.http
				.delete<any>(url, { ...this.apiService.getUAOHeaders(), params })
				.subscribe(b => {
					resolve(new SetpointTemplate(b));
				});
		});
	};

	async getTemplateCategories(): Promise<SetpointTemplateCategory[]> {
		return new Promise((resolve) => {
			console.log('getTemplateCategories()');
			const url = `${this.API_URL}templates/categories`;

			return this.http
				.get<any>(url, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b.map(item => new SetpointTemplateCategory(item)));
				});
		});
	};

	async updateAsset(assetId: number, rangeId, setpoints, alarms, master, groupings: Grouping[]): Promise<any> {
		return new Promise((resolve) => {
			console.log('updateAsset()');
			const url = `${this.API_URL}${assetId}`;
			const body = { setpoints, alarms, master, rangeId, groupings };

			return this.http
				.post<any>(url, body, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

	async updateTemplateCategory(setpointTemplateCategory: SetpointTemplateCategory): Promise<any> {
		return new Promise((resolve) => {
			console.log('updateTemplateCategory()');
			const url = `${this.API_URL}/templates/categories`;
			const body = setpointTemplateCategory.serialise();

			return this.http
				.post<any>(url, body, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

	async updateTemplate(setpointTemplate: SetpointTemplate): Promise<any> {
		return new Promise((resolve) => {
			console.log('updateTemplate()');
			const url = `${this.API_URL}/templates`;
			const body = setpointTemplate.serialise();

			return this.http
				.post<any>(url, body, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

	async updateSchedule(setpointSchedule: SetpointSchedule): Promise<any> {
		return new Promise((resolve) => {
			console.log('updateSchedule()');
			const url = `${this.API_URL}schedules`;
			const body = setpointSchedule.serialise();

			return this.http
				.post<any>(url, body, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

	get(qs: string = ''): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = `${this.API_URL}?${qs}`;

			return this.http
				.get<any>(url, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

	post(body, qs: string = ''): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = `${this.API_URL}?${qs}`;

			return this.http
				.post<any>(url, body, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}
}

export interface IGetList {
	id: number;
	title: string;
	gatewayId: string;
	gatewayTitle: string;
	siteId: number;
	siteTitle: string;
	rag: string;
}
