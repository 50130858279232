import { User } from "./user";

export class UserGroup {
    // The user
    user: User;
    // The name of the collection
    grouping: string;

    constructor(data?: any) {
        if (data) {
            this.user = new User(data.user || { id: data.userId || data.id, name: data.userName || data.name, email: data.email || data.userEmail });
            this.grouping = data.grouping;
        }
    }
}