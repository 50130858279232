import { ReportFragment } from './report-fragment';

export class ReportBlock {
	id: number;
	title: string;
	fragment_id: number;
	pagebreak: string;
	updatedAt: string;
	createdAt: Date = new Date();
	options: any;

	constructor(id?:number, title?:string, fragment_id?:number, pagebreak?: string, options?: any) {
		this.id = id;
		this.title = title;
		this.fragment_id = fragment_id;
		this.pagebreak = pagebreak;
		this.options = options || {};
	}
}
