export class SetpointScheduleRepeats {
	data: any;
	title: 'Annually' | 'Does not repeat' = 'Does not repeat';
	frequency: 'annually' | null = null;

	constructor(data?: any) {

		if (data) {
			if (typeof data === 'string') {
				data = JSON.parse(data);
			}
			this.data = data;
			this.frequency = data.frequency;
			this.title = this.frequency === 'annually' ? this.title = 'Annually' : this.title = 'Does not repeat';
		} else {
			this.frequency = null;
			this.title = 'Does not repeat';
		}
	}

	serialise() {
		return { frequency: this.frequency };
	}

	toString() {
		return this.title;
	}
}
