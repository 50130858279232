import { Component, Input, signal, Output, EventEmitter, input } from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrl: './chip.component.css'
})
export class ChipComponent {
  _value = signal<IChipValue>(null);
  _label = signal<string>('');
  _id = signal<string | number>(null);
  _icon = signal<string>(null);
  _checked = signal<boolean>(null);
  _isRadio = signal<boolean>(false);
  _canClick = signal<boolean>(true);
  _size = signal<'small' | 'medium'>('medium');
  _noColour = signal(false);
  noBackground = input<boolean>(false);
  isDanger = input<boolean>(false);
  iconPos = input<'left' | 'right'>('left');
  @Input()
  public set value(v: IChipValue) {
    this._value.set(v);
    this._label.set(v?.title || '');
    this._id.set(v.id || null);
  }

  @Input()
  public set label(v: string) {
    this._label.set(v);
  }

  @Input()
  public set icon(v: string) {
    this._icon.set(v);
  }

  @Input()
  public set checked(v: boolean) {
    this._checked.set(v);
  }

  @Input()
  public set isRadio(v: boolean) {
    this._isRadio.set(v);
  }

  @Input()
  public set canClick(v: boolean) {
    this._canClick.set(v);
  }

  @Input()
  public set size(v: 'small' | 'medium') {
    this._size.set(v);
  }

  @Input()
  public set noColour(v: boolean) {
    this._noColour.set(v);
  }

  @Output()
  onChanged = new EventEmitter<boolean>();


  spanClicked() {
    if (this._checked() !== null) {
      this._checked.set(!this._checked())
      this.onChanged.emit(this._checked());
    }
  }
}

export interface IChipValue {
  id: number | string;
  title: string;
}
