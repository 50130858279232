import { Asset } from '../asset';

/**
 * The selected asset + value combination for one side of a rule.
 * @version 1
 */
export class SelectedAsset {
	parentId: number;
	componentId: string;
	asset: Asset;
	value: string;
	computedValue: string; // Updated via backend

	constructor(parentId?: number, componentId?: string, asset?: Asset, value?: string) {
		this.componentId = componentId;
		this.asset = asset;
		this.value = value;
		this.parentId = parentId;
	}

	getTitle() {
		let title = this.asset ? this.asset.title : '';

		if (Number(this.value)) {
			if (Number(this.value) > 0) {
				title += (title ? ' + ' : '') + this.value;
			} else {
				title += (title ? ' - ' : '') + Math.abs(Number(this.value));
			}
		} else {
			title += this.value;
		}
	}
}
