@defer( when asset()) {

<div class="tabview-list">
  <div [class.active]="tabIndex() === 0" (click)="tabChange(0)">Realtime</div>
  <div [class.active]="tabIndex() === 1" (click)="tabChange(1)">Query</div>
  <div></div>
</div>
<div class="tabview-content">

  @switch (tabIndex()) {
  @case(0) {
  <div class="row">
    <div [hidden]="notitle" class="col-xs-12 history-header text-center" title="Refresh every {{everySeconds}} seconds">
      <h4>History </h4>
    </div>

    <div class="col-xs-12" [hidden]="true">
      <button class="btn btn-sm btn-secondary"><i (click)="reportType='list'" class="fa fa-fw fa-list-ul"></i>
        list</button> &nbsp;
      <button class="btn btn-sm btn-secondary"><i (click)="reportType='line-chart'" class="fa fa-fw fa-line-chart"></i> Graph</button>
    </div>
  </div>
  <div class="row" *ngIf="reportType==='list'">
    <div class="col-xs-12">
      <div style="width: 500px; margin: 0 auto;">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Date</th>
              <th class="text-right">Value</th>
            </tr>
          </thead>
          <tbody>
            <ng-template ngFor let-packet [ngForOf]="packets">
              <tr>
                <td class="hidden-xs" tooltipPosition="left" pTooltip="{{packet.createdAt | date:'dd MMM YY HH:mm' }} - {{packet.value}}">
                  {{packet.createdAt | date:'dd-MM-YY HH:mm' }},
                  <small> {{packet.createdAt | amTimeAgo}} </small>
                </td>
                <td class="hidden-sm visible-xs hidden-md hidden-lg" title="{{packet.createdAt | date:'dd MMM YY HH:mm' }}">
                  {{packet.createdAt | amTimeAgo}}</td>
                <td class="text-right">{{formattedValue(packet.value)}}</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  }
  @case(1) {
  @if(isMobile) {
  <app-setpoint-query [asset]="asset()" [fullscreen]="fullscreen"></app-setpoint-query>
  } @else {
  <app-explorer-asset [asset]="asset()"></app-explorer-asset>
  }
  }
  }

</div>

} @placeholder (minimum 200ms) {
<span><i class="mdi mdi-loading mdi-spin-x2"></i> Please wait...</span>
}
