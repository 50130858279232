<!-- Dim background -->
<div class="modal-background isDesktop" [style.z-index]="zIndex" (mousewheel)="backgroundWheel($event)"></div>
@defer {
  <!-- The dialog box -->
  <article [style]="_style()" [style.z-index]="zIndex" [ngClass]="{ 'can-scroll': _canScroll(), 'has-footer': _footerItems(), 'has-toolbar': _hasToolbar() }">
    <section>
      @if (canInteract()) {
        <header>
          <span>{{ _header() }}</span>
          <span>
            @if (_canFullscreen()) {
              <i class="mdi mdi-fullscreen can-click" tooltip="Fullscreen" (click)="fullscreen()"></i>
            }
            <i class="mdi mdi-close can-click" (click)="close()"></i>
          </span>
        </header>
      }
      @if (_hasToolbar()) {
        <div class="toolbar">
          <ng-content select=".toolbar"></ng-content>
        </div>
      }

      <main [style]="_mainStyle()">
        <ng-content></ng-content>
      </main>

      @if (_footerItems()) {
        <footer>
          @for (item of _footerItems(); track item) {
            <div class="right">
              @switch (item.type) {
                @case ('action') {
                  <app-button [label]="item.label" [icon]="item.icon" (click)="close(item.id)"></app-button>
                }
                @case ('button') {
                  <app-button [label]="item.label" [icon]="item.icon"></app-button>
                }
                @default {
                  {{ item.label }}
                }
              }
            </div>
          }
        </footer>
      }
    </section>
  </article>
} @placeholder (minimum 100ms) {}
