import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { HttpClient } from '@angular/common/http';

import { WeatherItem } from "app/classes/weather-item";
import { StoreService } from './store.service';

@Injectable({
	providedIn: 'root'
})
export class WeatherService {
	API_URL = 'https://w9ntw5tqw3.execute-api.eu-west-2.amazonaws.com/4d/';

	constructor(private apiService: APIService, private http: HttpClient, private storeService: StoreService) { }

	/**
	 * Convert a weather string to the icon URL
	 * 
	 * https://icons.qweather.com/en/
	 * 
	 *     "clear-night": "",
		"clear-day": "☀️",
		"clear": "",
		"rain": "🌧",
		"snow": "🌨",
		"sleet": "🌧",
		"fog": "🌫",
		"cloudy": "☁️",
		"thunderstorm": "⛈",
		"partly-cloudy-night": "☁️",
		"partly-cloudy-day": "⛅️",
		"drizzle": "🌧"
	 */
	textWeatherToURL(weatherAsText: string, fill = true): string {
		let icon: number;
		switch (weatherAsText) {
			case "clear-day":
				icon = 100
				break;
			case 'clear-night':
				icon = 150;
				break;
			case 'cloudy':
				icon = 104;
				break;
			case "cloudy-day":
				icon = 101;
				break;
			case "cloudy-night":
				icon = 151;
				break;
			case "fog-mist-rain":
			case "fog-drizzle-rain":
			case "mist-drizzle-rain":
			case "mist-fog-rain":
			case "rain-mist-mist":
			case "rain-fog":
			case "rain-drizzle":
			case "rain-drizzle-fog":
			case "rain-drizzle-mist":
			case "rain-mist":
			case "rain-mist-drizzle":
			case "rain-mist-fog":
			case "fog-rain":
			case "mist-rain":
			case "mist-rain-drizzle":
			case "rain":
				icon = 305;
				break;
			case "drizzle-fog":
			case "drizzle-fog-rain":
			case "drizzle-mist":
			case "drizzle-mist-fog":
			case "drizzle-mist-fog-rain":
			case "drizzle-mist-rain":
			case "drizzle-mist-snow":
			case "drizzle-rain-fog":
			case "drizzle-snow":
			case "drizzle-thunder":
			case "drizzle":
				icon = 309;
				break;
			case "drizzle-rain":
				icon = 305;
				break;
			case "fog-rain-drizzle":
			case "mist-fog":
			case "fog":
			case "fog-drizzle":
				icon = 501;
				break;
			case "fog-mist":
			case "mist-drizzle":
			case "mist-mist":
			case "mist":
				icon = 500;
				break;
			case "fog-mist-snow":
			case "snow-fog":
			case "rain-snow":
			case "snow-mist":
			case "fog-snow":
			case "snow-rain":
			case "mist-snow":
			case "mist-snow-drizzle":
			case "snow":
				icon = 2215;
				break;
			case "snow-drizzle":
				icon = 404;
				break;
			case "rain-thunder-mist":
			case "rain-thunder-fog":
			case "rain-thunder":
			case "thunder-rain":
			case "thunder":
				icon = 1043;
				break;
			case "thunder-drizzle":
				icon = 302;
				break;
			default:
				break;
		}

		let url = '';
		if (icon) {
			url = `https://4d-icons.s3.eu-west-2.amazonaws.com/weather/v3/icons/${icon}${fill ? '-fill' : ''}.svg`;
		}

		return url;
	}

	textWeatherToFont(weatherAsText: string, fill = true): string {
		let icon: number;
		switch (weatherAsText) {
			case "clear-day":
				icon = 100
				break;
			case 'clear-night':
				icon = 150;
				break;
			case 'cloudy':
				icon = 104;
				break;
			case "cloudy-day":
				icon = 101;
				break;
			case "cloudy-night":
				icon = 151;
				break;
			case "fog-mist-rain":
			case "fog-drizzle-rain":
			case "mist-drizzle-rain":
			case "mist-fog-rain":
			case "rain-mist-mist":
			case "rain-fog":
			case "rain-drizzle":
			case "rain-drizzle-fog":
			case "rain-drizzle-mist":
			case "rain-mist":
			case "rain-mist-drizzle":
			case "rain-mist-fog":
			case "fog-rain":
			case "mist-rain":
			case "mist-rain-drizzle":
			case "rain":
				icon = 305;
				break;
			case "drizzle-fog":
			case "drizzle-fog-rain":
			case "drizzle-mist":
			case "drizzle-mist-fog":
			case "drizzle-mist-fog-rain":
			case "drizzle-mist-rain":
			case "drizzle-mist-snow":
			case "drizzle-rain-fog":
			case "drizzle-snow":
			case "drizzle-thunder":
			case "drizzle":
				icon = 309;
				break;
			case "drizzle-rain":
				icon = 305;
				break;
			case "fog-rain-drizzle":
			case "mist-fog":
			case "fog":
			case "fog-drizzle":
				icon = 501;
				break;
			case "fog-mist":
			case "mist-drizzle":
			case "mist-mist":
			case "mist":
				icon = 500;
				break;
			case "fog-mist-snow":
			case "snow-fog":
			case "rain-snow":
			case "snow-mist":
			case "fog-snow":
			case "snow-rain":
			case "mist-snow":
			case "mist-snow-drizzle":
			case "snow":
				icon = 2215;
				break;
			case "snow-drizzle":
				icon = 404;
				break;
			case "rain-thunder-mist":
			case "rain-thunder-fog":
			case "rain-thunder":
			case "thunder-rain":
			case "thunder":
				icon = 1043;
				break;
			case "thunder-drizzle":
				icon = 302;
				break;
			default:
				break;
		}

		return `${icon}${fill ? '-fill' : ''}`;
	}

	textWeatherToColour(weatherAsText: string) {
		let colour = 'black';
		switch (weatherAsText) {
			case "clear-day":
				colour = 'yellow';
				break;
			case 'clear-night':

				break;
			case 'cloudy':

				break;
			case "cloudy-day":

				break;
			case "cloudy-night":

				break;
			case "fog-mist-rain":
			case "fog-drizzle-rain":
			case "mist-drizzle-rain":
			case "mist-fog-rain":
			case "rain-mist-mist":
			case "rain-fog":
			case "rain-drizzle":
			case "rain-drizzle-fog":
			case "rain-drizzle-mist":
			case "rain-mist":
			case "rain-mist-drizzle":
			case "rain-mist-fog":
			case "fog-rain":
			case "mist-rain":
			case "mist-rain-drizzle":
			case "rain":

				break;
			case "drizzle-fog":
			case "drizzle-fog-rain":
			case "drizzle-mist":
			case "drizzle-mist-fog":
			case "drizzle-mist-fog-rain":
			case "drizzle-mist-rain":
			case "drizzle-mist-snow":
			case "drizzle-rain-fog":
			case "drizzle-snow":
			case "drizzle-thunder":
			case "drizzle":

				break;
			case "drizzle-rain":

				break;
			case "fog-rain-drizzle":
			case "mist-fog":
			case "fog":
			case "fog-drizzle":

				break;
			case "fog-mist":
			case "mist-drizzle":
			case "mist-mist":
			case "mist":

				break;
			case "fog-mist-snow":
			case "snow-fog":
			case "rain-snow":
			case "snow-mist":
			case "fog-snow":
			case "snow-rain":
			case "mist-snow":
			case "mist-snow-drizzle":
			case "snow":

				break;
			case "snow-drizzle":

				break;
			case "rain-thunder-mist":
			case "rain-thunder-fog":
			case "rain-thunder":
			case "thunder-rain":
			case "thunder":

				break;
			case "thunder-drizzle":

				break;
			default:
				break;
		}

		return colour;
	}

	get(qs: string = ''): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = `${this.API_URL}?${qs}`;
			return this.http
				.get<any>(url, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

	post(body: any, qs: string = ''): Promise<WeatherItem[]> {
		return new Promise((resolve, reject) => {
			const url = `${this.API_URL}?${qs}`;
			return this.http
				.post<any[]>(url, body, { observe: 'body', ...this.apiService.getUAOHeaders() })
				.subscribe(i => {
					let response: any[] = <any>i;
					resolve(response.map(i => new WeatherItem(i)));
				});
		});
	}

	getWeather() {
		this.apiService.getWeatherStats().then(w => this.storeService._weather.next(w));
	}
}
