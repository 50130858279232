import { Asset } from "./asset";
import { SetpointTemplate } from "./setpoint-template";
import { Site } from "./site";
import { User } from "./user";
import { SetpointScheduleRepeats } from "./setpoint-schedule-repeats";

export class SetpointSchedule {
	id: number;
	// A short title summerising the schedule for lists
	title: string;
	// Any notes about this schedule
	notes: string;
	// The reason for this schedule
	reason: string;
	// The reoccuance for this schedule
	repeats: SetpointScheduleRepeats = new SetpointScheduleRepeats();
	// The tempalte this schedule is attached too
	template: SetpointTemplate;
	// The state the schedule is at
	state: SetpointSecheduleState = 'new';
	// The next date the schedule is to run
	processAt: Date;

	createdBy: User;

	// Recoursive settings TBC

	// Assets (no assets for all)
	assets: Asset[] = [];
	// Sites (no sites for all)
	sites: Site[] = [];

	assetTitleContains: string;

	// Listings
	counts: { sites: number, assets: number };

	wouldProcess: { assets: Asset[] };

	filter: {
		location: { value: string, op: '=' | '!=' },
		asset: { value: string, op: '=' | '!=' }
	} = {
			asset: { value: null, op: null },
			location: { value: null, op: null }
		};

	constructor(data?: any) {
		if (!data) {
			return;
		}

		this.id = data.id;
		this.title = data.title;
		this.reason = data.reason;
		this.template = new SetpointTemplate(data.template);
		this.processAt = new Date(data.processAt);
		this.createdBy = new User({ id: data.createdBy.id, name: data.createdBy.name });
		if (data.sites) {
			this.sites = data.sites.map(s => new Site(s));
		}
		if (data.assets) {
			this.assets = data.assets.map(a => new Asset(a));
		}
		this.counts = data.counts || { asset: null, sites: null };
		if (data.wouldProcess) {
			this.wouldProcess = { assets: data.wouldProcess.assets.map(asset => new Asset(asset)) };
		}
		if (data.filter) {
			Object.keys(data.filter).forEach(key => this.setFilterProperty(<FilterProperty>key, data.filter[key].value, <FilterOP>data.filter[key].op));
		}
		this.repeats = new SetpointScheduleRepeats(data.repeats || null);
	}

	setFilterProperty(property: FilterProperty, value: string, op: FilterOP) {
		console.log(`setFilterProperty(${property} , ${value} , ${op})`);
		this.filter[property] = { value, op };
	}

	serialise() {
		return {
			id: this.id,
			title: this.title,
			reason: this.reason,
			templateId: this.template?.id,
			processAt: this.processAt,
			assetTitleContains: this.assetTitleContains,
			assets: this.assets.map(a => a.id),
			sites: this.sites.map(s => s.id),
			filter: this.filter,
			repeats: this.repeats.serialise()
		};
	}
}

// new - a new schedule, never processed
// ready - has a process date
// done - nothing to do
// failed - last process failed
// building - currently creating setpoints

export type SetpointSecheduleState = 'new' | 'building' | 'ready' | 'done' | 'failed';
export type FilterOP = '=' | '!=';
export type FilterProperty = 'location' | 'asset';
