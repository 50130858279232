import { Org } from "./org";
import { Site } from "./site";
import { Asset } from "./asset";
import { Collection } from "./collection";
import { License } from "./license";

export class Gateway {
	id: string;
	title: string;
	site_id: number;
	seenOnline: boolean;
	online: string; // offline, standby, online
	seenAt: Date;
	location: string;
	embedLink: string;
	statusId: number;
	statusTitle: string;
	selected = false;
	metObsId: number;
	isFav: boolean;
	createdAt: Date;
	checked: boolean;

	lat: number;
	lng: number;

	mapX: number;
	mapY: number;

	assetInErrorCount = 0;
	assetCount: number;

	gatewayIconUrl: string;

	// Include fourdSQL.GATEWAY_WEATHER_LATEST
	localWeatherTemp: number;
	localWeatherIcon: string;
	localWeatherDate: Date;

	weather: {
		temp: number,
		pressure: number,
		humidity: number,
		icon: string
	};

	org: Org;
	site: Site;
	svgState: string;
	lineState: string;

	assets: Collection<Asset>;
	typeOf = 'Gateway';
	hasCharts: boolean;

	isStreamable: boolean;
	license: License;

	noDataFor24Hours: boolean;

	constructor(data?: any) {
		this.site = new Site();
		this.org = new Org();
		this.assets = new Collection<Asset>();
		if (data) {
			this.patch(data);
		}
	}

	patch(data) {
		if (!data) {
			return;
		}
		this.id = data.id;
		this.title = data.title;
		this.online = data.online;
		this.seenAt = data.seenAt ? new Date(data.seenAt) : null;
		this.mapX = data.mapX;
		this.mapY = data.mapY;
		this.assetCount = data.assetCount;
		this.metObsId = data.metObsId;
		this.embedLink = data.embedLink;
		this.location = data.location;
		this.assetInErrorCount = data.assetInErrorCount;
		this.localWeatherTemp = data.localWeatherTemp;
		this.localWeatherIcon = data.localWeatherIcon;
		this.localWeatherDate = data.localWeatherDate ? new Date(data.localWeatherDate) : null;
		this.createdAt = data.createdAt ? new Date(data.createdAt) : null;

		if (data.wTemp) {
			this.weather = {
				temp: data.wTemp,
				pressure: data.wPressure,
				humidity: data.wHumidity,
				icon: data.wIcon
			};
		}
		this.seenOnline = data.seenOnline;
		this.hasCharts = !!data.hasCharts;
		this.isStreamable = (data.isStreamable === "Y");
		this.statusTitle = data.statusTitle;
		this.statusId = data.status_id;
		if (!this.statusTitle && data.status_id) {
			this.statusTitle = ['', 'Configuration', 'Testing', 'Deployed', 'Deleted', 'Stock', 'Discovered', 'New', 'Delivered', 'Faulty', 'Demo', 'Engineer'][data.status_id];
		}
		if (data.site_id) {
			this.site_id = data.site_id;
			this.site.id = data.site_id;
			this.site.address.addressPostcode = data.sitePostcode;
			this.site.address.addressTown = data.siteAddressTown;
			this.site.address.address1 = data.siteAddress1;
			this.site.title = data.siteTitle;
			this.lat = data.sLat || null;
			this.lng = data.sLong || null;
		}
		if (data.orgTitle) {
			this.org.title = data.orgTitle;
			this.org.shortTitle = data.orgShortTitle;
		}

		if (this.id.startsWith('at_')) {
			this.gatewayIconUrl = 'https://www.airthings.com/hubfs/wave-plus-hero-image.png';
		} else if (this.id.startsWith('awair')) {
			this.gatewayIconUrl = 'https://4d-public.s3.eu-west-2.amazonaws.com/clients/awair.png';
		}

		try {
			this.noDataFor24Hours = (+new Date() - +this.seenAt) > 24 * 60 * 60 * 1000;
		} catch (e) { }

	}

	serialise() {
		return {
			id: this.id,
			title: this.title,
			site_id: this.site.id,
			org_id: this.org.id
		};
	}
}
