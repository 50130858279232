<span (click)="spanClicked()" [class.no-colour]="_noColour()" [class.danger]="isDanger()" [class.is-radio]="_isRadio()" [class.is-checked]="_checked()" [class.can-click]="_canClick()" [class.chip-small]="_size() === 'small'" [class.no-background]="noBackground()">

	@if(_checked() !== null && !_isRadio()) {
	<i class="mdi" [ngClass]="{
				'mdi-checkbox-blank-outline':!_checked(),
				'mdi-checkbox-marked-outline': _checked()
				}"></i>
	} @else {
	@if(_checked() !== null) {
	<i class="mdi" [ngClass]="{
				'mdi-radiobox-blank':!_checked(),
				'mdi-radiobox-marked': _checked()
				}"></i>
	}
	}

	@if(_icon() && iconPos() === 'left') {
	<i [class]="_icon()"></i>
	}
	{{_label()}}
	@if(_icon() && iconPos() === 'right') {
	<i [class]="_icon()"></i>
	}
</span>
