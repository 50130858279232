import { Site } from './site';
import { Org } from './org';
import { License } from './license';
import { Document } from './document';
import { Asset } from './asset';
import { OpeningHours } from './opening-hours';

export class Building {
	id: number;
	title: string;
	notes: string;
	site: Site;
	isActive: boolean;
	createdAt: Date;
	counts = { gateways: 0 };

	license: License;
	licenses: License[];
	org: Org;
	documents: Document[];
	assets: Asset[];
	openingHours: OpeningHours;
	isDeleted: boolean;
	aqConfig: IAQConfig;

	constructor(data?: any) {
		this.site = new Site();
		this.site.org = new Org();
		this.license = new License();

		if (!data) {
			return;
		}

		if (data.building && data.site) {
			const { building, documents, site, org, assets } = data;
			this.id = building.id;
			this.title = building.title;
			this.site.title = site.title;
			this.site.id = site.id;
			this.org = new Org({ id: org.id, title: org.title, shortTitle: org.shortTitle });
			this.site.org = this.org;
			this.documents = documents.map(document => new Document(document));
			this.isDeleted = building.isDeleted;

			if (assets) {
				this.assets = assets.sort((a, b) => a.title > b.title ? 1 : -1).map(asset => new Asset(asset));
			}
			// The default building license
			this.license = new License({
				id: building.licenseId | building.license_id,
				licensedAt: building.licensedAt,
				value: building.licenseValue,
				isActive: building.licenseIsActive,
				expiresAt: building.licenseExpiresAt
			});
		} else if (typeof data === 'object') {
			this.title = data.title;
			this.id = data.id;
			this.site.id = data.site_id || data.siteId;
			this.isDeleted = !!data.isDeleted;
			this.site.title = data.siteTitle;
			if (data.addressId) {
				this.site.address.addressLat = data.addressLat;
				this.site.address.addressLong = data.addressLong;
				this.site.address.addressPostcode = data.addressPostcode;
				this.site.address.addressTown = data.addressTown;
				this.site.address.address1 = data.address1;
				this.site.address.address2 = data.address2;
				this.site.address.address3 = data.address3;
			}
			this.isActive = data.isActive === 1;
			this.notes = data.notes || null;
			this.createdAt = new Date(data.createdAt);
			this.counts.gateways = data.gatewayCount;

			this.license = new License({
				id: data.licenseId | data.license_id,
				licensedAt: data.licensedAt,
				value: data.licenseValue,
				isActive: data.licenseIsActive,
				expiresAt: data.licenseExpiresAt,
				neverExpires: data.licenseNeverExpires,
				billingCycle: data.licenseBillingCycle,
				package_id: data.licensePackageId
			});

			if (data.site) {
				this.site = data.site;
			}
			if (data.documents) {
				this.documents = data.documents;
			}
			this.org = new Org({ id: data.orgId, title: data.orgTitle, shortTitle: data.orgShortTitle, isBillable: data.orgIsBillable });
			this.site.org = this.org;
			this.org.isBillable = data.orgIsBillable;
			//if (this.license.id) {
			//	this.licenses = [this.license];
			//	}
		}
	}

	serialise() {
		return {
			id: this.id,
			title: this.title,
			site_id: this.site.id,
			notes: this.notes,
			isActive: this.isActive
		};
	}

	doesValidate() {
		if (this.title.length < 3 || this.title.length > 50) {
			return false;
		}
		if (!this.site.id) {
			return false;
		}

		return true;
	}
}


export interface IAQConfig {
	id: number;
	isDefault: boolean;
	title: string;
	config: {
		alarms: {
			high: { action: { from: number, to: number }, warn: { from: number, to: number } },
			normal: { from: number, to: number },
			low: { action: { from: number, to: number }, warn: { from: number, to: number } }
		},
		id: string
	}[];
}
