import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Asset } from 'app/classes/asset';

@Component({
  selector: 'app-asset-dashboard',
  templateUrl: './asset-dashboard.component.html',
  styleUrls: ['./asset-dashboard.component.css']
})
export class AssetDashboardComponent implements OnInit {

  @Input()
  asset: Asset;

  @Output()
  onGoBack = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

}
