import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Site } from '../classes/site';
import { APIService } from './api.service';
import { StoreService } from './store.service';
import { Asset } from 'app/classes/asset';
import { SimpleAssetCollection } from 'app/classes/asset-collection';
import { firstValueFrom } from 'rxjs';
import { AssetCollectionService } from './asset.collection.service';


@Injectable({
  providedIn: 'root'
})
export class SiteService {

  API_URL = 'https://1khb6oop6f.execute-api.eu-west-2.amazonaws.com/v1';

  constructor(private apiService: APIService, private http: HttpClient, private storeService: StoreService) { }

  async getSitesContainsAssetTypes(assetTypes: number[]): Promise<Site[]> {
    return firstValueFrom(this.http
      .get<any[]>(this.API_URL + '?assettypes=' + assetTypes.join(), this.apiService.getUAOHeaders()))
      .then((response) => {
        return response.map(item => new Site(item));
      });
  }

  async getAssetCollections(siteId: number, collectionTypeIdList: number[]): Promise<SimpleAssetCollection[]> {
    return firstValueFrom(this.http
      .get<any[]>(this.API_URL + `?siteid=${siteId}&action=get-asset-collection&collectiontypes=${collectionTypeIdList.join(',')}`, this.apiService.getUAOHeaders()))
      .then(response => response.map(item => new SimpleAssetCollection(item)));
  }

  async getCollections(siteId: number): Promise<CombinedCollectionItem[]> {
    return this.http
      .get<any[]>(AssetCollectionService.API_URL + 'collections?site=' + siteId, this.apiService.getUAOHeaders())
      .toPromise()
      .then((response) => {

        return response.map(item => new CombinedCollectionItem({ site_id: siteId, ...item }));
      });
  }

  async deleteCollection(collectionId: number) {
    return this.http
      .post<any[]>(AssetCollectionService.API_URL + 'collections?delete=' + collectionId, { id: collectionId }, this.apiService.getUAOHeaders())
      .toPromise()
      .then((response) => {

        return response;
      });
  }

  async updateCollection(collection: CombinedCollectionItem): Promise<any> {
    return this.http
      .post<any[]>(AssetCollectionService.API_URL + 'collections', collection.serialise(), this.apiService.getUAOHeaders())
      .toPromise()
      .then((response) => {

        return response;
      });
  }

  getFavouriteSites(): number[] {
    const org = this.apiService.getUserOrg();
    const key = `org:${org.id}:sites:fav`;
    const existing: number[] = (localStorage.getItem(key) || "").split(',').filter(i => i).map(i => +i);

    return existing;
  }

  saveFavouriteSites(siteList: number[]) {
    const org = this.apiService.getUserOrg();
    const key = `org:${org.id}:sites:fav`;
    localStorage.setItem(key, siteList.join(','));
  }

  toggleFavouriteSite(site: Site): number[] {
    const existing = this.getFavouriteSites();
    let newList: number[];
    if (existing.findIndex(i => i === site.id) !== -1) {
      newList = existing.filter(i => i !== site.id);
    } else {
      newList = [...existing, site.id];
    }
    this.saveFavouriteSites(newList);

    return newList;
  }

  /**
   * Get sites
   */
  getSites(querystring?: any): Promise<Site[]> {
    return this.http
      .get<any[]>(this.apiService.getUserAPI() + '/sites' + (querystring ? '?' + querystring : ''), { headers: this.apiService.getHttpHeaders() })
      .toPromise()
      .then((response) => {
        let sites = [];
        let rags = [];
        response.filter(s => s.isActive).forEach(element => {
          const site = this.createSiteObject(element);
          if (rags.length) {
            rags.forEach(rag => {
              if (rag.site_id === site.id) {
                site.rags.push(rag);
              }
            });
          }
          sites.push(site);
        });

        return sites;
      })
      .catch(this.apiService.handleError);
  }

  createSiteObject(site: any) {
    const obj = new Site(site);

    const userOrg = this.apiService.getUserOrg();
    const orgId = obj.org.id;

    if (orgId) {
      if (this.storeService.getOrgs().find(o => o.id === orgId && o.hide)) {
        obj.org = userOrg;
      }

    }
    return obj;
  }

}

export class CombinedCollectionItem {
  static readonly TYPE_AQ = 3;
  static readonly TYPES = ['', 'clean room', 'gowning room', 'office air quality', 'footfall', 'reporting', 'equipment'];

  id: number;
  title: string;
  value: string;
  updatedAt: Date;
  type: { id: number, title: string };
  site: { id: number, title: string };
  origin: 'user' | 'shape' = 'user';
  assets: { id: number, typeId: number, value?: string, title: string, gateway: { id: string, title: string } }[];
  selected: boolean;

  constructor(data?: any) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.title = data.title;
    this.type = { id: +data.type, title: CombinedCollectionItem.TYPES[+data.type] };
    this.origin = data.origin;
    this.assets = data.assets;
    if (data.site_id) {
      this.site = { id: data.site_id, title: data.site_title || data.sTitle };
    }
  };

  addAsset(asset: Asset) {
    if (!asset) {
      return;
    }
    const item = { id: asset.id, typeId: asset.assetType_id, title: asset.title, gateway: { id: asset.gateway_id, title: asset.gatewayTitle } };

    if (!this.assets) {
      this.assets = [item];

    } else {
      if (!this.assets.find(a => a.id === asset.id)) {
        this.assets.push(item);
      }
    }
  }

  serialise() {
    return {
      id: this.id,
      title: this.title,
      type_id: this.type?.id,
      site_id: this.site?.id,
      assets: this.assets.map(a => a.id)
    }
  }
}
