import { Component, OnInit, Input, Output, EventEmitter, signal, input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { APIService } from 'app/shared/api.service';
import { Site } from 'app/classes/site';
import { Gateway } from 'app/classes/gateway';
import { Asset } from 'app/classes/asset';
import { AssetService } from 'app/shared/asset.service';
import { ExportService } from "../../shared/export.service";
import { SiteService } from 'app/shared/site.service';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogExportSitesComponent } from 'app/dialogs/dialog-export-sites/dialog-export-sites.component';
import moment from 'moment';

declare const document: any;

@Component({
  selector: 'app-explorer',
  templateUrl: './explorer.component.html',
  styleUrls: ['./explorer.component.css'],
  providers: [DialogService]
})
export class ExplorerComponent implements OnInit {

  _selectMode = signal<boolean>(false);
  _isAdmin = signal<boolean>(false);
  isInDialog = input<boolean>(false);

  sitesChecked = {};
  gatewaysChecked = {};
  assetsChecked = {};

  @Input()
  startAtSites = signal<boolean>(false);

  @Input()
  api: any;

  @Input()
  selectType = 'asset';

  @Input()
  public set selectMode(v: boolean) {
    this._selectMode.set(v);
  }
  public get selectMode(): boolean {
    return this._selectMode();
  }

  _selectedItems = signal<IExplorerSelectedItems>(null);
  @Input()
  public set selectedItems(v: IExplorerSelectedItems) {
    this._selectedItems.set(v);
    this.sitesChecked = v.sites || {};
    this.assetsChecked = v.assets || {};
    this.gatewaysChecked = v.gateways || {};
  }

  public get selectedItems(): IExplorerSelectedItems {
    return this._selectedItems();
  }

  @Output()
  onSelected = new EventEmitter<IExplorerSelectedItems>();

  @Output()
  addToFilter = new EventEmitter<string>();

  pushPayload = '';
  pushJSON = '';

  isLoading: boolean;
  isSaving: boolean;
  isDirty: boolean;

  revealGateway: Gateway;
  breadcrumb: MenuItem[] = null;
  home = { icon: 'pi pi-home' };
  view = signal<'sites' | 'gateways' | 'assets' | 'asset' | 'search' | 'favourites'>('sites');
  sites: Site[];
  gateways: Gateway[];
  site: Site;
  gateway: Gateway;
  assets: Asset[];
  asset: Asset;
  copied: boolean;

  pushAuthorisationRequired: boolean;
  pushAuthorisation: string;
  isEditingJSON: boolean;

  // API working on
  newAPI: boolean;
  orgAPI: number;
  orgAPIs: any[];
  assetAPIs: any[];
  // The action working on
  actionAPI: string;
  apiURL: string;
  apiTitle: string;

  isAddingProperty: boolean;
  moduleAccess: any;

  selectedRag: any;
  ragDetails: { site: Site, siteId: number, rag: any, assets: any[] };
  // Hold assets here for RAG export
  ragAssets: any = {};
  cached: any = {};

  showAPIpush = false;

  filtered: {
    lists: { sites: Site[] },
    text: { sites: string },
    counts: { sites: number }

  } = {
      lists: { sites: [] },
      text: { sites: '' },
      counts: { sites: null }
    };

  public items: MenuItem[];
  rightClickedAssset: Asset;
  labelEnterVisible: boolean;
  assetLabels: any[];
  pleaseWait = signal(false);
  isLoadingFavourites = signal<boolean>(false);

  constructor(public dialogService: DialogService, private siteService: SiteService, private apiService: APIService, public assetService: AssetService, private exportService: ExportService) {
    this.moduleAccess = apiService.moduleAccess;
    const orgId = this.apiService.getUserOrg().id;
    if (orgId && !this.startAtSites) {
      const previousStateString: any = localStorage.getItem(`${orgId}:explorer:state`);

      if (previousStateString) {
        const previousState = JSON.parse(previousStateString);
        this.view.set(previousState.view);
        if (previousState.siteId) {
          this.site = new Site({ id: previousState.siteId, title: previousState.siteTitle });
        }
        if (previousState.gatewayId) {
          this.gateway = new Gateway({ id: previousState.gatewayId, title: previousState.gatewayTitle });
        }
        if (previousState.assetId) {
          this.asset = new Asset({ id: previousState.assetId, title: previousState.assetTitle });
          assetService.getAsset(previousState.assetId).then(asset => {
            this.asset = asset;
            this.isLoading = false;
          });
        }
      }
    }
    this.getLocalStorage();
  }

  ngOnInit() {
    this.rebuild();
    this._isAdmin.set(this.apiService.isAdmin());
  }

  select(item: Site | Gateway | Asset, event: any) {
    if (item instanceof Site) {
      item.checked = !item.checked;
      this.sitesChecked[item.id] = item.checked;
    }
    if (item instanceof Gateway) {
      item.checked = !item.checked;
      this.gatewaysChecked[item.id] = item.checked;
    }
    if (item instanceof Asset) {
      item.checked = !item.checked;
      this.assetsChecked[item.id] = item.checked;
    }

    event.stopPropagation();
  }

  gatewaySelected(gateway: Gateway) {
    this.gatewaysChecked[gateway.id] = gateway.checked;
  }

  selectClear() {
    this.sitesChecked = {};
    this.gatewaysChecked = {};
    this.assetsChecked = {};
    this.rebuild();
  }

  selectSave() {
    const payload: IExplorerSelectedItems = { sites: this.sitesChecked, gateways: this.gatewaysChecked, assets: this.assetsChecked };
    this.onSelected.emit(payload);
  }

  searchChanged(section: 'sites', text: string) {
    console.log(text);
    this.filtered.text[section] = text.toLowerCase().trim();
    this.saveLocalStorage();
    this.rebuild();
  }

  getLocalStorage() {
    try {
      const orgId = this.apiService.getUserOrg().id;
      if (!orgId) {
        return;
      }
      const storage = localStorage.getItem(`org:${orgId}:explorer:filter`);
      if (!storage) {
        return;
      }
      this.filtered.text = JSON.parse(storage);
    } catch (error) { }
  }

  saveLocalStorage() {
    const orgId = this.apiService.getUserOrg().id;
    if (!orgId) {
      return;
    }
    localStorage.setItem(`org:${orgId}:explorer:filter`, JSON.stringify(this.filtered.text));
  }

  applySiteFilter() {
    if (this.filtered.text.sites === '') {
      this.filtered.lists.sites = this.sites;
      this.filtered.counts.sites = this.sites.length;

      return;
    }

    const searchFor = this.filtered.text.sites;

    this.filtered.lists.sites = this.sites.filter(s => {
      if (s.title.toLowerCase().includes(searchFor)) return true;
      if (s.org.shortTitle.toLowerCase().includes(searchFor)) return true;
      if (s.id === +searchFor) return true;
      if (s.address?.address1.toLowerCase().includes(searchFor)) return true;
      if (s.address?.addressCounty.toLowerCase().includes(searchFor)) return true;
      if (s.address?.addressTown.toLowerCase().includes(searchFor)) return true;
      if (s.address?.addressPostcode.toLowerCase().includes(searchFor)) return true;
    });

    this.filtered.counts.sites = this.filtered.lists.sites.length;
  }

  async getFavs() {
    this.isLoadingFavourites.set(true);
    this.assetService
      .getUserFavourites()
      .then(assets => {
        this.assets = assets;
        this.isLoading = false;
        this.assetLabels = [];
        const assetLabels = {};
        assets.forEach(asset => {
          const labels = asset.labels || 'default';
          assetLabels[labels] = assetLabels[labels] || [];
          assetLabels[labels].push(asset);
        });
        this.assetLabels = Object.keys(assetLabels).map(labels => { return { key: labels, assets: assetLabels[labels] } });
        this.isLoadingFavourites.set(false);
      });
  }

  rebuild() {
    let routeUrl = '/explorer/sites';

    this.isLoading = true;
    if (this.view() === 'sites') {
      this.breadcrumb = null;
      this.siteService
        .getSites('rags=1')
        .then(sites => {
          this.sites = sites;
          this.sites.forEach(s => s.checked = this.sitesChecked[s.id]);
          this.isLoading = false;
          this.applySiteFilter();
        });
    }
    if (this.view() === 'gateways') {
      routeUrl += `/${this.site.id}/gateways`;
      this.breadcrumb = [{ label: this.site.title }];
    }
    if (this.view() === 'assets') {
      routeUrl += `/${this.site.id}/gateways/${this.gateway.id}/assets`;
      this.breadcrumb = [{ label: this.site.title }, { label: this.gateway.title }];
      this.apiService
        .getAssetsForGatewayId(this.gateway.id, 'refs=1')
        .then(assets => {
          this.assets = assets;

          this.assets.forEach(s => s.checked = this.assetsChecked[s.id]);
          this.isLoading = false;
        });
    }

    if (this.view() === 'favourites') {
      routeUrl += `/assets/favourites`;
      this.breadcrumb = [{ label: 'Favourites' }];
      this.getFavs();
    }

    if (this.view() === 'asset') {
      routeUrl += `/${this.site.id}/gateways/${this.gateway.id}/asset/${this.asset.id}`;
      this.breadcrumb = [
        { label: this.site.title },
        { label: this.gateway.title },
        { label: this.asset.title }];

      this.apiService.getAssetSettings(this.asset.id)
        .then(asset => {
          try {
            this.asset.settings = asset[2];
            if (asset[1].length) {
              this.asset.ref = asset[1][0].value;
            }
          } catch (e) {
            console.log(e);
          }
          this.isLoading = false;
        });
    }

    this.apiService.postRoute(routeUrl);

    const orgId = this.apiService.getUserOrg().id;

    if (orgId) {
      const params = {
        view: this.view(),
        siteTitle: this.site ? this.site.title : null,
        siteId: this.site ? this.site.id : null,
        gatewayId: this.gateway ? this.gateway.id : null,
        gatewayTitle: this.gateway ? this.gateway.title : null,
        assetId: this.asset ? this.asset.id : null,
        assetTitle: this.asset ? this.asset.title : null
      }

      localStorage.setItem(`${orgId}:explorer:state`, JSON.stringify(params));
    }
  }

  siteClick(site: Site) {
    this.view.set('gateways');
    this.site = site;
    this.gateway = null;
    this.gateways = null;
    this.assets = null;
    this.asset = null;
    this.rebuild();
  }

  gatewayClick(gateway: Gateway) {
    this.view.set('assets');
    this.gateway = gateway;
    this.assets = null;
    this.asset = null;
    this.rebuild();
  }

  assetClick(asset: Asset) {
    this.isAddingProperty = false;
    this.asset = asset;
    if (this.view() === 'favourites') {
      this.site = this.asset.gateway.site;
      this.gateway = this.asset.gateway;
    }
    this.view.set('asset');

    this.rebuild();
  }

  crumbFavouriteAssetsClick() {
    this.gateways = null;
    this.gateway = null;
    this.assets = null;
    this.asset = null;
    this.view.set('favourites');
    this.rebuild();
  }

  crumbClick(event) {
    console.log(event);
    switch (event) {
      case 0:
        this.assets = null;
        this.asset = null;
        if (this.breadcrumb[0].label === 'Favourites') {
          this.view.set('favourites');
        } else {
          this.view.set('gateways');
        }
        break;
      case 1:
        this.asset = null;
        this.view.set('assets');
        break;
      case 99:
        this.gateways = null;
        this.gateway = null;
        this.assets = null;
        this.asset = null;
        this.view.set('sites');
        break;
    }
    this.rebuild();
  }

  reveal(event, obj) {
    event.preventDefault();
    this.revealGateway = obj;
  }

  copyToClipboard(section = 'asset') {
    const copyText = document.getElementById(section + 'api');

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand('copy');
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 2000);
  }

  ragClick(rag, site) {
    this.showRAG(rag, site);
  }

  showRAG(rag, site) {
    this.getRAGFromS3(rag.site_id, rag.year, rag.month)
      .then(data => {
        this.ragDetails = { siteId: rag.site_id, site, rag, assets: [] };
        data.forEach(asset => {
          const assetId = asset.assetId;
          const day = asset.days.find(row => row.day === rag.day);
          if (day) {
            const detail = { assetId, asset: null, day: day.totals };
            this.getRAGAsset(assetId).then(asset0 => { detail.asset = asset0; asset.asset = asset0 });
            this.ragDetails.assets.push(detail);
          }
        });
      });
  }

  ragHover(rag, site) {
    // Only change if clicked (stops it poping up when moving around the list)
    if (this.ragDetails?.siteId === rag.site_id) {
      this.showRAG(rag, site);
    }
  }

  /**
   * Get RAG from cache or load, place in cache and return
   * @param siteId 
   * @param year 
   * @param month 
   */
  getRAGFromS3(siteId, year, month): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const cacheKey = 'site-' + year + '-' + month + '_' + siteId;
      if (!this.cached[cacheKey]) {
        try {
          this.apiService.getSiteRAGS3(siteId, year, month)
            .then(response => {
              if (response) {
                this.cached[cacheKey] = response.assets;
              }
              resolve(response.assets);
            });
        } catch (e) {
          console.log('NO RAG FOR MONTH');
          resolve(null);
        }
      } else {
        resolve(this.cached[cacheKey]);
      }
    });
  }

  /**
   * Load x months of RAG to the cache
   */
  getMonthsOfRAG(months: number) {
    return new Promise((resolve, reject) => {
      const siteId = this.ragDetails.siteId;
      // Load up the RAGS for x months
      const fns = [];
      for (let index = 0; index < months; index++) {
        fns.push(this.getRAGFromS3(siteId, moment()
          .subtract(index, 'months').year(),
          (moment().subtract(index, 'months').month() + 1)));
      }

      Promise.all(fns).then((data) => {
        // Load up the asset details in the RAGS
        console.log(data);
        const aFns = [];
        let assetIds = {};
        data.forEach(assets => {
          if (assets) {
            assets.forEach(asset => {
              if (!assetIds[String(asset.assetId)]) {
                assetIds[String(asset.assetId)] = true;
                aFns.push(this.getRAGAsset(asset.assetId));
              }
            });
          }
        });

        Promise.all(aFns).then(() => resolve(data));
      });
    });
  }

  getRAGAsset(assetId) {
    return new Promise((resolve, reject) => {
      if (!this.ragAssets[String(assetId)]) {
        this.assetService.getAsset(assetId).then(asset => {
          this.ragAssets[String(assetId)] = asset;
          resolve(asset);
        });
      } else {
        resolve(this.ragAssets[String(assetId)]);
      }
    });
  }

  exportSitesWithDialog() {
    const dialogRef = this.dialogService.open(DialogExportSitesComponent, {
      header: `Export Sites`,
      width: '80%',
      data: { sites: this.sites }
    });
    dialogRef.onClose.subscribe((columns) => {
      if (columns) {
        this.exportSites(columns);
      }
    });
  }

  exportSites(columns?: string[]) {
    this.pleaseWait.set(true);
    let data = [];
    this.assetService.getAssetsForUser().then(assets => {
      data = assets.map(a => {
        return {
          a: a.gateway.site.title,
          b: a.gateway.title,
          c: a.title,
          d: a.assetTypeTitle,
          e: a.updatedAt,
          f: a.value,
          g: a.createdAt,
          h: a.id
        };
      });
      this.exportService.exportAssetsToExcel(data, 'assets');
      this.pleaseWait.set(false);
    });
  }

  export(action?: string) {
    switch (action) {
      case 'sites':
        this.exportSites();
        break;
      default:
        const site = this.ragDetails.site;
        this.getMonthsOfRAG(4)
          .then(() => {
            // this.apiService.getSite(siteId)
            //   .then(site => {
            let assetsData = [];
            for (let index = 0; index < 4; index++) {
              const cacheKey = 'site-'
                + moment().subtract('months', index).year() + '-'
                + (moment().subtract('months', index).month() + 1) + '_' + site.id;

              if (!this.cached[cacheKey]) {
                // Skip if no data
                continue;
              }
              this.cached[cacheKey].forEach(asset => {
                if (asset.data) {
                  asset.data.forEach(element => {
                    assetsData.push({
                      asset: this.ragAssets[String(asset.assetId)].title, date: `${element.day}/${asset.month}/${asset.year}`,
                      min: element.totals.min.toFixed(2),
                      avg: element.totals.avg.toFixed(2),
                      max: element.totals.max.toFixed(2),
                      rag: ['green', 'amber', 'red'][element.totals.rag]
                    });
                  });
                }
              });
            }
            assetsData = assetsData.sort((a, b) => a.asset > b.asset ? 1 : -1);
            this.exportService.exportExcel(assetsData, site, site.title + ' RAG Report');
          });
        break;
    }
  }

  mouseClick(asset: Asset) {
    this.rightClickedAssset = asset;
  }

  async updateLabel() {
    this.labelEnterVisible = false;
    await this.assetService.updateFavouriteAssetLabels(this.rightClickedAssset.id, this.rightClickedAssset.labels);
    await this.getFavs();
  }

  showContextMenu() {
    // Get context
    const self = this;
    this.items = [
      { label: this.rightClickedAssset?.title, disabled: true },
      {
        label: 'Label',
        items: [
          {
            label: 'set',
            command: async (ev: any) => {
              this.labelEnterVisible = true;
            }
          }

        ]
      },
      { separator: true },
      {
        label: 'Favourite',
        items: [
          {
            label: 'Remove',
            command: async () => {
              this.isLoading = true;
              await self.assetService.removeUserFavourite(this.rightClickedAssset.id);
              this.getFavs();
            }
          }
        ]
      }
    ];
  }
}

export interface IExplorerSelectedItems {
  assets: { [key: number]: boolean };
  gateways: { [key: string]: boolean };
  sites: { [key: number]: boolean };
}
