import { Ticket } from "./ticket";
import { Site } from "./site";

export class SiteTicket extends Ticket {
    override isSiteTicket = true;

    constructor(data?: any) {
        super(data);

        let site = new Site();
        site.id = data.siteId;
        site.title = data.siteTitle;

        this.site = site;
    }

    override serialise() {
        let serialise = super.serialise();
        serialise.siteId = this.site.id;

        return serialise;
    }

    display() {
        return ' Site ' + this.site.title;
    }

}
