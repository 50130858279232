import { EventEmitter, Input, Output, input } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-theme-page-section',
  templateUrl: './theme-page-section.component.html',
  styleUrls: ['./theme-page-section.component.css']
})
export class ThemePageSectionComponent {

  @Input()
  label: string;

  @Input()
  titleLink: any[];

  @Input()
  checkboxes: { title: string, property: string }[];

  @Input()
  isWarning: boolean;

  @Input()
  logo: '4d' | 'elogbooks';

  @Input()
  toolbar: IToolbarItem[];

  @Input()
  rag: 'red' | 'amber' | 'green' | undefined;

  @Input()
  canChangeRag: boolean;

  @Input()
  border = false;

  @Input()
  isMuted = false;

  noBackground = input<boolean>(false);

  selectValue: any = '';

  @Output()
  onToolbarClick: EventEmitter<any> = new EventEmitter();


  @Output()
  onToolbarSelectChange: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router,) { }


  navigate() {
    this.router.navigate(this.titleLink);
  }

  toolbarClick(item) {
    this.onToolbarClick.emit(item);
  }

  selectChanged() {
    this.onToolbarSelectChange.emit(this.selectValue);
  }

  clickRag(rag: 'red' | 'amber' | 'green') {
    this.rag = rag;
  }
}


export class IToolbarItem {
  icon?: string;
  label?: string;
  text?: string;
  select?: { id: number | string, label: string }[];
  tag?: string;
  tooltipposition: 'right' | 'left' | 'top' | 'bottom' | undefined = 'top';
}
