<div [class.caption]="caption()">
	@if(caption()) {
	<label>{{caption()}}</label>
	}
	<app-button icon="mdi mdi-pencil" [size]="size()" [label]="label()" (click)="buttonClick()"></app-button>
</div>

@if(showDialog()) {
<app-dialog label="Notifications" (onClose)="submit($event)" [dimensions]="{width:600,height:480}" [footer]="[{type:'action', label:'Update', id:'update'}]">
	<main class="dialog">
		<section class="details">
			@for(grouping of groupingsAvailable(); track grouping.id) {
			<div>
				<div class="grouping" [class.active]="groupingActive?.id === grouping.id" (mouseenter)="groupingActive = grouping" (click)="grouping.selected = !grouping.selected">
					<i class="mdi" [ngClass]="{'mdi-checkbox-marked-outline':grouping.selected, 'mdi-checkbox-blank-outline':!grouping.selected}"></i> {{grouping.title}}
				</div>
			</div>
			}
		</section>
		<section class="users">
			<div>
				<div class="selected" *ngIf="groupingActive">
					<label>In Group</label>
					@if(groupingActive.users.length===0) {
					<p><i class="red">No users</i></p>
					} @else {
					<ul>
						@for(user of groupingActive?.users;track user.id) {
						<li> {{user.name}}</li>
						}
					</ul>
					<p class="notes" [innerHTML]="groupingActive.notes"></p>
					}
				</div>
			</div>
		</section>
	</main>
</app-dialog>
}
