import { Grouping } from './grouping';
import { Org } from './org';
import { Site } from './site';
export class User {
  id: number;
  name: string;
  email: string;
  password: string;
  role: UserRole;
  org: Org;
  isActive: boolean;
  // If the user has 1 module they are a restricted user
  isRestricted: boolean;
  hasAcceptedTerms = true;
  orgRoles?: any[] = [];
  modules: Set<string>;
  moduleCount = 0;
  siteCount: number;
  updatedAt: Date;
  settings: any[] = [];
  prefs: any = {};
  notes: string;
  jobTitle: string;
  lastRoute: string;
  inits?: string;
  sites?: Site[];
  exports?: UserExportItem[];
  hasActivated: boolean;
  training?: ITrainingItem[];
  groupings?: Grouping[];

  constructor(data?: any) {
    this.org = new Org();
    if (data) {
      this.id = data.id;
      this.email = data.email;
      this.name = data.name;
      this.role = data.role;
      this.isActive = data.isActive;
      this.hasActivated = this.isActive || data.updatedAt;
      if (data.exports) {
        this.exports = data.exports;
      }
      if (data.org) {
        this.org = new Org(data.org);
      } else {
        this.org.title = data.orgTitle;
        this.org.shortTitle = data.orgShortTitle;
        this.org.id = data.orgId;
        this.org.type = data.orgType;
      }
      this.hasAcceptedTerms = data.hasAcceptedTerms;
      this.hasAcceptedTerms = true;
      this.orgRoles = data.orgRoles || [];
      this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : null;
      this.notes = data.notes;
      this.lastRoute = data.path;
      if (data.prefs) {
        this.prefs = data.prefs;
      }
      if (data.settings) {
        if (typeof data.settings === 'string') {
          // Settings delimiter set to ~
          const settings = data.settings.split('~');
          // Fields delimiter set to TAB
          settings.forEach(element => {
            const obj = {};
            const parts = element.split("\t");
            obj[parts[0]] = parts[1];
            this.settings.push(obj);
            if (parts[0] === 'job_title') {
              this.jobTitle = parts[1];
            }
          });
        } else {
          this.settings = data.settings;
          this.jobTitle = this.settings["job_title"] || null;
        }

        if (data.training) {
          this.training = data.training.map(t => ({ id: t.id, title: t.title, watched: !!t.watched }));
        }
        if (data.groupings) {
          this.groupings = data.groupings.map(g => new Grouping(g));
        }
      }
      this.modules = new Set();
      if (data.modules) {
        if (typeof data.modules === 'string') {
          data.modules.split(',').forEach(module => this.modules.add(module.toLowerCase().trim()));
        } else {
          data.modules.forEach(module => this.modules.add(module));
        }
      }
      this.moduleCount = this.modules.size;
      this.isRestricted = (this.moduleCount < 2);
      this.siteCount = data.siteCount || null;
      this.sites = data.sites ? data.sites.filter(s => s.title).map(site => new Site(site)) : null;
    } else {
      this.email = '';
      this.name = '';
      this.password = '';
    }
  }

  modulesAsObject() {
    const obj = {};
    this.modules.forEach(module => {
      obj[module] = true;
    });

    return obj;
  }

  serialise() {
    return {
      id: this.id,
      name: this.name,
      email: this.email,
      hasAcceptedTerms: this.hasAcceptedTerms,
      isActive: this.isActive,
      moduleCount: this.moduleCount,
      modules: [...this.modules],
      org: this.org,
      orgRoles: this.orgRoles,
      prefs: this.prefs,
      role: this.role,
      settings: this.settings,
      v: 2
    };
  }
}

export class UserSelection extends User {
  isSelected: boolean;
  constructor(data?: any) {
    super(data);
  }
}

export type UserRole = 'demo' | 'contact' | 'user' | 'admin';

export interface UserExportItem {
  id: number;
  sqlCommand: string;
  frequency: UserExportItemFrequency;
  permission?: 'access' | 'org' | 'none';
  isActive?: boolean;
}
export type UserExportItemFrequency = 'daily' | 'weekly' | 'monthly' | 'yearly';

export interface ITrainingItem {
  id: number;
  title: string;
  watched: boolean;
}
