export class SettingItem {
    id: number;
    setting: string;
    type: string;
    value: string;
    version: number;

    constructor(data?: any) {
      if (data) {
        this.setting = data.setting;
        this.value = data.value;
        this.version = data.version;
        this.id = data.id;
        this.type = data.type;
      }
    }
  }
