import { Component, computed, input } from '@angular/core';
import { Asset } from '../../classes/asset';

@Component({
  selector: 'app-asset-icon',
  templateUrl: './asset-icon.component.html',
  styleUrls: ['./asset-icon.component.css']
})
export class AssetIconComponent {

  asset = input<Asset>();
  assetTypeId = input<number>(null);

  typeId = computed(() => this.asset()?.assetType || this.asset()?.assetType_id || this.assetTypeId());
  purposeId = computed(() => this.asset()?.purpose?.id);

  icon = computed<IAssetIcon>(() => {
    let typeId, purposeId, value;
    if (this.asset()) {
      typeId = this.asset().assetType || this.asset().assetType_id;
      purposeId = this.asset().purpose?.id || this.asset().purposeId;
      value = this.asset().value;
    } else {
      typeId = this.assetTypeId();
      purposeId = this.purposeId();
      value = null;
    }

    switch (typeId) {
      case 1:
        if (purposeId === 11) {
          return { tooltip: 'Water Leak', icon: 'mdi mdi-pipe-leak' };
        } else {
          return { tooltip: 'Button', icon: 'mdi mdi-button-pointer' };
        }
      case 2:
      case 9:
      case 47:
      case 19:
        return { tooltip: 'Temperature', icon: 'mdi mdi-thermometer-low' };
      case 4:
        return { tooltip: 'Relay', icon: 'mdi mdi-connection' };
      case 12:
        return { tooltip: 'Wifi', icon: 'mdi mdi-wifi' };
      case 7:
      case 11:
      case 45:
        return { tooltip: 'Light', icon: 'mdi mdi-lightbulb-on-10' };
      case 14:
      case 16:
        return { tooltip: 'Light', icon: 'mdi mdi-power-plug' };
      case 3:
      case 59:
        return { tooltip: 'Pressure', icon: 'mdi mdi-car-brake-low-pressure' };
      case 8:
        return { tooltip: 'Humidity', icon: 'mdi mdi-water-percent' };
      case 17:
      case 46:
        return { tooltip: 'Door', icon: 'mdi mdi-door' };
      case 18:
      case 43:
      case 44:
        return { tooltip: 'CCTV', icon: 'mdi mdi-cctv' };
      case 20:
        return { tooltip: '', icon: 'mdi mdi-counter' };
      case 22:
        return { tooltip: 'Motion', icon: 'mdi mdi-motion-sensor' };
      case 25:
        return { tooltip: 'CO2', icon: 'mdi mdi-molecule-co2' };
      case 48:
        return { tooltip: 'Bin', icon: 'mdi mdi-delete-empty' };
      case 49:
        return { tooltip: 'Bin', icon: 'mdi mdi-delete-empty' };
      case 50:
      case 56:
        return { tooltip: 'Occupancy', icon: 'mdi mdi-walk' };
      case 24:
        return { tooltip: 'Distance', icon: 'mdi mdi-map-marker-distance' };
      case 29:
        return { tooltip: 'PM2.5', icon: 'icon-font_pm25  fa-fw' };
      case 30:
        return { tooltip: 'PM10', icon: 'icon-font_pm10 fa-fw' };
      case 62:
        return { tooltip: 'PM1', icon: 'icon-font_pm10 fa-fw' };
      case 15:
        return { tooltip: 'Footfall', icon: 'mdi mdi-shoe-print' };
      case 38:
        return { tooltip: 'Firewall', icon: 'mdi mdi-wall-fire' };
      case 33:
        return { tooltip: 'UPS', icon: 'mdi mdi-power-plug-battery-outline' };
      case 63:
      case 61:
        return { tooltip: 'Nitric Oxide', icon: 'mdi mdi-gas-cylinder' };
      case 27:
        return { tooltip: 'VOC', icon: 'mdi mdi-flask' };
      case 23:
        if (purposeId === 11) {
          return { tooltip: 'Moisture', icon: 'mdi mdi-pipe-leak' };
        } else {
          return { tooltip: 'Moisture', icon: 'mdi mdi-water' };
        }
      case 26:
        return { tooltip: 'Dust', icon: 'mdi mdi-weather-dust' };
      case 28:
        return { tooltip: 'Air Score', icon: 'mdi mdi-scoreboard-outline' };
      case 13:
        return { tooltip: 'ADC', icon: 'mdi mdi-video-input-component' };
      case 51:
        return { tooltip: 'Radon', icon: 'mdi mdi-gas-cylinder' };
      case 68:
        return { tooltip: 'Gas', icon: 'mdi mdi-gas-burner' };
      case 42:
        return { tooltip: 'Custom value', icon: 'mdi mdi-code-string' };
      case 53:
        return { tooltip: 'Hourly Counter', icon: 'mdi mdi-counter' };
      case 64:
        return { tooltip: 'Water Tension', icon: 'mdi mdi-water' };
      case 65:
        return { tooltip: 'Soil Temperature', icon: 'mdi mdi-thermometer-low' };
      case 67:
        if (value === null) {
          // No value passed
          return { tooltip: 'Battery', icon: 'mdi mdi-battery' };
        } else if (+value >= 90) {
          return { tooltip: 'Battery over 90%', icon: 'mdi mdi-battery' };
        } else if (+value >= 50) {
          return { tooltip: 'Battery over 50%', icon: 'mdi mdi-battery-50' };
        } else if (+value >= 10) {
          return { tooltip: 'Battery over 50%', icon: 'mdi mdi-battery-20' };
        } else {
          return { tooltip: 'Battery ALERT', icon: 'mdi mdi-battery-alert-variant-outline red' };
        }
      case 69:
        return { tooltip: 'Occupancy headcount', icon: 'mdi mdi-account-multiple' };
      case 70:
        return { tooltip: 'Temp. & Humidity', icon: 'mdi  mdi-thermometer-low' };
      case 72:
        return { tooltip: 'Electric Consumption', icon: 'mdi mdi-meter-electric-outline' };
      case 73:
        return { tooltip: 'Gas Consumption', icon: 'mdi mdi-meter-gas-outline' };
      case 74:
        return { tooltip: 'Velocity', icon: 'mdi mdi-speedometer' };
      default:
        switch (purposeId) {
          case 6:
            if (this.asset().value === 'In Use' || this.asset().value === 'occupied') {
              return { tooltip: 'In use', icon: 'mdi mdi-account' };
            } else {
              return { tooltip: 'Not In Use', icon: 'mdi mdi-account-outline' };
            }

          default:
            console.log(this.asset());
            return { tooltip: 'Unknown ' + typeId + ' ' + purposeId, icon: 'mdi mdi-help-circle-outline' };
        }
    }
  });

  constructor() { }


}

export interface IAssetIcon {
  icon: string;
  tooltip: string;
}
