export class OfflineIssue {
	id: number;
	issue: string;
	effected: OfflineIssueEffect;
	assetId: number;
	gatewayId: number;
	siteId: number;
	communication: any[];
	isIgnored: boolean;
	createdAt: Date;
	siteTitle: string;
	gatewayTitle: string;
	assetTitle: string;

	constructor(data?: any) {
		let payload;
		if (!data) {
			return;
		}

		if (data.sites?.length) {
			payload = data.sites[0];
		} else if (data.gateways?.length) {
			payload = data.gateways[0];
		} else if (data.assets?.length) {
			payload = data.assets[0];
		} else {
			payload = data;
		}
		this.id = payload.id;
		this.issue = payload.issue;
		this.assetId = payload.asset_id;
		this.siteId = payload.site_id;
		this.gatewayId = payload.gateway_id;
		this.effected = payload.effected;
		this.createdAt = payload.createdAt;
		this.siteTitle = payload.sTitle || null;
		this.gatewayTitle = payload.gTitle || null;
		this.assetTitle = payload.aTitle || null;
	}

	serialise() {
		return {
			id: this.id,
			issue: this.issue,
			effected: this.effected,
			asset_id: this.assetId,
			gateway_id: this.gatewayId,
			site_id: this.siteId
		};
	}
}


export type OfflineIssueEffect = 'asset' | 'gateway' | 'site';

export interface IOfllineIssueConstructor {
	sites: [];
	gateways: [];
	assets: [];
}
