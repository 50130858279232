import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { ICalendarDay } from '../calendar/calendar.component';
import moment from 'moment';
import { CalendarService, IDateRange, IDateConfig } from 'app/shared/calendar.service';

@Component({
  selector: 'app-button-dates',
  templateUrl: './button-dates.component.html',
  styleUrl: './button-dates.component.css'
})
export class ButtonDatesComponent {
  _onDialog = signal<boolean>(false);
  label = signal<string>('Select Date');
  _config = signal<IDateConfig>({ dateRange: 'lastweek', dates: { from: new Date(), to: new Date() } });
  _caption = signal<string>(null);

  @Input()
  public set caption(v: string) {
    this._caption.set(v);
  }

  @Input()
  public set value(v: IDateConfig) {
    console.log(`set config`, v);
    this._config.set({ dateRange: v.dateRange, dates: v.dates });

    this.changed();
  }

  @Output()
  onSelect = new EventEmitter<any>();

  @Output()
  size = signal<string>('medium');

  constructor(private calendarService: CalendarService) {

  }

  closed(event?: any) {
    if (this._config()?.dateRange === 'custom') {
      this.onSelect.emit(this._config());
    }
    this._onDialog.set(false);
    this.buildLabel();
  }

  buildLabel() {
    if (this._config()) {
      const dates = this._config().dates;
      switch (this._config()?.dateRange) {
        case 'today':
          this.label.set('Today');
          break;
        case 'thisweek':
          this.label.set('This week');
          break;
        case 'lastweek':
          this.label.set('Last Week');
          break;
        case 'thismonth':
          this.label.set('This Month');
          break;
        case 'lastmonth':
          this.label.set('Last Month');
          break;
        default: {
          const from = dates.from ? moment(dates.from).format('DD/MM/YYYY') : null;
          const to = dates.to ? moment(dates.to).format('DD/MM/YYYY') : null;
          if (from === null || to === null) {
            this.label.set('Select Dates');
          } else {
            this.label.set(`${from} to ${to}`);
          }

          break;
        }
      }
    } else {
      this.label.set('Select Dates');
    }
  }

  ngOnDestroy(): void {

  }

  changed(closeDialog = true) {
    if (this._config().dateRange === 'custom' || !this._config().dates.from || !this._config().dates.to) {
      this.buildLabel();
      return;
    }
    if (this._config().dateRange !== 'custom') {
      this.onSelect.emit(this._config());
      if (closeDialog) {
        this.closed();
      }
    } else {
      if (this._config().dates.to && this._config().dates.from) {
        this.onSelect.emit(this._config());
        if (closeDialog) {
          this.closed();
        }
      }
    }
    this.buildLabel();
  }


  dateButtonClick(dateRange: IDateRange) {
    this._config.update(item => {
      item.dateRange = dateRange;

      switch (dateRange) {
        case 'today':
        case 'thisweek':
        case 'lastweek':
        case 'thismonth':
        case 'lastmonth': {
          item.dates = this.calendarService.calculateDatesFromRangeText(dateRange);
          break;
        }
      }

      return item;
    });

    this.changed();
  }

  changeDate(part: 'from' | 'to', day: ICalendarDay) {
    console.log(part, day);
    this._config.update(obj => {
      obj.dates[part] = day.date;
      obj.dateRange = 'custom';
      return obj;
    });
    this.changed(false);
  }
}
