export class Purpose {
    id: number;
    title: string;
    category: string;
    chartType: 'line' | 'step';
    min: number = null;
    max: number = null;

    constructor(data?: any) {
        if (!data) {

            return;
        }
        // Import the order details
        this.id = +data.id;
        this.title = data.title;
        this.chartType = 'line';
        switch (this.id) {
            case 1:
            case 2:
            case 7:
                this.category = 'camera';
                break;
            case 3:
            case 4:
            case 5:
                this.category = 'switch';
                break;
            case 6:
                this.category = 'occupancy';
                break;
            case 8:
                this.category = 'waste';
                break;
            case 9:
                this.category = 'footfall';
                this.chartType = 'step';
                break;
            case 10:
                this.category = 'footfall';
                this.chartType = 'step';
                break;
            case 11:
                this.category = 'water';
                this.chartType = 'step';
                this.title = 'Water Leak';
                this.min = 0;
                this.max = 1;
                break;
            default:
                this.category = '';
                break;
        }
    }

    asNumber(value): number {
        switch (this.id) {
            case null:
            case 0:
                return +value;
            case 6:
                return value === 'In Use' || value === 'occupied' ? 1 : 0;
            case 11:
                return value === 'No Leak' ? 0 : value === '*LEAK*' ? 1 : value;
            default:
                return null;
        }
    }
}
