import { Site } from "./site";
import { Org } from "./org";
import { Message } from "./message";

export class Ticket {
    type: TicketType = 'generic';
    id: number;
    title: string;
    description: string;
    state: string;
    createdAt: Date;

    messages: Message[];
    site: Site;
    org: Org;

    isAssetTicket: boolean = false;
    isGatewayTicket: boolean = false;
    isSiteTicket: boolean = false;

    constructor(data?: any) {
        if (data) {
            this.id = data.id;
            this.title = data.title;
            this.description = data.description;
            this.state = data.state;
            this.createdAt = data.createdAt;

            this.site = new Site();
            this.site.title = data.siteTitle;
            this.site.id = data.siteId;

            this.org = new Org();
            this.org.id = data.orgId;
            this.org.title = data.orgTitle;
        }

        this.messages = [];
    }

    serialise() {
        return {
            id: this.id,
            title: this.title,
            description: this.description,
            assetId: null,
            siteId: null,
            gatewayId: null
        };
    }
}


export type TicketType = 'asset' | 'generic';
