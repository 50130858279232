@defer(when asset()) {
<article>
    @if(wantsDetails) {
    <app-theme-page-section [label]="isAdmin ? '#' + asset().id + ' ' + asset().title  : asset().title" [toolbar]="canEdit ? [{icon:'mdi-pencil',label:'Edit fields',tag:'EDIT_FIELDS'}] : null" (onToolbarClick)="toolbarClick($event)">
        <main>
            <div>
                <section>
                    <div>
                        Value
                    </div>
                    <div>
                        <span pTooltip=" {{asset().value}}">{{asset().displayValue()}}</span>
                    </div>
                </section>
            </div>

            <div>
                <section>
                    <div>
                        Sensor
                    </div>
                    <div>
                        <app-asset-icon [asset]="asset()"></app-asset-icon>
                        {{asset().assetTypeTitle}}
                    </div>
                </section>
            </div>
            <div>
                <section>
                    <div>
                        Identifier
                    </div>
                    <div>
                        <span [pTooltip]="isAdmin ? '#'+asset().id : ''">{{asset().identifier}}</span>
                    </div>
                </section>
            </div>
            <div>
                <section>
                    <div>
                        Location
                    </div>
                    <div>
                        {{asset().location}}
                    </div>
                </section>
            </div>
            <div>
                <section>
                    <div>
                        Updated
                    </div>
                    <div [class.red]="asset().daysOld >= 1">
                        <span *ngIf="asset().daysOld < 1">{{asset().updatedAt | amDateFormat:'DD MMM YYYY HH:mm:ss'}}</span>
                        <span *ngIf="asset().daysOld >= 1" [pTooltip]="asset().daysOld + ' days ago.'" tooltipPosition="bottom">
                            {{asset().updatedAt | amDateFormat:'DD MMM YYYY HH:mm'}}</span>
                    </div>
                </section>
            </div>
            <div>
                <section>
                    <div>
                        Created
                    </div>
                    <div>
                        {{asset().createdAt | amDateFormat:'DD MMM YYYY'}}
                    </div>
                </section>
            </div>
            <div>
                <section>
                    <div>
                        Gateway
                    </div>
                    <div>
                        {{ asset().gateway_id}}
                    </div>
                </section>
            </div>
            <div>
                <section>
                    <div>

                    </div>
                    <div>
                        {{ asset().gateway.title}}
                    </div>
                </section>
            </div>
            <div>
                <section>
                    <div>
                        Weather
                    </div>
                    <div>
                        <app-chip size="small" [label]="'#' +asset().metobsid" />
                    </div>
                </section>
            </div>
            <div>
                <section>
                    <div>
                        Site
                    </div>
                    <div>
                        {{ asset().siteTitle}} <app-chip size="small" [label]="'#' +asset().site_id" />
                    </div>
                </section>
            </div>
            <div>
                <section>
                    <div>
                        Purpose
                    </div>
                    <div>
                        @if(asset().purpose?.id) {
                        {{ asset().purpose?.title}} <app-chip size="small" [label]="'#' +asset().purpose.id" />
                        } @else {
                        Not assigned
                        }
                    </div>
                </section>
            </div>
            <div>
                <section>
                    <div>
                        Days old
                    </div>
                    <div>
                        {{asset().daysOld}}
                    </div>
                </section>
            </div>
            <div>
                <section>
                    <div>
                        ingest per day
                    </div>
                    <div>
                        {{asset().redis?.ingestDayRate}}
                    </div>
                </section>
            </div>
            <div>

            </div>
        </main>

    </app-theme-page-section>
    }
    @if(wantsChart()) {
    <div>
        <app-asset-monitor [asset]="asset()" [fullscreen]="fullscreen">
        </app-asset-monitor>
    </div>
    }
</article>
}
