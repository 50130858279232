
export class TrainingModule {
    id: number;
    image: string;
    title: string;
    video: string;
    body: string;
    state: string;
    viewedAt: Date;
    isViewable: boolean;
    document: string;
    isCompleted: string;
    timestamp: number;
    duration: number;

    static readonly IMAGE_BUCKET = 'https://s3-eu-west-1.amazonaws.com/4d-training/module-list-item/';

    nextModuleId: number;

    constructor(data?: any) {
        if (data) {
            this.id = data.id;
            this.image = TrainingModule.IMAGE_BUCKET + data.image;
            this.title = data.title;
            this.video = data.video;
            this.body = data.body;
            this.state = data.state;
            this.viewedAt = data.viewedAt;
            this.isCompleted = data.isCompleted;
            this.nextModuleId = data.next_training_module_id;
            this.duration = data.duration;

            if (this.isCompleted === 'Y') {
                this.isViewable = true;
            }
        }
     }
}
