import { ElogbooksReactiveJob } from "./elogbooks-reactive-job";

export class AlarmTimeline {
    id: number;
    alarm_id: number;
    createdAt: Date;
    action: string;
    value: string;
    notes: string;
    user_id: number;
    name: string;
    job: ElogbooksReactiveJob;

    constructor(data?: any) {
        this.id = data.id;
        this.alarm_id = data.alarm_id;
        this.createdAt = data.createdAt;
        this.action = data.action;
        this.value = data.value;
        this.notes = data.notes;
        this.user_id = data.user_id;
        this.name = data.name;

        const parts = data.value ? data.value.split(' ') : [];

        if (parts.length === 2) {
            switch (parts[1]) {
                case 'acknowledged':
                case 'paused':
                case 'resolved':
                    this.name = parts[0];
                    this.value = parts[1];
                    break;
            }
        }
    }
}
